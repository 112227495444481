import api from '@http/api';
import moment from 'moment';
import { IPerfil } from '@interfaces/UserComplete';




export interface IFormaContribuicao {
  id: string;
  descricao: string;
  data_cadastro: string;
  status_ativo: boolean;
}



class PageEditCadastroPessoaServices {







  public async ValidarTriagemContribuica(id_perfil: any, informacaoInstantanea: boolean, nova_informacao: any, coluna: string) {
    return await api
    .post(`/api/Triagem/AtualizarNovaInformacao?id_perfil=${id_perfil}&informacaoInstantanea=${informacaoInstantanea}&nova_informacao=${nova_informacao}&coluna=${coluna}`,)
    .then(response => {
      return {
        error: false,
        //response: response.data,
      };
    })
    .catch(error => {
      return {
        error: true,
        //response: [],
      };
    });
  }

  public async DescartarTriagemContribuica(id_perfil: any, coluna: string) {
    return await api
    .delete(`/api/Triagem/DescartarNovaInformacao?id_perfil=${id_perfil}&coluna=${coluna}`,)
    .then(response => {
      return {
        error: false,
        //response: response.data,
      };
    })
    .catch(error => {
      return {
        error: true,
        //response: [],
      };
    });
  }


  public async loadFormaContribuicao() {
    return await api
      .get<IFormaContribuicao[]>('/api/FormaContribuicao/GetFormasContribuicao')
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
          response: [],
        };
      });
  }



  public async loadFormaContribById(id: string) {
    return await api
      .get(
        `/api/FormaContribuicao/GetFormaContribuicaoById/${id}`,
      )
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
          response: [],
        };
      });
}





}

export default PageEditCadastroPessoaServices;
