import api from '@http/api';
import moment from 'moment';
import { IPerfil } from '@interfaces/UserComplete';



export interface ITipoConvenio {
  id: string;
  descricao: string;
  status_ativo: boolean;
}




class PageEditTriagemPerfilServices {

    public async ValidarTriagemConvenio(id_perfil: any, informacaoInstantanea: boolean, nova_informacao: any, coluna: string) {
    return await api
    .post(`/api/Triagem/AtualizarNovaInfofrmacao?id_perfil=${id_perfil}&informacaoInstantanea=${informacaoInstantanea}&nova_informacao=${nova_informacao}&coluna=${coluna}`,)
    .then(response => {
      return {
        error: false,
        //response: response.data,
      };
    })
    .catch(error => {
      return {
        error: true,
        //response: [],
      };
    });
  }

  public async DescartarTriagemConvenio(id_perfil: any, coluna: string) {
    return await api
    .delete(`/api/Triagem/DescartarNovaInformfacao?id_perfil=${id_perfil}&coluna=${coluna}`,)
    .then(response => {
      return {
        error: false,
        //response: response.data,
      };
    })
    .catch(error => {
      return {
        error: true,
        //response: [],
      };
    });
  }



  public async loadCorrespondenciaById(id: string) {
    return await api
      .get(
        `/api/TipoCorrespondencia/GetTipoCorrespondenciaById/${id}`,
      )
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
          response: [],
        };
      });
  }

  public async loadTiposConvenio() {
    return await api
      .get<ITipoConvenio[]>(
        '/api/Financeiro/GetFinanceiroConvenios',
      )
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
          response: [],
        };
      });
  }


}

export default PageEditTriagemPerfilServices;
