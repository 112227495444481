import React, { useEffect, useState, useRef } from 'react';

import { Grid, IconButton } from '@material-ui/core';
import { Form, Input, Select as SelectAntd, Spin, Radio } from 'antd';
import { SaveOutlined } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import ButtonComponent from '@components/Shared/Buttons/Button';
import InvisibleContent from '@src/components/Shared/InvisibleContent';

import ComponentPerfilServices, {
  IOperacao,
} from './services';

interface IProps {
  finishOperacao?: Function;
  contrib: any;
  contrib2: any;
  //OperacaoNovo: any;
  perfil: number;
}

const optionsFilter = ['ID', 'CPF/CNPJ'];

const COperacaoDevolvidaComponent:React.FC<IProps> = ({
  contrib,
  contrib2,
  finishOperacao,
  //cOperacaoNovo,
  perfil,

}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [formRef] = Form.useForm();

  const [loadingValidar, setLoadingValidar] = useState(false);
  const [loadingDescartar, setLoadingDescartar] = useState(false);

  const [informacaoInstantanea, setInformacaoInstantanea] = useState(true)

  const [colunaValidar, setColunaValidar] = useState('Operacao');
  const [colunaDescartar, setColunaDescartar] = useState('NovoNumeroOperacao');

  const [operacao, setOperacao] = useState<
  IOperacao[]>([]);

  const [bancoId, setBancoId] = useState<number>(0);

  const [novoValor, setNovoValor] = useState('');


  const init = async () => {
    const componentPerfilServices = new ComponentPerfilServices();


    if (contrib === 8 || contrib2 === "CAIXA ECONOMICA FEDERAL"){
      setBancoId(3)
    }else if (contrib === 7 || contrib2 === "SANTANDER"){
      setBancoId(1)
    }


   const OperacaoResponse =
      await componentPerfilServices.loadTiposOperacao(bancoId);

    if (!OperacaoResponse.error) {
       setOperacao(OperacaoResponse.response);
    }
  }

  async function onSubmitValidarNovo() {
    setLoadingValidar(true);

    const service = new ComponentPerfilServices();
    const { error,  } = await service.ValidarTriagemOperacao(
      perfil,
      informacaoInstantanea,
      novoValor,
      colunaValidar,
    );

    if (!error) {
      enqueueSnackbar(`Cadastro alterado com sucesso!`, {
        variant: 'success',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      if (finishOperacao) {
        finishOperacao();
      }
      window.location.reload();
    } else {
      enqueueSnackbar(`Ocorreu um erro`, {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
    }
    setLoadingValidar(false);
  }


  async function onSubmitDescartar() {
    setLoadingDescartar(true);

    const service = new ComponentPerfilServices();
    const { error,  } = await service.DescartarTriagemOperacao(
      perfil,
      colunaDescartar,
    );

    if (!error) {
      enqueueSnackbar(`Cadastro descartado com sucesso!`, {
        variant: 'success',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      if (finishOperacao) {
        finishOperacao();
      }
    } else {
      enqueueSnackbar(`Ocorreu um erro`, {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
    }
    setLoadingDescartar(false);
    //window.location.reload();
  }

  const clearCadastro = () => {
    formRef.setFieldsValue({
      selectCadastro: [],
    });
  };

  useEffect(() => {
    init();
    window.document.title = 'CRM - Edição de Cadastro Triagem';
  }, []);

  return (
    <Grid container>
      <Grid container item sm={12}>
        <Grid container item xs={12} md={12} justify="center" alignItems="center">
          <Form
            form={formRef}
            scrollToFirstError={true}
            //onFinish={onSubmitValidar}
            requiredMark={false}
            style={{ width: '100%' }}
            layout="vertical"
          >
            <Grid item container justify="center" alignItems="center" alignContent='center' sm={12} xs={12} lg={12}>

              <Grid item lg={12} xs={12} sm={12}>
                <Form.Item name="operacao">
                  <SelectAntd
                    placeholder="Operação"
                    onChange={(e: any) => {
                      setNovoValor(e);
                      //filterCheckTipoFormContribuicao(e);
                      //validaAlteracao();
                    }}
                  >
                    {operacao.map(item => (
                      <SelectAntd.Option key={item.id} value={item.id}>
                        {item.codigo_operacao + "-" + item.descricao}
                      </SelectAntd.Option>
                    ))}
                  </SelectAntd>
                </Form.Item>
              </Grid>

              <Grid container item lg={12} sm={12} xs={12} style={{ paddingTop: 10 }}>
                <Grid item lg={5} sm={12} xs={12}>
                  <ButtonComponent
                    color="default"
                    fullWidth
                    type="submit"
                    onClick={onSubmitDescartar}
                  >
                    <SaveOutlined /> Descartar
                  </ButtonComponent>
                </Grid>

                <Grid item lg={1} sm={12} xs={12}></Grid>

                <Grid item lg={5} sm={12} xs={12}>
                  <ButtonComponent
                    color="quartenary"
                    fullWidth
                    type="submit"
                    loading={loadingValidar}
                    onClick={onSubmitValidarNovo}
                  >
                    <SaveOutlined /> Validar
                  </ButtonComponent>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default COperacaoDevolvidaComponent;
