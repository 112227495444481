import React, { useEffect, useState, useCallback } from 'react';
import { Grid, IconButton } from '@material-ui/core';
import { Form, Input, Select as SelectAntd, Spin } from 'antd';
import MaskedInput from 'antd-mask-input';
import { SaveOutlined, Edit, CheckCircle } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';

import InvisibleContent from '@components/Shared/InvisibleContent';

import ModalTriagemCidade from '../ModalTriagemCIdade';
import ModalTriagemCorrespondencia from '../ModalTriagemCorrespondencia';
import ModalTriagemFormaContrib from '../ModalTriagemFormaContrib';
import ModalTriagemValorPadrao from '../ModalTriagemValorPadrao';
import ModalTriagemCelular from '../ModalTriagemCelular';
import ModalTriagemWhatsApp from '../ModalTriagemWhatsApp';
import ModalTriagemTelFixo from '../ModalTriagemTelFixo';
import ModalTriagemTelComercial from '../ModalTriagemTelComercial';
import ModalTriagemEmail from '../ModalTriagemEmail';
import ModalTriagemComplemento from '../ModalTriagemComplemento';
import ModalTriagemEndereco from '../ModalTriagemEndereco';
import ModalTriagemDistrito from '../ModalTriagemDistrito';
import ModalTriagemBairro from '../ModalTriagemBairro';
import ModalTriagemEstado from '../ModalTriagemEstado';
import ModalTriagemCEP from '../ModalTriagemCEP';
import ModalTriagemAgenciaNumero from '../ModalTriagemAgenciaNumero';
import ModalTriagemAgenciaDigito from '../ModalTriagemAgenciaDigito';
import ModalTriagemContaNumero from '../ModalTriagemContaNumero';
import ModalTriagemContaDigito from '../ModalTriagemContaDigito';
import ModalTriagemDiaVencimento from '../ModalTriagemDiaVencimento';
import ModalTriagemValorDebito from '../ModalTriagemValorDebito';
import ModalTriagemOperacao from '../ModalTriagemOperacao';
import ModalTriagemConvenio from '../ModalTriagemConvenio';

import Modal from '@src/components/Shared/Modal/ModalPure';
import ButtonComponent from '@components/Shared/Buttons/Button';

import { isReal, ClearString } from '@utils/Masks';
import { useLoading } from '@hooks/LoadingContext';

import SearchCEPCorreios from '@utils/SearchCEPCorreios';
import useDebounce from '@hooks/useDebounce';

import ComponentPerfilServices, {
  IPerfilTriagem,
  IInfosAlteradasTriagem,
} from './services';


interface Iprops {
  perfilID: number;
  functionReload?: Function;
  idInteracao: number;
  idPessoa: number;
}

const PerfilComponent: React.FC<Iprops> = ({
  perfilID,
  functionReload,
  idInteracao,
  idPessoa,
}) => {
  const [formRef] = Form.useForm();

  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [loadingCEP, setLoadingCEP] = useState(false);

  const [loadingUpdate, setLoadingUpdate] = useState(false);


  const [checkTipoFormContribuicao, setCheckTipoFormContribuicao] = useState<any>('');
  const [checkTipoConvenio, setCheckTipoConvenio] = useState<any>('');



  //MODAIS

  const [openModalCidade, setOpenModalCidade] = useState(false);
  const [openModalCorrespondencia, setOpenModalCorrespondencia] = useState(false);
  const [openModalFormaContrib, setOpenModalFormaContrib] = useState(false);
  const [openModalValorPadrao, setOpenModalValorPadrao] = useState(false);
  const [openModalCelular, setOpenModalCelular] = useState(false);
  const [openModalWhatsApp, setOpenModalWhatsApp] = useState(false);
  const [openModalTelFixo, setOpenModalTelFixo] = useState(false);
  const [openModalTelComercial, setOpenModalTelComercial] = useState(false);
  const [openModalEmail, setOpenModalEmail] = useState(false);
  const [openModalComplemento, setOpenModalComplemento] = useState(false);
  const [openModalEndereco, setOpenModalEndereco] = useState(false);
  const [openModalDistrito, setOpenModalDistrito] = useState(false);
  const [openModalBairro, setOpenModalBairro] = useState(false);
  const [openModalEstado, setOpenModalEstado] = useState(false);
  const [openModalCEP, setOpenModalCEP] = useState(false);
  const [openModalAgenciaNumero, setOpenModalAgenciaNumero] = useState(false);
  const [openModalAgenciaDigito, setOpenModalAgenciaDigito] = useState(false);
  const [openModalContaDigito, setOpenModalContaDigito] = useState(false);
  const [openModalContaNumero, setOpenModalContaNumero] = useState(false);
  const [openModalVencimentoDebito, setOpenModalVencimentoDebito] = useState(false);
  const [openModalValorDebito, setOpenModalValorDebito] = useState(false);
  const [openModalOperacao, setOpenModalOperacao] = useState(false);
  const [openModalConvenio, setOpenModalConvenio] = useState(false);


  const [valorModifyValorDebito, setValorModifyValorDebito] = useState<string>('');
  const [stringDebitoStatus, setStringDebitoStatus] = useState<string>('');


  const [loadingCadastro, setLoadingCadastro] = useState(false);
  const [loadingInfoAlterada, setLoadingInfoAlterada] = useState(false);
  const [loadingCadastroLigacao, setLoadingCadastroLigacao] = useState(false);
  const [loadingConfirmarTriagem, setLoadingConfirmarTriagem] = useState(false);
  const [infoAlteracaoTriagem, setInfoAlteracaoTriagem] = useState<IInfosAlteradasTriagem>();

  const [perfilTriagem, setPerfilTriagem] = useState<IPerfilTriagem>();

  const [uf, setUF] = useState(
    perfilTriagem?.contato_offline.estado_descricao,
   );


   //SET INFO ALTERADA

  const [tipoPerfilTriagem, setTipoPerfilTriagem] =  useState(undefined);
  const [formaContribuicaoTriagem, setFormaContribuicaoTriagem] =  useState(undefined);
  const [tipoCorrespondenciaTriagem, setTipoCorrespondenciaTriagem] = useState(undefined);
  const [valorPadraoTriagem, setValorPadraoTriagem] = useState(undefined);
  const [bairroTriagem, setBairroTriagem] = useState(undefined);
  const [estadoTriagem, setEstadoTriagem] = useState(undefined);
  const [cidadeTriagem, setCidadeTriagem] = useState(undefined);
  const [cepTriagem, setCepTriagem] = useState(undefined);
  const [logradouroTraigem, setLogradouroTriagem] = useState(undefined);
  const [enderecoTriagem, setEnderecoTriagem] = useState(undefined);
  const [numeroCasariagem, setNumeroCasaTriagem] = useState(undefined);
  const [complementoTriagem, setComplementoTriagem] = useState(undefined);
  const [distritoTriagem, setDistritoTriagem] = useState(undefined);
  const [emailTriagem, setEmailTriagem] = useState(undefined);
  const [celularTriagem, setCelularTriagem] = useState(undefined);
  const [whatsAppTriagem, setWhatsAppTriagem] = useState(undefined);
  const [telefoneComercialTriagem, setTelefoneComercialTriagem] = useState(undefined);
  const [telefoneFixoTriagem, setTelefoneFixoTriagem] = useState(undefined);

  const [convenioTriagem, setConvenioTriagem] = useState(undefined);
  const [agenciaNumeroTriagem, setAgenciaNumeroTriagem] = useState(undefined);
  const [agenciaDigitoTriagem, setAgenciaDigitoTriagem] = useState(undefined);
  const [contaNumeroTriagem, setContaNumeroTriagem] = useState(undefined);
  const [contaDigitoTriagem, setContaDigitoTriagem] = useState(undefined);
  const [valorDebitoTriagem, setValorDebitoTriagem] = useState(undefined);
  const [vencimentoDebitoTriagem, setVencimentoDebitoTriagem] = useState(undefined);
  const [operacaoTriagem, setOperacaoTriagem] = useState(undefined);


  const [formaContribDescricao, setFormaContribDescricao] = useState('');
  const [tipoCorrespDescricao, setTipoCorrespDescricao] = useState('');
  const [convenioDescricao, setconvenioDescricao] = useState('');
  const [cidadeDescricao, setCidadeDescricao] = useState('');


  //VALIDAR ALTERAÇÕES
  const [loadingValidar, setLoadingValidar] = useState(false);
  const [colunaValidarContrib, setColunaValidarContrib] = useState('FormaContribuicaoId');
  const [colunaValidarCorresp, setColunaValidarCorresp] = useState('TipoCorrespondenciaId');
  const [colunaValidarValorPadrao, setColunaValidarValorPadrao] = useState('ValorPadraoDoacao');
  const [colunaValidarCelular, setColunaValidarCelular] = useState('Celular');
  const [colunaValidarWhatsApp, setColunaValidarWhatsApp] = useState('WhatsApp');
  const [colunaValidarTelFixo, setColunaValidarTelFixo] = useState('TelefoneFixo');
  const [colunaValidarTelComercial, setColunaValidarTelComercial] = useState('TelefoneComercial');
  const [colunaValidarEmail, setColunaValidarEmail] = useState('Email');
  const [colunaValidarCep, setColunaValidarCep] = useState('Cep');
  const [colunaValidarEstado, setColunaValidarEstado] = useState('Estado');
  const [colunaValidarCidade, setColunaValidarCidade] = useState('Cidade');
  const [colunaValidarEndereco, setColunaValidarEndereco] = useState('Endereco');
  const [colunaValidarBairro, setColunaValidarBairro] = useState('Bairro');
  const [colunaValidarDistrito, setColunaValidarDistrito] = useState('Distrito');
  const [colunaValidarComplemento, setColunaValidarComplemento] = useState('Complemento');
  const [colunaValidarConvenio, setColunaValidarConvenio] = useState('ConvenioId');
  const [colunaValidarVencDebito, setColunaValidarVencDebito] = useState('DiaVencimentoDebito');
  const [colunaValidarAgenciaNumero, setColunaValidarAgenciaNumento] = useState('AgenciaNumero');
  const [colunaValidarAgenciaDigito, setColunaValidarAgenciaDigito] = useState('AgenciaDigito');
  const [colunaValidarContaNumero, setColunaValidarContaNumero] = useState('ContaNumero');
  const [colunaValidarContaDigito, setColunaValidarContaDigito] = useState('ContaDigito');
  const [colunaValidarValorDebito, setColunaValidarValorDebito] = useState('ValorRecorrente');
  const [colunaValidarOperacao, setColunaValidarOperacao] = useState('Operacao');




 //VALIDAR ALTERAÇÕES
  async function onSubmitValidarFormaContrib() {
    setLoadingValidar(true);

    const service = new ComponentPerfilServices();
    const { error,  } = await service.ValidarInfoTriagem(
      perfilID,
      false,
      formaContribuicaoTriagem,
      colunaValidarContrib,
    );

    if (!error) {
      enqueueSnackbar(`Cadastro alterado com sucesso!`, {
        variant: 'success',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      window.location.reload();
    } else {
      enqueueSnackbar(`Ocorreu um erro`, {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
    }
    setLoadingValidar(false);
  }

  {/* ---------------------------------------------------------- */}

  async function onSubmitValidarCorrespondencia() {
    setLoadingValidar(true);

    const service = new ComponentPerfilServices();
    const { error,  } = await service.ValidarInfoTriagem(
      perfilID,
      false,
      tipoCorrespondenciaTriagem,
      colunaValidarCorresp,
    );

    if (!error) {
      enqueueSnackbar(`Cadastro alterado com sucesso!`, {
        variant: 'success',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      window.location.reload();
    } else {
      enqueueSnackbar(`Ocorreu um erro`, {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
    }
    setLoadingValidar(false);
  }

  {/* ---------------------------------------------------------- */}

  async function onSubmitValidarValorPadrao() {
    setLoadingValidar(true);

    const service = new ComponentPerfilServices();
    const { error,  } = await service.ValidarInfoTriagem(
      perfilID,
      false,
      valorPadraoTriagem,
      colunaValidarValorPadrao,
    );

    if (!error) {
      enqueueSnackbar(`Cadastro alterado com sucesso!`, {
        variant: 'success',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      window.location.reload();
    } else {
      enqueueSnackbar(`Ocorreu um erro`, {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
    }
    setLoadingValidar(false);
  }

  {/* ---------------------------------------------------------- */}

  async function onSubmitValidarCelular() {
    setLoadingValidar(true);

    const service = new ComponentPerfilServices();
    const { error,  } = await service.ValidarInfoTriagem(
      perfilID,
      false,
      celularTriagem,
      colunaValidarCelular,
    );

    if (!error) {
      enqueueSnackbar(`Cadastro alterado com sucesso!`, {
        variant: 'success',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      window.location.reload();
    } else {
      enqueueSnackbar(`Ocorreu um erro`, {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
    }
    setLoadingValidar(false);
  }

  {/* ---------------------------------------------------------- */}

  async function onSubmitValidarWhatsApp() {
    setLoadingValidar(true);

    const service = new ComponentPerfilServices();
    const { error,  } = await service.ValidarInfoTriagem(
      perfilID,
      false,
      whatsAppTriagem,
      colunaValidarWhatsApp,
    );

    if (!error) {
      enqueueSnackbar(`Cadastro alterado com sucesso!`, {
        variant: 'success',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      window.location.reload();
    } else {
      enqueueSnackbar(`Ocorreu um erro`, {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
    }
    setLoadingValidar(false);
  }

  {/* ---------------------------------------------------------- */}

  async function onSubmitValidarTelFixo() {
    setLoadingValidar(true);

    const service = new ComponentPerfilServices();
    const { error,  } = await service.ValidarInfoTriagem(
      perfilID,
      false,
      telefoneFixoTriagem,
      colunaValidarTelFixo,
    );

    if (!error) {
      enqueueSnackbar(`Cadastro alterado com sucesso!`, {
        variant: 'success',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      window.location.reload();
    } else {
      enqueueSnackbar(`Ocorreu um erro`, {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
    }
    setLoadingValidar(false);
  }

  {/* ---------------------------------------------------------- */}

  async function onSubmitValidarTelComercial() {
    setLoadingValidar(true);

    const service = new ComponentPerfilServices();
    const { error,  } = await service.ValidarInfoTriagem(
      perfilID,
      false,
      telefoneComercialTriagem,
      colunaValidarTelComercial,
    );

    if (!error) {
      enqueueSnackbar(`Cadastro alterado com sucesso!`, {
        variant: 'success',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      window.location.reload();
    } else {
      enqueueSnackbar(`Ocorreu um erro`, {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
    }
    setLoadingValidar(false);
  }

  {/* ---------------------------------------------------------- */}

  async function onSubmitValidarEmail() {
    setLoadingValidar(true);

    const service = new ComponentPerfilServices();
    const { error,  } = await service.ValidarInfoTriagem(
      perfilID,
      false,
      emailTriagem,
      colunaValidarEmail,
    );

    if (!error) {
      enqueueSnackbar(`Cadastro alterado com sucesso!`, {
        variant: 'success',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      window.location.reload();
    } else {
      enqueueSnackbar(`Ocorreu um erro`, {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
    }
    setLoadingValidar(false);
  }

  {/* ---------------------------------------------------------- */}

  async function onSubmitValidarCEP() {
    setLoadingValidar(true);

    const service = new ComponentPerfilServices();
    const { error,  } = await service.ValidarInfoTriagem(
      perfilID,
      false,
      cepTriagem,
      colunaValidarCep,
    );

    if (!error) {
      enqueueSnackbar(`Cadastro alterado com sucesso!`, {
        variant: 'success',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      window.location.reload();
    } else {
      enqueueSnackbar(`Ocorreu um erro`, {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
    }
    setLoadingValidar(false);
  }

  {/* ---------------------------------------------------------- */}

  async function onSubmitValidarCidade() {
    setLoadingValidar(true);

    const service = new ComponentPerfilServices();
    const { error,  } = await service.ValidarInfoTriagem(
      perfilID,
      false,
      cidadeTriagem,
      colunaValidarCidade,
    );

    if (!error) {
      enqueueSnackbar(`Cadastro alterado com sucesso!`, {
        variant: 'success',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      window.location.reload();
    } else {
      enqueueSnackbar(`Ocorreu um erro`, {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
    }
    setLoadingValidar(false);
  }

  {/* ---------------------------------------------------------- */}

  async function onSubmitValidarEndereco() {
    setLoadingValidar(true);

    const service = new ComponentPerfilServices();
    const { error,  } = await service.ValidarInfoTriagem(
      perfilID,
      false,
      enderecoTriagem,
      colunaValidarEndereco,
    );

    if (!error) {
      enqueueSnackbar(`Cadastro alterado com sucesso!`, {
        variant: 'success',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      window.location.reload();
    } else {
      enqueueSnackbar(`Ocorreu um erro`, {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
    }
    setLoadingValidar(false);
  }

  {/* ---------------------------------------------------------- */}

  async function onSubmitValidarBairro() {
    setLoadingValidar(true);

    const service = new ComponentPerfilServices();
    const { error,  } = await service.ValidarInfoTriagem(
      perfilID,
      false,
      bairroTriagem,
      colunaValidarBairro,
    );

    if (!error) {
      enqueueSnackbar(`Cadastro alterado com sucesso!`, {
        variant: 'success',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      window.location.reload();
    } else {
      enqueueSnackbar(`Ocorreu um erro`, {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
    }
    setLoadingValidar(false);
  }

  {/* ---------------------------------------------------------- */}

  async function onSubmitValidarDistrito() {
    setLoadingValidar(true);

    const service = new ComponentPerfilServices();
    const { error,  } = await service.ValidarInfoTriagem(
      perfilID,
      false,
      distritoTriagem,
      colunaValidarDistrito,
    );

    if (!error) {
      enqueueSnackbar(`Cadastro alterado com sucesso!`, {
        variant: 'success',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      window.location.reload();
    } else {
      enqueueSnackbar(`Ocorreu um erro`, {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
    }
    setLoadingValidar(false);
  }

  {/* ---------------------------------------------------------- */}

  async function onSubmitValidarComplemento() {
    setLoadingValidar(true);

    const service = new ComponentPerfilServices();
    const { error,  } = await service.ValidarInfoTriagem(
      perfilID,
      false,
      complementoTriagem,
      colunaValidarComplemento,
    );

    if (!error) {
      enqueueSnackbar(`Cadastro alterado com sucesso!`, {
        variant: 'success',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      window.location.reload();
    } else {
      enqueueSnackbar(`Ocorreu um erro`, {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
    }
    setLoadingValidar(false);
  }

  {/* -------------------------DEBITO AUTOMATICO --------------------------------- */}

  async function onSubmitValidarConvenio() {
    setLoadingValidar(true);

    const service = new ComponentPerfilServices();
    const { error,  } = await service.ValidarInfoTriagem(
      perfilID,
      false,
      convenioTriagem,
      colunaValidarConvenio,
    );

    if (!error) {
      enqueueSnackbar(`Cadastro alterado com sucesso!`, {
        variant: 'success',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      window.location.reload();
    } else {
      enqueueSnackbar(`Ocorreu um erro`, {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
    }
    setLoadingValidar(false);
  }

  {/* ---------------------------------------------------------- */}

  async function onSubmitValidarVencimentoDebito() {
    setLoadingValidar(true);

    const service = new ComponentPerfilServices();
    const { error,  } = await service.ValidarInfoTriagem(
      perfilID,
      false,
      vencimentoDebitoTriagem,
      colunaValidarVencDebito,
    );

    if (!error) {
      enqueueSnackbar(`Cadastro alterado com sucesso!`, {
        variant: 'success',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      window.location.reload();
    } else {
      enqueueSnackbar(`Ocorreu um erro`, {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
    }
    setLoadingValidar(false);
  }

  {/* ---------------------------------------------------------- */}

  async function onSubmitValidarAgenciaNumero() {
    setLoadingValidar(true);

    const service = new ComponentPerfilServices();
    const { error,  } = await service.ValidarInfoTriagem(
      perfilID,
      false,
      agenciaNumeroTriagem,
      colunaValidarAgenciaNumero,
    );

    if (!error) {
      enqueueSnackbar(`Cadastro alterado com sucesso!`, {
        variant: 'success',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      window.location.reload();
    } else {
      enqueueSnackbar(`Ocorreu um erro`, {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
    }
    setLoadingValidar(false);
  }

  {/* ---------------------------------------------------------- */}

  async function onSubmitValidarAgenciaDigito() {
    setLoadingValidar(true);

    const service = new ComponentPerfilServices();
    const { error,  } = await service.ValidarInfoTriagem(
      perfilID,
      false,
      agenciaDigitoTriagem,
      colunaValidarAgenciaDigito,
    );

    if (!error) {
      enqueueSnackbar(`Cadastro alterado com sucesso!`, {
        variant: 'success',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      window.location.reload();
    } else {
      enqueueSnackbar(`Ocorreu um erro`, {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
    }
    setLoadingValidar(false);
  }

  {/* ---------------------------------------------------------- */}

  async function onSubmitValidarContaNumero() {
    setLoadingValidar(true);

    const service = new ComponentPerfilServices();
    const { error,  } = await service.ValidarInfoTriagem(
      perfilID,
      false,
      contaNumeroTriagem,
      colunaValidarContaNumero,
    );

    if (!error) {
      enqueueSnackbar(`Cadastro alterado com sucesso!`, {
        variant: 'success',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      window.location.reload();
    } else {
      enqueueSnackbar(`Ocorreu um erro`, {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
    }
    setLoadingValidar(false);
  }

  {/* ---------------------------------------------------------- */}

  async function onSubmitValidarContaDigito() {
    setLoadingValidar(true);

    const service = new ComponentPerfilServices();
    const { error,  } = await service.ValidarInfoTriagem(
      perfilID,
      false,
      contaDigitoTriagem,
      colunaValidarContaDigito,
    );

    if (!error) {
      enqueueSnackbar(`Cadastro alterado com sucesso!`, {
        variant: 'success',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      window.location.reload();
    } else {
      enqueueSnackbar(`Ocorreu um erro`, {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
    }
    setLoadingValidar(false);
  }

  {/* ---------------------------------------------------------- */}

  async function onSubmitValidarValorDebito() {
    setLoadingValidar(true);

    const service = new ComponentPerfilServices();
    const { error,  } = await service.ValidarInfoTriagem(
      perfilID,
      false,
      valorDebitoTriagem,
      colunaValidarValorDebito,
    );

    if (!error) {
      enqueueSnackbar(`Cadastro alterado com sucesso!`, {
        variant: 'success',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      window.location.reload();
    } else {
      enqueueSnackbar(`Ocorreu um erro`, {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
    }
    setLoadingValidar(false);
  }

  {/* ---------------------------------------------------------- */}

  async function onSubmitValidarOperacao() {
    setLoadingValidar(true);

    const service = new ComponentPerfilServices();
    const { error,  } = await service.ValidarInfoTriagem(
      perfilID,
      false,
      operacaoTriagem,
      colunaValidarOperacao,
    );

    if (!error) {
      enqueueSnackbar(`Cadastro alterado com sucesso!`, {
        variant: 'success',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      window.location.reload();
    } else {
      enqueueSnackbar(`Ocorreu um erro`, {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
    }
    setLoadingValidar(false);
  }

  //VALIDAR ALTERAÇÕES END ///




  function handleInput(e: any) {
    var ss = e.target.selectionStart;
    var se = e.target.selectionEnd;
    e.target.value = e.target.value.toUpperCase();
    e.target.selectionStart = ss;
    e.target.selectionEnd = se;
  };




  const loadPerfilTriagem = useCallback(async perfilID => {
    setLoadingCadastro(true);
    const services = new ComponentPerfilServices();
    const { error, response } = await services.GetPerfilByID({ id: perfilID });

    if (!error && response) {

      const perfilTriagem: IPerfilTriagem = {
        nome: response.nome,
        tipo_perfil_descricao: response.tipo_perfil_descricao,
        origem_id: response.origem_id,
        origem_descricao: response.origem_descricao,
        motivo_cadastro_id: response.motivo_cadastro_id,
        motivo_cadastro_descricao: response.motivo_cadastro_descricao,
        tipo_correspondencia_id: response.tipo_correspondencia_id,
        tipo_correspondencia_descricao: response.tipo_correspondencia_descricao,
        forma_contribuicao_id: response.forma_contribuicao_id,
        forma_contribuicao_descricao: response.forma_contribuicao_descricao,
        valor_padrao_doacao: response?.valor_padrao_doacao,
        telefone_fixo: response.telefone_fixo,
        telefone_comercial: response.telefone_comercial,
        celular: response.celular,
        whatsapp: response.whatsapp,
        email: response.email,
        contato_offline: response.contato_offline,
        nome_fantasia: response.nome_fantasia,
        debito_automatico: response.debito_automatico


      };

      setPerfilTriagem(perfilTriagem);
      setCheckTipoFormContribuicao(perfilTriagem?.forma_contribuicao_descricao)
      setStringDebitoStatus(
        perfilTriagem.debito_automatico.status_ativo
          ? ' está ativo'
          : ' está inativo',
      );


      formRef.setFieldsValue({
        nome: response.nome,
        origem_id: response.origem_descricao,
        tipo_perfil_descricao: response.tipo_perfil_descricao,
        //origem_descricao: response.origem_descricao,
        motivo_cadastro_id: response.motivo_cadastro_descricao,
        tipo_correspondencia_id: response.tipo_correspondencia_descricao,
        forma_contribuicao_id: response.forma_contribuicao_descricao,
        //forma_contribuicao_descricao: response.forma_contribuicao_descricao,
        valor_padrao_doacao: Intl.NumberFormat('pt-br', {
          style: 'currency',
          currency: 'BRL',
        })
          .format(response.valor_padrao_doacao)
          .replace('R$', '')
          .trim() || '',
        telefone_fixo: response.telefone_fixo.contato_online_descricao,
        telefone_comercial: response.telefone_comercial.contato_online_descricao,
        celular: response.celular.contato_online_descricao,
        whatsapp: response.whatsapp.contato_online_descricao,
        email: response.email.contato_online_descricao,
        cep: response.contato_offline.cep,
        endereco: response.contato_offline.endereco,
        bairro: response.contato_offline.bairro,
        Distrito: response.contato_offline.Distrito,
        complemento: response.contato_offline.complemento,
        estado_descricao: response.contato_offline.estado_descricao,
        cidade_id: response.contato_offline.cidade_descricao,
        contato_offline: response.contato_offline,
        nome_fantasia: response.nome_fantasia,
        debito_automatico: response.debito_automatico,
        convenio_id: response.debito_automatico.nome_cedente,
        agencia_numero: response.debito_automatico.agencia_numero,
        agencia_digito: response.debito_automatico.agencia_digito,
        conta_numero: response.debito_automatico.conta_numero,
        conta_digito: response.debito_automatico.conta_digito,
        valor_debito: Intl.NumberFormat('pt-br', {
          style: 'currency',
          currency: 'BRL',
        })
          .format(response.debito_automatico?.valor)
          .replace('R$', '')
          .trim() || undefined,
        dia_vencimento: response.debito_automatico.dia_vencimento,
        operacao: response.debito_automatico.operacao,
        debito_automatico_status_ativo: response.debito_automatico.status_ativo

      });
      setLoadingCadastro(false);
    } else {
      console.log('error', error);
    }
  }, []);


  const loadInfoAlteradas = useCallback(async perfilID => {
    setLoadingInfoAlterada(true);
    const services = new ComponentPerfilServices();
    const { error, response } = await services.GetInformacoesAlteradas({ id: perfilID });

    if (!error && response) {

      const info: IInfosAlteradasTriagem = {
        NovoTipoPerfil: response.NovoTipoPerfil,
        NovaFormaContribuicao: response.NovaFormaContribuicao,
        NovoTipoCorrespondencia: response.NovoTipoCorrespondencia,
        NovoValorPadrao: response.NovoValorPadrao,
        NovoBairro: response.NovoBairro,
        NovoEstado: response.NovoEstado,
        NovaCidade: response.NovaCidade,
        NovoCep: response.NovoCep,
        NovoLogradouro: response.NovoLogradouro,
        NovoEndereco: response.NovoEndereco,
        NovoNumeroCasa: response.NovoNumeroCasa,
        NovoComplemento: response.NovoComplemento,
        NovoDistrito: response.NovoDistrito,
        NovoEmail: response.NovoEmail,
        NovoCelular: response.NovoCelular,
        NovoWhatsApp: response.NovoWhatsApp,
        NovoTelefoneComercial: response.NovoTelefoneComercial,
        NovoTelefoneFixo: response.NovoTelefoneFixo,
        NovoConvenio: response.NovoConvenio,
        NovaAgenciaNumero: response.NovaAgenciaNumero,
        NovaAgenciaDigito: response.NovaAgenciaDigito,
        NovaContaNumero: response.NovaContaNumero,
        NovoValorRecorrente: response.NovoValorRecorrente,
        NovoDiaVencimentoDebito: response.NovoDiaVencimentoDebito,
        NovaContaDigito: response.NovaContaDigito,
        NovoNumeroOperacao: response.NovoNumeroOperacao,

      };

      setInfoAlteracaoTriagem(info);

      formRef.setFieldsValue({
        NovaFormaContribuicao: response.NovaFormaContribuicao,
        NovoTipoCorrespondencia: response.NovoTipoCorrespondencia,
        NovoValorRecorrente: Intl.NumberFormat('pt-br', {
          style: 'currency',
          currency: 'BRL',
        })
          .format(response.NovoValorRecorrente)
          .replace('R$', '')
          .trim() || '',

        NovoValorPadrao: Intl.NumberFormat('pt-br', {
          style: 'currency',
          currency: 'BRL',
        })
          .format(response.NovoValorPadrao)
          .replace('R$', '')
          .trim() || '',
      });

      setTipoPerfilTriagem(info.NovoTipoPerfil)
      setFormaContribuicaoTriagem(info?.NovaFormaContribuicao)
      setTipoCorrespondenciaTriagem(info.NovoTipoCorrespondencia)
      setValorPadraoTriagem(info.NovoValorPadrao)
      setBairroTriagem(info.NovoBairro)
      setEstadoTriagem(info.NovoEstado)
      setCidadeTriagem(info.NovaCidade)
      setCepTriagem(info.NovoCep)
      setLogradouroTriagem(info.NovoLogradouro)
      setEnderecoTriagem(info.NovoEndereco)
      setNumeroCasaTriagem(info.NovoNumeroCasa)
      setComplementoTriagem(info.NovoComplemento)
      setDistritoTriagem(info.NovoDistrito)
      setEmailTriagem(info.NovoEmail)
      setCelularTriagem(info.NovoCelular)
      setWhatsAppTriagem(info.NovoWhatsApp)
      setTelefoneComercialTriagem(info.NovoTelefoneComercial)
      setTelefoneFixoTriagem(info.NovoTelefoneFixo)
      setConvenioTriagem(info.NovoConvenio)
      setAgenciaNumeroTriagem(info.NovaAgenciaNumero)
      setAgenciaDigitoTriagem(info.NovaAgenciaDigito)
      setContaNumeroTriagem(info.NovaContaNumero)
      setContaDigitoTriagem(info.NovaContaDigito)
      setValorDebitoTriagem(info.NovoValorRecorrente)
      setVencimentoDebitoTriagem(info.NovoDiaVencimentoDebito)
      setOperacaoTriagem(info.NovoNumeroOperacao)

      setLoadingInfoAlterada(false);

    } else {
      console.log('error22', error);
    }
  }, []);


  async function onSubmitListaLigacao() {
    setLoadingCadastroLigacao(true);
    const services = new ComponentPerfilServices();
    const { error,  } = await services.CreateListaLigacaoTriagem(
      perfilID
    );

    setLoadingCadastroLigacao(false);

    if (!error) {
      enqueueSnackbar(`Cadastro adicionado na lista de ligação`, {
        variant: 'success',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      setLoadingCadastroLigacao(false);
      history.push('/ligacoes');


    } else {
      enqueueSnackbar(`Ocorreu um erro`, {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      setLoadingInfoAlterada(false);
    }

    //window.location.reload();
  }

  async function onSubmitConfirmarTriagem() {
    setLoadingConfirmarTriagem(true);
    const services = new ComponentPerfilServices();
    const { error,  } = await services.ConfirmarTriagem(
      perfilID
    );

    setLoadingConfirmarTriagem(false);

    if (!error) {
      enqueueSnackbar(`Cadastro triado com sucesso`, {
        variant: 'success',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      setLoadingConfirmarTriagem(false);

      if (idInteracao === undefined){
        history.push('/cadastros/consulta/triagem');
      }else if (idInteracao !== undefined){
        history.push(`/cadastro/${idPessoa}?telefonia={"id_ligacao_interacao":${idInteracao},"id_pessoa":${idPessoa},"id_perfil":${perfilID}}`);
      }

    } else {
      enqueueSnackbar(`Ocorreu um erro`, {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      setLoadingConfirmarTriagem(false);
    }

    //window.location.reload();
  }


  const searchCEPDebounce = async (value: string) => {
    const searchCEPCorreios = new SearchCEPCorreios();
    setLoadingCEP(true);
    const { error, response } = await searchCEPCorreios.SearchCEP(value);
    setLoadingCEP(false);
    if (!error && response) {
      setUF(response.uf);
      let endereco1 = response.logradouro;
      endereco1 = endereco1.toUpperCase();

      let bairro1 = response.bairro;
      bairro1 = bairro1.toUpperCase();
      formRef.setFieldsValue({
        endereco: endereco1,
        bairro: bairro1,
        estado_descricao: response.uf,
        //cidade_id: response.localidade,
      });
    }
  };


  const debounceCEP = useDebounce(searchCEPDebounce, 800);

  const init = async () => {
    const services = new ComponentPerfilServices();

      const formaContribuicaoResponse = await services.loadFormaContribById(formaContribuicaoTriagem );

      if (!formaContribuicaoResponse.error) {
        setFormaContribDescricao(formaContribuicaoResponse.response.descricao);
      }

      const correspResponse = await services.loadCorrespondenciaById(tipoCorrespondenciaTriagem);
      if (!correspResponse.error) {
        setTipoCorrespDescricao(correspResponse.response.descricao);
      }

      const cidadesResponse = await services.loadCidadeById(cidadeTriagem);
      if (!cidadesResponse.error) {
        setCidadeDescricao(cidadesResponse.response);
      }

      if (convenioTriagem === "2"){
        setconvenioDescricao('BANCO DO BRASIL')
      } else if (convenioTriagem === "6"){
        setconvenioDescricao('BANCO DO BRASIL')
      }else if (convenioTriagem === "72"){
        setconvenioDescricao('SANTANDER')
      }else if (convenioTriagem === "8"){
        setconvenioDescricao('CAIXA ECONOMICA FEDERAL')
      }else if (convenioTriagem === "9"){
        setconvenioDescricao('BRADESCO')
      }else if (convenioTriagem === "13"){
        setconvenioDescricao('ITAÚ UNIBANCO S.A.')
      }  }

  init();

  useEffect(() => {
    loadInfoAlteradas(perfilID);
    loadPerfilTriagem(perfilID);
  }, []);


  const finishTriagemFormaContrib = async () => {
    if (functionReload) {
      await functionReload();
    }
    setOpenModalFormaContrib(false);
  };

  const finishTriagemCorrespondencia = async () => {
    if (functionReload) {
      await functionReload();
    }
    setOpenModalCorrespondencia(false);
  };

  const finishTriagemValorPadrao = async () => {
    if (functionReload) {
      await functionReload();
    }
    setOpenModalValorPadrao(false);
  };

  const finishTriagemCelular = async () => {
    if (functionReload) {
      await functionReload();
    }
    setOpenModalCelular(false);
  };

  const finishTriagemWhatsApp = async () => {
    if (functionReload) {
      await functionReload();
    }
    setOpenModalWhatsApp(false);
  };

  const finishTriagemTelFixo = async () => {
    if (functionReload) {
      await functionReload();
    }
    setOpenModalTelFixo(false);
  };

  const finishTriagemTelComercial = async () => {
    if (functionReload) {
      await functionReload();
    }
    setOpenModalTelComercial(false);
  };

  const finishTriagemEmail = async () => {
    if (functionReload) {
      await functionReload();
    }
    setOpenModalEmail(false);
  };

  const finishTriagemCidade = async () => {
    if (functionReload) {
      await functionReload();
    }
    setOpenModalCidade(false);
  };

  const finishTriagemComplemento = async () => {
    if (functionReload) {
      await functionReload();
    }
    setOpenModalComplemento(false);
  };

  const finishTriagemEndereco = async () => {
    if (functionReload) {
      await functionReload();
    }
    setOpenModalEndereco(false);
  };

  const finishTriagemDistrito = async () => {
    if (functionReload) {
      await functionReload();
    }
    setOpenModalDistrito(false);
  };

  const finishTriagemBairro = async () => {
    if (functionReload) {
      await functionReload();
    }
    setOpenModalBairro(false);
  };

  const finishTriagemEstado = async () => {
    if (functionReload) {
      await functionReload();
    }
    setOpenModalEstado(false);
  };

  const finishTriagemCEP = async () => {
    if (functionReload) {
      await functionReload();
    }
    setOpenModalCEP(false);
  };

  const finishTriagemAgenciaNumero = async () => {
    if (functionReload) {
      await functionReload();
    }
    setOpenModalAgenciaNumero(false);
  };

  const finishTriagemAgenciaDigito = async () => {
    if (functionReload) {
      await functionReload();
    }
    setOpenModalAgenciaDigito(false);
  };

  const finishTriagemContaNumero = async () => {
    if (functionReload) {
      await functionReload();
    }
    setOpenModalContaNumero(false);
  };

  const finishTriagemContaDigito = async () => {
    if (functionReload) {
      await functionReload();
    }
    setOpenModalContaDigito(false);
  };

  const finishTriagemValorDebito = async () => {
    if (functionReload) {
      await functionReload();
    }
    setOpenModalValorDebito(false);
  };

  const finishTriagemVencimentoDebito = async () => {
    if (functionReload) {
      await functionReload();
    }
    setOpenModalVencimentoDebito(false);
  };

  const finishTriagemConvenio= async () => {
    if (functionReload) {
      await functionReload();
    }
    setOpenModalConvenio(false);
  };

  const finishTriagemOperacao= async () => {
    if (functionReload) {
      await functionReload();
    }
    setOpenModalOperacao(false);
  };


  return (
    <Grid container>
      <Grid container item sm={12}>
        <Grid item lg={6} xs={6} style={{ marginLeft: 3,  }}>
          <ButtonComponent
            color="primary"
            fullWidth
            onClick={() => onSubmitListaLigacao()}
            disabled={infoAlteracaoTriagem === (undefined)}

          >Adicionar a lista de ligação</ButtonComponent>
        </Grid>

        <Spin size="large" spinning={loadingUpdate} tip="Enviando dados...">


          {/* MODAIS */}

          <Modal
            title=""
            size="pequeno"
            openModal={openModalFormaContrib}
            controlModal={(value: boolean) => setOpenModalFormaContrib(value)}
          >

            <ModalTriagemFormaContrib
              formaContrib={perfilTriagem?.forma_contribuicao_descricao}
              finishFormaContrib={finishTriagemFormaContrib}
              formaContribNovo={formaContribuicaoTriagem}
              perfil={perfilID}
            />

          </Modal>

          <Modal
            title=""
            size="pequeno"
            openModal={openModalCorrespondencia}
            controlModal={(value: boolean) => setOpenModalCorrespondencia(value)}
          >

            <ModalTriagemCorrespondencia
              correspondencia={perfilTriagem?.tipo_correspondencia_descricao}
              finishCorrespondencia={finishTriagemCorrespondencia}
              correspondenciaNovo={tipoCorrespondenciaTriagem}
              perfil={perfilID}
            />

          </Modal>

          <Modal
            title=""
            size="pequeno"
            openModal={openModalValorPadrao}
            controlModal={(value: boolean) => setOpenModalValorPadrao(value)}
          >

            <ModalTriagemValorPadrao
              valorPadrao={perfilTriagem?.valor_padrao_doacao}
              finishValorPadrao={finishTriagemValorPadrao}
              valorPadraoNovo={valorPadraoTriagem}
              perfil={perfilID}
            />

          </Modal>

          <Modal
            title=""
            size="pequeno"
            openModal={openModalCelular}
            controlModal={(value: boolean) => setOpenModalCelular(value)}
          >

            <ModalTriagemCelular
              celular={perfilTriagem?.celular.contato_online_descricao}
              finishCelular={finishTriagemCelular}
              celularNovo={celularTriagem}
              perfil={perfilID}
            />

          </Modal>

          <Modal
            title=""
            size="pequeno"
            openModal={openModalWhatsApp}
            controlModal={(value: boolean) => setOpenModalWhatsApp(value)}
          >

            <ModalTriagemWhatsApp
              whatsApp={perfilTriagem?.whatsapp.contato_online_descricao}
              finishWhatsApp={finishTriagemWhatsApp}
              whatsAppNovo={whatsAppTriagem}
              perfil={perfilID}
            />

          </Modal>

          <Modal
            title=""
            size="pequeno"
            openModal={openModalTelFixo}
            controlModal={(value: boolean) => setOpenModalTelFixo(value)}
          >

            <ModalTriagemTelFixo
              telFixo={perfilTriagem?.telefone_fixo.contato_online_descricao}
              finishTelFixo={finishTriagemTelFixo}
              telFixoNovo={telefoneFixoTriagem}
              perfil={perfilID}
            />

          </Modal>

          <Modal
            title=""
            size="pequeno"
            openModal={openModalTelComercial}
            controlModal={(value: boolean) => setOpenModalTelComercial(value)}
          >

            <ModalTriagemTelComercial
              telComercial={perfilTriagem?.telefone_comercial.contato_online_descricao}
              finishTelComercial={finishTriagemTelComercial}
              telComercialNovo={telefoneComercialTriagem}
              perfil={perfilID}
            />

          </Modal>

          <Modal
            title=""
            size="pequeno"
            openModal={openModalEmail}
            controlModal={(value: boolean) => setOpenModalEmail(value)}
          >

            <ModalTriagemEmail
              email={perfilTriagem?.email.contato_online_descricao}
              finishEmail={finishTriagemEmail}
              emailNovo={emailTriagem}
              perfil={perfilID}
            />

          </Modal>


          <Modal
            title=""
            size="pequeno"
            openModal={openModalCidade}
            controlModal={(value: boolean) => setOpenModalCidade(value)}
          >

            <ModalTriagemCidade
              cidade={perfilTriagem?.contato_offline.cidade_descricao}
              finishCidade={finishTriagemCidade}
              cidadeNovo={cidadeTriagem}
              estado={perfilTriagem?.contato_offline.estado_descricao}
              perfil={perfilID}
            />

          </Modal>

          <Modal
            title=""
            size="pequeno"
            openModal={openModalComplemento}
            controlModal={(value: boolean) => setOpenModalComplemento(value)}
          >

            <ModalTriagemComplemento
              complemento={perfilTriagem?.contato_offline.complemento}
              finishComplemento={finishTriagemComplemento}
              complementoNovo={complementoTriagem}
              perfil={perfilID}
            />

          </Modal>

          <Modal
            title=""
            size="pequeno"
            openModal={openModalEndereco}
            controlModal={(value: boolean) => setOpenModalEndereco(value)}
          >

            <ModalTriagemEndereco
              endereco={perfilTriagem?.contato_offline.endereco}
              finishEndereco={finishTriagemEndereco}
              enderecoNovo={enderecoTriagem}
              perfil={perfilID}
            />

          </Modal>

          <Modal
            title=""
            size="pequeno"
            openModal={openModalDistrito}
            controlModal={(value: boolean) => setOpenModalDistrito(value)}
          >

            <ModalTriagemDistrito
              distrito={perfilTriagem?.contato_offline.Distrito}
              finishDistrito={finishTriagemDistrito}
              distritoNovo={distritoTriagem}
              perfil={perfilID}
            />

          </Modal>

          <Modal
            title=""
            size="pequeno"
            openModal={openModalBairro}
            controlModal={(value: boolean) => setOpenModalBairro(value)}
          >

            <ModalTriagemBairro
              bairro={perfilTriagem?.contato_offline.bairro}
              finishBairro={finishTriagemBairro}
              bairroNovo={bairroTriagem}
              perfil={perfilID}
            />

          </Modal>

          <Modal
            title=""
            size="pequeno"
            openModal={openModalEstado}
            controlModal={(value: boolean) => setOpenModalEstado(value)}
          >

            <ModalTriagemEstado
              estado={perfilTriagem?.contato_offline.estado_descricao}
              finishEstado={finishTriagemEstado}
              estadoNovo={estadoTriagem}
              perfil={perfilID}
            />

          </Modal>

          <Modal
            title=""
            size="pequeno"
            openModal={openModalCEP}
            controlModal={(value: boolean) => setOpenModalCEP(value)}
          >

            <ModalTriagemCEP
              cep={perfilTriagem?.contato_offline.cep}
              finishCep={finishTriagemCEP}
              cepNovo={cepTriagem}
              perfil={perfilID}
            />

          </Modal>

          <Modal
            title=""
            size="pequeno"
            openModal={openModalAgenciaNumero}
            controlModal={(value: boolean) => setOpenModalAgenciaNumero(value)}
          >

            <ModalTriagemAgenciaNumero
              agenciaNumero={perfilTriagem?.debito_automatico.agencia_numero}
              finishAgenciaNumero={finishTriagemAgenciaNumero}
              agenciaNumeroNovo={agenciaNumeroTriagem}
              perfil={perfilID}
            />

          </Modal>

          <Modal
            title=""
            size="pequeno"
            openModal={openModalAgenciaDigito}
            controlModal={(value: boolean) => setOpenModalAgenciaDigito(value)}
          >

            <ModalTriagemAgenciaDigito
              agenciaDigito={perfilTriagem?.debito_automatico.agencia_digito}
              finishAgenciaDigito={finishTriagemAgenciaDigito}
              agenciaDigitoNovo={agenciaDigitoTriagem}
              perfil={perfilID}
            />

          </Modal>

          <Modal
            title=""
            size="pequeno"
            openModal={openModalContaNumero}
            controlModal={(value: boolean) => setOpenModalContaNumero(value)}
          >

            <ModalTriagemContaNumero
              contaNumero={perfilTriagem?.debito_automatico.conta_numero}
              finishContaNumero={finishTriagemContaNumero}
              contaNumeroNovo={contaNumeroTriagem}
              perfil={perfilID}
            />

          </Modal>

          <Modal
            title=""
            size="pequeno"
            openModal={openModalContaDigito}
            controlModal={(value: boolean) => setOpenModalContaDigito(value)}
          >

            <ModalTriagemContaDigito
              contaDigito={perfilTriagem?.debito_automatico.conta_digito}
              finishContaDigito={finishTriagemContaDigito}
              contaDigitoNovo={contaDigitoTriagem}
              perfil={perfilID}
            />

          </Modal>

          <Modal
            title=""
            size="pequeno"
            openModal={openModalValorDebito}
            controlModal={(value: boolean) => setOpenModalValorDebito(value)}
          >

            <ModalTriagemValorDebito
              valorDebito={perfilTriagem?.debito_automatico.valor}
              finishValorDebito={finishTriagemValorDebito}
              valorDebitoNovo={valorDebitoTriagem}
              perfil={perfilID}
            />

          </Modal>

          <Modal
            title=""
            size="pequeno"
            openModal={openModalVencimentoDebito}
            controlModal={(value: boolean) => setOpenModalVencimentoDebito(value)}
          >

            <ModalTriagemDiaVencimento
              vencimentoDebito={perfilTriagem?.debito_automatico.dia_vencimento}
              finishVencimentoDebito={finishTriagemVencimentoDebito}
              vencimentoDebitoNovo={vencimentoDebitoTriagem}
              perfil={perfilID}
            />

          </Modal>

          <Modal
            title=""
            size="pequeno"
            openModal={openModalConvenio}
            controlModal={(value: boolean) => setOpenModalConvenio(value)}
          >

            <ModalTriagemConvenio
              convenio={perfilTriagem?.debito_automatico.id_convenio}
              finishConvenio={finishTriagemConvenio}
              convenioNovo={convenioTriagem}
              perfil={perfilID}
            />

          </Modal>

          <Modal
            title=""
            size="pequeno"
            openModal={openModalOperacao}
            controlModal={(value: boolean) => setOpenModalOperacao(value)}
          >

            <ModalTriagemOperacao
              //convenio={perfilTriagem?.debito_automatico.id_convenio}
              finishOperacao={finishTriagemOperacao}
              contrib={formaContribuicaoTriagem}
              perfil={perfilID}
              contrib2={checkTipoConvenio}
            />

          </Modal>

          {/*FORMULARIO */}

          <Form
            form={formRef}
            size="middle"
            layout="vertical"
            scrollToFirstError={true}
            //onFinish={onSubmitCheckMotivo}
            labelAlign="left"
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
            requiredMark={false}
          >
            <Grid
              container item sm={12} xs={12} lg={12}

              justify="center"
              alignItems="center"
              style={{paddingTop: 3}}
            >

              <Grid item lg={12} xs={12}>
                <Form.Item name="tipo_perfil_descricao">
                  <Input disabled
                     suffix={ <InvisibleContent visible={tipoPerfilTriagem !== (undefined)}><IconButton
                      size='small'
                      //disabled={tipoPerfilTriagem === (undefined)}
                        color="secondary"
                        onClick={() => setOpenModalCidade(true)}
                        style={{ minWidth: 0, height: 10}}
                      >
                        <Edit fontSize="small" />



                      </IconButton></InvisibleContent>}
                      placeholder="Tipo de perfil" />
                </Form.Item>
              </Grid>

              <Grid container justify="center" alignItems="center">

                {/* ORIGEM MACRO, ESPECIFICA e MOTIVO */}

                <Grid container>
                  <Grid item lg={12} xs={12}>
                    <Form.Item
                      label="Origem macro"
                      name="origem_descricao"
                    >
                      <Input disabled placeholder="Origem macro" />
                    </Form.Item>
                  </Grid>

                  <Grid item lg={12} sm={12} xs={12} style={{ marginTop: 10 }}>
                    <Form.Item
                      name="origem_id"
                      label="Origem específica"
                    >
                      <Input disabled placeholder="Origem específica" />
                    </Form.Item>
                  </Grid>
                </Grid>

                <Grid item sm={12} xs={12}>
                  <Form.Item
                    name="motivo_cadastro_id"
                    label="Motivo"
                  >
                    <Input disabled placeholder="Motivo" />
                  </Form.Item>
                </Grid>

                {/* FORMA DE CONTRIBUIÇÃO CORRESPONDÊNCIA E DÉBITO AUTOMÁTICO */}
                <Grid container>

                  {/* FORMA DE CONTRIBUIÇÃO */}
                  <Grid item sm={6} xs={12}>

                    <InvisibleContent visible={formaContribuicaoTriagem === (undefined)}>
                      <Form.Item
                        name="forma_contribuicao_id"
                        label="Forma de contrib."
                      >
                        <Input disabled placeholder="Forma de contrib." />
                      </Form.Item>
                    </InvisibleContent>

                    <InvisibleContent visible={formaContribuicaoTriagem !== (undefined)}>
                      <Form.Item
                        label="Forma de contrib."
                      >
                        <Input
                          disabled

                          placeholder="Forma de contrib."
                          value={formaContribDescricao}
                          style={{ borderColor: '#ff2800', color: "#464646" }}
                          suffix={
                            <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                              <IconButton
                                size="small"
                                color="secondary"
                                onClick={() => setOpenModalFormaContrib(true)}
                                style={{ minWidth: 0, height: 10 }}
                              >
                                <Edit fontSize="small" />
                              </IconButton>

                              <IconButton
                                size="small"
                                color="secondary"
                                onClick={() => onSubmitValidarFormaContrib()}
                                style={{ minWidth: 0, height: 10 }}
                              >
                                <CheckCircle style={{color: '#423B7D'}} fontSize="small" />
                              </IconButton>
                            </div>
                          }
                        />
                      </Form.Item>
                    </InvisibleContent>
                  </Grid>

                  {/* TIPO DE CORRESPONDÊNCIA */}

                  <Grid item sm={6} xs={12}>

                  <InvisibleContent visible={tipoCorrespondenciaTriagem === (undefined)}>
                    <Form.Item
                      name="tipo_correspondencia_id"
                      label="Tipo de corresp."
                    >
                      <Input disabled placeholder="Tipo de corresp." />

                    </Form.Item>
                    </InvisibleContent>


                    <InvisibleContent visible={tipoCorrespondenciaTriagem !== (undefined)}>
                    <Form.Item
                      //name="tipo_correspondencia_id"
                      label="Tipo de corresp."
                    >
                      <Input
                          disabled
                          placeholder="Tipo de corresp."
                          value={tipoCorrespDescricao}
                          style={{ borderColor: '#ff2800', color: "#464646" }}
                          suffix={
                            <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                              <IconButton
                                size="small"
                                color="secondary"
                                onClick={() => setOpenModalCorrespondencia(true)}
                                style={{ minWidth: 0, height: 10 }}
                              >
                                <Edit fontSize="small" />
                              </IconButton>

                              <IconButton
                                size="small"
                                color="secondary"
                                onClick={() => onSubmitValidarCorrespondencia()}
                                style={{ minWidth: 0, height: 10 }}
                              >
                                <CheckCircle style={{color: '#423B7D'}} fontSize="small" />
                              </IconButton>
                            </div>
                          }
                        />

                    </Form.Item>
                    </InvisibleContent>
                  </Grid>

                  {/* INFOS DÉBITO AUTOMÁTICO */}

                  <InvisibleContent
                    visible={checkTipoFormContribuicao === 'DÉBITO AUTOMÁTICO' || formaContribuicaoTriagem === "2"}
                  >
                    <>
                      {/* CONVÊNIO */}
                      <Grid item sm={6} xs={12}>
                        <InvisibleContent visible={convenioTriagem === (undefined)}>
                          <Form.Item
                            name="convenio_id"
                            label="Convênio"
                          >
                            <Input disabled placeholder="Convênio" />
                          </Form.Item>
                        </InvisibleContent>

                        <InvisibleContent visible={convenioTriagem !== (undefined)}>
                          <Form.Item
                            //name="convenio_id"
                            label="Convênio"
                          >

                          <Input
                            disabled
                            placeholder="Convenio"
                            value={convenioDescricao}
                            style={{ borderColor: '#ff2800', color: "#464646" }}
                            suffix={
                              <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                                <IconButton
                                  size="small"
                                  color="secondary"
                                  onClick={() => setOpenModalConvenio(true)}
                                  style={{ minWidth: 0, height: 10 }}
                                >
                                  <Edit fontSize="small" />
                                </IconButton>

                                <IconButton
                                  size="small"
                                  color="secondary"
                                  onClick={() => onSubmitValidarConvenio()}
                                  style={{ minWidth: 0, height: 10 }}
                                >
                                  <CheckCircle style={{color: '#423B7D'}} fontSize="small" />
                                </IconButton>
                              </div>
                            }
                          >
                          </Input>

                        </Form.Item>
                        </InvisibleContent>
                      </Grid>

                      {/* DIA VENCIMENTO */}

                      <Grid item sm={6} xs={12}>
                        <InvisibleContent visible={vencimentoDebitoTriagem === (undefined)}>
                          <Form.Item
                            name="dia_vencimento"
                            label="Dia do vencimento"
                          >
                            <Input disabled placeholder="Dia vencimento" />

                          </Form.Item>
                        </InvisibleContent>

                        <InvisibleContent visible={vencimentoDebitoTriagem !== (undefined)}>
                          <Form.Item
                            //name="dia_vencimento"
                            label="Dia do vencimento"
                          >
                            <Input disabled
                              placeholder="Dia vencimento"
                              defaultValue={vencimentoDebitoTriagem}
                              style={{ borderColor: '#ff2800', color: "#464646" }}
                              suffix={
                                <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                                  <IconButton
                                    size="small"
                                    color="secondary"
                                    onClick={() => setOpenModalVencimentoDebito(true)}
                                    style={{ minWidth: 0, height: 10 }}
                                  >
                                    <Edit fontSize="small" />
                                  </IconButton>

                                  <IconButton
                                    size="small"
                                    color="secondary"
                                    onClick={() => onSubmitValidarVencimentoDebito()}
                                    style={{ minWidth: 0, height: 10 }}
                                  >
                                    <CheckCircle style={{color: '#423B7D'}} fontSize="small" />
                                  </IconButton>
                                </div>
                              }
                            />
                          </Form.Item>
                        </InvisibleContent>
                      </Grid>

                      {/* AGÊNCIA NÚMERO */}

                      <Grid item sm={9} xs={12}>

                        <InvisibleContent visible={agenciaNumeroTriagem === (undefined)}>
                          <Form.Item
                            name="agencia_numero"
                            label="Número da Agência"
                          >
                            <Input disabled placeholder="Número da agência" />
                          </Form.Item>
                        </InvisibleContent>

                        <InvisibleContent visible={agenciaNumeroTriagem !== (undefined)}>
                          <Form.Item
                            //name="agencia_numero"
                            label="Número da Agência"
                          >
                            <Input
                              disabled
                              placeholder="Número agência"
                              defaultValue={agenciaNumeroTriagem}
                              style={{ borderColor: '#ff2800', color: "#464646" }}
                              suffix={
                                <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                                  <IconButton
                                    size="small"
                                    color="secondary"
                                    onClick={() => setOpenModalAgenciaNumero(true)}
                                    style={{ minWidth: 0, height: 10 }}
                                  >
                                    <Edit fontSize="small" />
                                  </IconButton>

                                  <IconButton
                                    size="small"
                                    color="secondary"
                                    onClick={() => onSubmitValidarAgenciaNumero()}
                                    style={{ minWidth: 0, height: 10 }}
                                  >
                                    <CheckCircle style={{color: '#423B7D'}} fontSize="small" />
                                  </IconButton>
                                </div>
                              }
                            />
                          </Form.Item>
                        </InvisibleContent>
                      </Grid>

                      {/* AGÊNCIA NÚMERO */}
                      <Grid item sm={3} xs={12}>

                        <InvisibleContent visible={agenciaDigitoTriagem === (undefined)}>
                          <Form.Item
                            name="agencia_digito"
                            label="Dígito da agência"
                          >
                            <Input disabled placeholder="Dígito da agência" />
                          </Form.Item>
                        </InvisibleContent>

                        <InvisibleContent visible={agenciaDigitoTriagem !== (undefined)}>
                          <Form.Item
                            //name="agencia_digito"
                            label="Dígito da agência"
                          >
                            <Input
                              disabled
                              placeholder="Número agência"
                              defaultValue={agenciaDigitoTriagem}
                              style={{ borderColor: '#ff2800', color: "#464646" }}
                              suffix={
                                <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                                  <IconButton
                                    size="small"
                                    color="secondary"
                                    onClick={() => setOpenModalAgenciaDigito(true)}
                                    style={{ minWidth: 0, height: 10 }}
                                  >
                                    <Edit fontSize="small" />
                                  </IconButton>

                                  <IconButton
                                    size="small"
                                    color="secondary"
                                    onClick={() => onSubmitValidarAgenciaDigito()}
                                    style={{ minWidth: 0, height: 10 }}
                                  >
                                    <CheckCircle style={{color: '#423B7D'}} fontSize="small" />
                                  </IconButton>
                                </div>
                              }
                            />
                          </Form.Item>
                        </InvisibleContent>
                      </Grid>

                       {/* CONTA NÚMERO */}
                      <Grid item sm={9} xs={12}>

                        <InvisibleContent visible={contaNumeroTriagem === (undefined)}>
                          <Form.Item
                            name="conta_numero"
                            label="Número da conta"
                          >
                            <Input disabled placeholder="Número da conta" />
                          </Form.Item>
                        </InvisibleContent>

                        <InvisibleContent visible={contaNumeroTriagem !== (undefined)}>
                          <Form.Item
                            //name="conta_numero"
                            label="Número da conta"
                          >
                            <Input
                              disabled
                              placeholder="Número conta"
                              defaultValue={contaNumeroTriagem}
                              style={{ borderColor: '#ff2800', color: "#464646" }}
                              suffix={
                                <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                                  <IconButton
                                    size="small"
                                    color="secondary"
                                    onClick={() => setOpenModalContaNumero(true)}
                                    style={{ minWidth: 0, height: 10 }}
                                  >
                                    <Edit fontSize="small" />
                                  </IconButton>

                                  <IconButton
                                    size="small"
                                    color="secondary"
                                    onClick={() => onSubmitValidarContaNumero()}
                                    style={{ minWidth: 0, height: 10 }}
                                  >
                                    <CheckCircle style={{color: '#423B7D'}} fontSize="small" />
                                  </IconButton>
                                </div>
                              }
                            />
                          </Form.Item>
                        </InvisibleContent>
                      </Grid>

                      {/* CONTA DIGITO */}
                      <Grid item sm={3} xs={12}>

                        <InvisibleContent visible={contaDigitoTriagem === (undefined)}>
                          <Form.Item
                            name="conta_digito"
                            label="Digito da conta"
                          >
                            <Input disabled placeholder="Dígito da conta" />

                          </Form.Item>
                        </InvisibleContent>

                        <InvisibleContent visible={contaDigitoTriagem !== (undefined)}>
                          <Form.Item
                            //name="conta_digito"
                            label="Digito da conta"
                          >
                            <Input
                              disabled
                              placeholder="Dígito conta"
                              defaultValue={contaDigitoTriagem}
                              style={{ borderColor: '#ff2800', color: "#464646" }}
                              suffix={
                                <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                                  <IconButton
                                    size="small"
                                    color="secondary"
                                    onClick={() => setOpenModalContaDigito(true)}
                                    style={{ minWidth: 0, height: 10 }}
                                  >
                                    <Edit fontSize="small" />
                                  </IconButton>

                                  <IconButton
                                    size="small"
                                    color="secondary"
                                    onClick={() => onSubmitValidarContaDigito()}
                                    style={{ minWidth: 0, height: 10 }}
                                  >
                                    <CheckCircle style={{color: '#423B7D'}} fontSize="small" />
                                  </IconButton>
                                </div>
                              }
                            />
                          </Form.Item>
                        </InvisibleContent>
                      </Grid>

                      {/* OPERAÇÃO */}

                      <InvisibleContent
                        visible={checkTipoConvenio === 'CAIXA ECONOMICA FEDERAL' || checkTipoConvenio === 'SANTANDER' || convenioDescricao === 'CAIXA ECONOMICA FEDERAL' || convenioDescricao === 'SANTANDER' }
                      >
                        <Grid item sm={6} xs={12}>
                          <InvisibleContent visible={operacaoTriagem === (undefined)}>
                            <Form.Item
                              name="operacao"
                              label="Operação"
                            >
                              <Input disabled placeholder="Operação" />
                            </Form.Item>
                          </InvisibleContent>

                          <InvisibleContent visible={operacaoTriagem !== (undefined)}>
                            <Form.Item
                              //name="operacao"
                              label="Operação"
                            >
                              <Input
                                disabled
                                placeholder="Operação"
                                defaultValue={operacaoTriagem}
                                style={{ borderColor: '#ff2800', color: "#464646" }}
                                suffix={
                                  <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                                    <IconButton
                                      size="small"
                                      color="secondary"
                                      onClick={() => setOpenModalOperacao(true)}
                                      style={{ minWidth: 0, height: 10 }}
                                    >
                                      <Edit fontSize="small" />
                                    </IconButton>

                                    <IconButton
                                      size="small"
                                      color="secondary"
                                      onClick={() => onSubmitValidarOperacao()}
                                      style={{ minWidth: 0, height: 10 }}
                                    >
                                      <CheckCircle style={{color: '#423B7D'}} fontSize="small" />
                                    </IconButton>
                                  </div>
                                }
                              />
                            </Form.Item>
                          </InvisibleContent>
                        </Grid>
                      </InvisibleContent>

                      {/* VALOR DÉBITO */}
                       <Grid item sm={6} xs={12}>

                          <InvisibleContent visible={valorDebitoTriagem === (undefined)}>
                            <Form.Item
                              name="valor_debito"
                              label="Valor do débito"
                            >
                              <Input disabled placeholder="Valor débito"
                                onChange={(e: any) => {
                                  formRef.setFieldsValue({
                                    valor_debito: isReal(e.target.value),
                                  });
                                  setValorModifyValorDebito(e.target.value);
                                }}
                              />
                            </Form.Item>
                          </InvisibleContent>

                          <InvisibleContent visible={valorDebitoTriagem !== (undefined)}>
                            <Form.Item
                              //name="operacao"
                              label="Valor do débito"
                            >
                              <Input
                                disabled
                                placeholder="Valor do débito"
                                defaultValue={valorDebitoTriagem}
                                style={{ borderColor: '#ff2800', color: "#464646" }}
                                suffix={
                                  <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                                    <IconButton
                                      size="small"
                                      color="secondary"
                                      onClick={() => setOpenModalValorDebito(true)}
                                      style={{ minWidth: 0, height: 10 }}
                                    >
                                      <Edit fontSize="small" />
                                    </IconButton>

                                    <IconButton
                                      size="small"
                                      color="secondary"
                                      onClick={() => onSubmitValidarValorDebito()}
                                      style={{ minWidth: 0, height: 10 }}
                                    >
                                      <CheckCircle style={{color: '#423B7D'}} fontSize="small" />
                                    </IconButton>
                                  </div>
                                }
                              />
                            </Form.Item>
                          </InvisibleContent>
                        </Grid>
                    </>
                  </InvisibleContent>
                </Grid>

                {/* { VALOR DEMAIS FORMAS DE CONTRIBUIÇÃO } */}

                <InvisibleContent visible={checkTipoFormContribuicao != 'DÉBITO AUTOMÁTICO' || formaContribuicaoTriagem != "2"}>

                  {/* { VALOR PADRÃO DOAÇÃO } */}
                  <Grid item lg={12} xs={12}>

                    <InvisibleContent visible={valorPadraoTriagem === (undefined)}>
                      <Form.Item
                        label="Valor padrão doação"
                        name="valor_padrao_doacao"
                      >
                        <Input disabled placeholder="Valor"
                          onChange={(e: any) => {
                            formRef.setFieldsValue({
                              valor_padrao_doacao: isReal(e.target.value),
                            });
                            setValorModifyValorDebito(e.target.value);
                          }}
                        />
                      </Form.Item>
                    </InvisibleContent>

                    <InvisibleContent visible={valorPadraoTriagem !== (undefined)}>
                      <Form.Item
                        label="Valor padrão doação"
                        //name="valor_padrao_doacao"
                      >
                        <Input
                          disabled
                          placeholder="Valor"
                          defaultValue={valorPadraoTriagem}
                          style={{ borderColor: '#ff2800', color: "#464646" }}
                          suffix={
                            <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                              <IconButton
                                size="small"
                                color="secondary"
                                onClick={() => setOpenModalValorPadrao(true)}
                                style={{ minWidth: 0, height: 10 }}
                              >
                                <Edit fontSize="small" />
                              </IconButton>

                              <IconButton
                                size="small"
                                color="secondary"
                                onClick={() => onSubmitValidarValorPadrao()}
                                style={{ minWidth: 0, height: 10 }}
                              >
                                <CheckCircle style={{color: '#423B7D'}} fontSize="small" />
                              </IconButton>
                            </div>
                          }
                        />
                      </Form.Item>
                    </InvisibleContent>
                  </Grid>
                </InvisibleContent>


                {/* CONTATO ONLINE - celular, whatsapp, tel fixo, tel comercial, email */}

                <Grid container>

                  {/* CELULAR */}
                  <Grid item lg={6} xs={12}>

                    <InvisibleContent visible={celularTriagem === (undefined)}>
                      <Form.Item
                        label="Celular"
                        name="celular"
                      >
                        <MaskedInput
                          disabled
                          mask='(11) 11111-1111'
                          placeholder="Celular"
                        />
                      </Form.Item>
                    </InvisibleContent>

                    <InvisibleContent visible={celularTriagem !== (undefined)}>
                      <Form.Item
                        label="Celular"
                        //name="celular"
                      >
                        <MaskedInput
                          disabled
                          mask='(11) 11111-1111'
                          placeholder="Celular"
                          defaultValue={celularTriagem}
                          style={{ borderColor: '#ff2800', color: "#464646" }}
                          suffix={
                            <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                              <IconButton
                                size="small"
                                color="secondary"
                                onClick={() => setOpenModalCelular(true)}
                                style={{ minWidth: 0, height: 10 }}
                              >
                                <Edit fontSize="small" />
                              </IconButton>

                              <IconButton
                                size="small"
                                color="secondary"
                                onClick={() => onSubmitValidarCelular()}
                                style={{ minWidth: 0, height: 10 }}
                              >
                                <CheckCircle style={{color: '#423B7D'}} fontSize="small" />
                              </IconButton>
                            </div>
                          }
                        />
                      </Form.Item>
                    </InvisibleContent>
                  </Grid>

                  {/* WHATSAPP */}
                  <Grid item lg={6} xs={12}>

                    <InvisibleContent visible={whatsAppTriagem === (undefined)}>
                      <Form.Item
                        label="WhatsApp"
                        name="whatsapp"
                      >
                        <MaskedInput
                          mask="(11) 11111-1111"
                          placeholder="WhatsApp"
                          disabled
                        />
                      </Form.Item>
                    </InvisibleContent>

                    <InvisibleContent visible={whatsAppTriagem !== (undefined)}>
                      <Form.Item
                        label="WhatsApp"
                        //name="whatsapp"
                      >
                        <MaskedInput
                          mask="(11) 11111-1111"
                          placeholder="WhatsApp"
                          disabled
                          defaultValue={whatsAppTriagem}
                          style={{ borderColor: '#ff2800', color: "#464646" }}
                          suffix={
                            <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                              <IconButton
                                size="small"
                                color="secondary"
                                onClick={() => setOpenModalWhatsApp(true)}
                                style={{ minWidth: 0, height: 10 }}
                              >
                                <Edit fontSize="small" />
                              </IconButton>

                              <IconButton
                                size="small"
                                color="secondary"
                                onClick={() => onSubmitValidarWhatsApp()}
                                style={{ minWidth: 0, height: 10 }}
                              >
                                <CheckCircle style={{color: '#423B7D'}} fontSize="small" />
                              </IconButton>
                            </div>
                          }
                        />
                      </Form.Item>
                    </InvisibleContent>
                  </Grid>

                  {/* TELEFONE FIXO */}

                  <Grid item lg={6} xs={12}>

                    <InvisibleContent visible={telefoneFixoTriagem === (undefined)}>
                      <Form.Item
                        label="Telefone fixo"
                        name="telefone_fixo"
                      >
                        <MaskedInput
                          mask='(11) 1111-1111'
                          disabled
                          placeholder="Telefone fixo"
                        />
                      </Form.Item>
                    </InvisibleContent>

                    <InvisibleContent visible={telefoneFixoTriagem !== (undefined)}>
                      <Form.Item
                        label="Telefone fixo"
                        //name="telefone_fixo"
                      >
                        <MaskedInput
                          mask='(11) 1111-1111'
                          disabled
                          placeholder="Telefone fixo"
                          defaultValue={telefoneFixoTriagem}
                          style={{ borderColor: '#ff2800', color: "#464646" }}
                          suffix={
                            <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                              <IconButton
                                size="small"
                                color="secondary"
                                onClick={() => setOpenModalTelFixo(true)}
                                style={{ minWidth: 0, height: 10 }}
                              >
                                <Edit fontSize="small" />
                              </IconButton>

                              <IconButton
                                size="small"
                                color="secondary"
                                onClick={() => onSubmitValidarTelFixo()}
                                style={{ minWidth: 0, height: 10 }}
                              >
                                <CheckCircle style={{color: '#423B7D'}} fontSize="small" />
                              </IconButton>
                            </div>
                          }
                        />
                      </Form.Item>
                    </InvisibleContent>
                  </Grid>

                  {/* TELEFONE COMERCIAL */}
                  <Grid item lg={6} xs={12}>

                    <InvisibleContent visible={telefoneComercialTriagem === (undefined)}>
                      <Form.Item
                        label="Telefone comercial"
                        name="telefone_comercial"
                      >
                        <MaskedInput
                          mask="(11) 1111-1111"
                          placeholder="Telefone comercial"
                          disabled
                        />
                      </Form.Item>
                    </InvisibleContent>

                    <InvisibleContent visible={telefoneComercialTriagem !== (undefined)}>
                      <Form.Item
                        label="Telefone comercial"
                        //name="telefone_comercial"
                      >
                        <MaskedInput
                          mask="(11) 1111-1111"
                          placeholder="Telefone comercial"
                          disabled
                          defaultValue={telefoneComercialTriagem}
                          style={{ borderColor: '#ff2800', color: "#464646" }}
                          suffix={
                            <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                              <IconButton
                                size="small"
                                color="secondary"
                                onClick={() => setOpenModalTelComercial(true)}
                                style={{ minWidth: 0, height: 10 }}
                              >
                                <Edit fontSize="small" />
                              </IconButton>

                              <IconButton
                                size="small"
                                color="secondary"
                                onClick={() => onSubmitValidarTelComercial()}
                                style={{ minWidth: 0, height: 10 }}
                              >
                                <CheckCircle style={{color: '#423B7D'}} fontSize="small" />
                              </IconButton>
                            </div>
                          }
                        />
                      </Form.Item>
                    </InvisibleContent>
                  </Grid>

                  {/* E-MAIL */}
                  <Grid item lg={12} xs={12}>

                    <InvisibleContent visible={emailTriagem === (undefined)}>
                      <Form.Item
                        label="E-mail"
                        name="email"
                      >
                        <Input disabled placeholder="E-mail"/>
                      </Form.Item>
                    </InvisibleContent>

                    <InvisibleContent visible={emailTriagem !== (undefined)}>
                      <Form.Item
                        label="E-mail"
                      //name="email"
                      >
                        <Input
                          value={emailTriagem}
                          placeholder="E-mail"
                          style={{ borderColor: '#ff2800', color: "#464646" }}
                          disabled
                          suffix={
                            <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                              <IconButton
                                size="small"
                                color="secondary"
                                onClick={() => setOpenModalEmail(true)}
                                style={{ minWidth: 0, height: 10 }}
                              >
                                <Edit fontSize="small" />
                              </IconButton>

                              <IconButton
                                size="small"
                                color="secondary"
                                onClick={() => onSubmitValidarEmail()}
                                style={{ minWidth: 0, height: 10 }}
                              >
                                <CheckCircle fontSize="small" />
                              </IconButton>
                            </div>
                          }
                        />
                      </Form.Item>
                    </InvisibleContent>
                  </Grid>
                </Grid>


                {/* { CONTATO OFFLINE cep, estado, cidade, endereco, bairro, distrito } */}

                <Spin spinning={loadingCEP} tip="Buscando dados do CEP">
                  <Grid container>
                    {/* CEP */}
                    <Grid item lg={12} xs={12}>

                      <InvisibleContent visible={cepTriagem === (undefined)}>
                        <Form.Item
                          label="CEP"
                          name="cep"
                        >
                          <MaskedInput
                            disabled
                            mask="11111-111"
                            placeholder="CEP"
                          />
                        </Form.Item>
                      </InvisibleContent>

                      <InvisibleContent visible={cepTriagem !== (undefined)}>
                        <Form.Item
                          label="CEP"
                          //name="cep"
                        >
                          <MaskedInput
                            disabled
                            mask="11111-111"
                            placeholder="CEP"
                            defaultValue={cepTriagem}
                            style={{ borderColor: '#ff2800', color: "#464646" }}
                            suffix={
                              <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                                <IconButton
                                  size="small"
                                  color="secondary"
                                  onClick={() => setOpenModalCEP(true)}
                                  style={{ minWidth: 0, height: 10 }}
                                >
                                  <Edit fontSize="small" />
                                </IconButton>

                                <IconButton
                                  size="small"
                                  color="secondary"
                                  onClick={() => onSubmitValidarCEP()}
                                  style={{ minWidth: 0, height: 10 }}
                                >
                                  <CheckCircle style={{color: '#423B7D'}} fontSize="small" />
                                </IconButton>
                              </div>
                            }
                          />
                        </Form.Item>
                      </InvisibleContent>
                    </Grid>

                    {/* ESTADO  */}
                    <Grid item sm={6} xs={12}>

                      <InvisibleContent visible={estadoTriagem === (undefined)}>
                        <Form.Item
                          label="Estado"
                          name="estado_descricao"
                        >
                          <Input disabled placeholder="Estado" />
                        </Form.Item>
                      </InvisibleContent>

                      <InvisibleContent visible={estadoTriagem !== (undefined)}>
                        <Form.Item
                          label="Estado"
                          //name="estado_descricao"
                        >
                          <Input
                            disabled
                            placeholder="Estado"
                            defaultValue={estadoTriagem}
                            style={{ borderColor: '#ff2800', color: "#464646" }}
                            suffix={
                              <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                                <IconButton
                                  size="small"
                                  color="secondary"
                                  onClick={() => setOpenModalEstado(true)}
                                  style={{ minWidth: 0, height: 10 }}
                                >
                                  <Edit fontSize="small" />
                                </IconButton>

                                <IconButton
                                  size="small"
                                  color="secondary"
                                  onClick={() => setOpenModalEstado(true)}
                                  style={{ minWidth: 0, height: 10 }}
                                >
                                  <CheckCircle style={{color: '#423B7D'}} fontSize="small" />
                                </IconButton>
                              </div>
                            }
                          />
                        </Form.Item>
                      </InvisibleContent>
                    </Grid>

                    {/* CIDADE  */}
                    <Grid item sm={6} xs={12}>

                      <InvisibleContent visible={cidadeTriagem === (undefined)}>
                        <Form.Item
                          label="Cidade"
                          name="cidade_id"
                        >
                          <Input disabled placeholder="Cidade" />
                        </Form.Item>
                      </InvisibleContent>

                      <InvisibleContent visible={cidadeTriagem !== (undefined)}>
                        <Form.Item
                          label="Cidade"
                          //name="cidade_id"
                        >
                          <Input
                            disabled
                            placeholder="Cidade"
                            value={cidadeDescricao}
                            style={{ borderColor: '#ff2800', color: "#464646" }}
                            suffix={
                              <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                                <IconButton
                                  size="small"
                                  color="secondary"
                                  onClick={() => setOpenModalCidade(true)}
                                  style={{ minWidth: 0, height: 10 }}
                                >
                                  <Edit fontSize="small" />
                                </IconButton>

                                <IconButton
                                  size="small"
                                  color="secondary"
                                  onClick={() => onSubmitValidarCidade()}
                                  style={{ minWidth: 0, height: 10 }}
                                >
                                  <CheckCircle style={{color: '#423B7D'}} fontSize="small" />
                                </IconButton>
                              </div>
                            }
                          />
                        </Form.Item>
                      </InvisibleContent>
                    </Grid>

                    {/* ENDEREÇO  */}
                    <Grid item lg={12} xs={12}>

                      <InvisibleContent visible={enderecoTriagem === (undefined)}>
                        <Form.Item
                          label="Endereço"
                          name="endereco"
                        >
                          <Input disabled placeholder="Endereço"/>
                        </Form.Item>
                      </InvisibleContent>

                      <InvisibleContent visible={enderecoTriagem !== (undefined)}>
                        <Form.Item
                          label="Endereço"
                          //name="endereco"
                        >
                          <Input
                            disabled
                            placeholder="Endereço"
                            defaultValue={enderecoTriagem}
                            style={{ borderColor: '#ff2800', color: "#464646" }}
                            suffix={
                              <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                                <IconButton
                                  size="small"
                                  color="secondary"
                                  onClick={() => setOpenModalEndereco(true)}
                                  style={{ minWidth: 0, height: 10 }}
                                >
                                  <Edit fontSize="small" />
                                </IconButton>

                                <IconButton
                                  size="small"
                                  color="secondary"
                                  onClick={() => onSubmitValidarEndereco()}
                                  style={{ minWidth: 0, height: 10 }}
                                >
                                  <CheckCircle style={{color: '#423B7D'}} fontSize="small" />
                                </IconButton>
                              </div>
                            }
                          />
                        </Form.Item>
                      </InvisibleContent>
                    </Grid>


                    {/* BAIRRO */}
                    <Grid item sm={6} xs={12}>

                      <InvisibleContent visible={bairroTriagem === (undefined)}>
                        <Form.Item
                          label="Bairro"
                          name="bairro"
                        >
                          <Input disabled placeholder="Bairro" />
                        </Form.Item>
                      </InvisibleContent>

                      <InvisibleContent visible={bairroTriagem !== (undefined)}>
                        <Form.Item
                          label="Bairro"
                          //name="bairro"
                        >
                          <Input
                            disabled
                            placeholder="Bairro"
                            defaultValue={bairroTriagem}
                            style={{ borderColor: '#ff2800', color: "#464646" }}
                            suffix={
                              <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                                <IconButton
                                  size="small"
                                  color="secondary"
                                  onClick={() => setOpenModalBairro(true)}
                                  style={{ minWidth: 0, height: 10 }}
                                >
                                  <Edit fontSize="small" />
                                </IconButton>

                                <IconButton
                                  size="small"
                                  color="secondary"
                                  onClick={() => onSubmitValidarBairro()}
                                  style={{ minWidth: 0, height: 10 }}
                                >
                                  <CheckCircle style={{color: '#423B7D'}} fontSize="small" />
                                </IconButton>
                              </div>
                            }
                          />
                        </Form.Item>
                      </InvisibleContent>
                    </Grid>


                    {/* DISTRITO */}
                    <Grid item sm={6} xs={12}>

                      <InvisibleContent visible={distritoTriagem === (undefined)}>
                        <Form.Item
                          label="Distrito"
                          name="Distrito"
                        >
                          <Input disabled placeholder="Distrito" />
                        </Form.Item>
                      </InvisibleContent>

                      <InvisibleContent visible={distritoTriagem !== (undefined)}>
                        <Form.Item
                          label="Distrito"
                          //name="Distrito"
                        >
                          <Input
                            disabled
                            placeholder="Distrito"
                            defaultValue={distritoTriagem}
                            style={{ borderColor: '#ff2800', color: "#464646" }}
                            suffix={
                              <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                                <IconButton
                                  size="small"
                                  color="secondary"
                                  onClick={() => setOpenModalDistrito(true)}
                                  style={{ minWidth: 0, height: 10 }}
                                >
                                  <Edit fontSize="small" />
                                </IconButton>

                                <IconButton
                                  size="small"
                                  color="secondary"
                                  onClick={() => onSubmitValidarDistrito()}
                                  style={{ minWidth: 0, height: 10 }}
                                >
                                  <CheckCircle style={{color: '#423B7D'}} fontSize="small" />
                                </IconButton>
                              </div>
                            }
                          />
                        </Form.Item>
                      </InvisibleContent>
                    </Grid>

                    {/* COMPLEMENTO */}
                    <Grid item sm={12} xs={12}>

                      <InvisibleContent visible={complementoTriagem === (undefined)}>
                        <Form.Item
                          label="Complemento"
                          name="complemento"
                        >
                          <Input disabled placeholder="Complemento" />
                        </Form.Item>
                      </InvisibleContent>

                      <InvisibleContent visible={complementoTriagem !== (undefined)}>
                        <Form.Item
                          label="Complemento"
                          //name="complemento"
                        >
                          <Input
                            disabled
                            placeholder="Complemento"
                            defaultValue={complementoTriagem}
                            style={{ borderColor: '#ff2800', color: "#464646" }}
                            suffix={
                              <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                                <IconButton
                                  size="small"
                                  color="secondary"
                                  onClick={() => setOpenModalComplemento(true)}
                                  style={{ minWidth: 0, height: 10 }}
                                >
                                  <Edit fontSize="small" />
                                </IconButton>

                                <IconButton
                                  size="small"
                                  color="secondary"
                                  onClick={() => onSubmitValidarComplemento()}
                                  style={{ minWidth: 0, height: 10 }}
                                >
                                  <CheckCircle style={{color: '#423B7D'}} fontSize="small" />
                                </IconButton>
                              </div>
                            }
                          />
                        </Form.Item>
                      </InvisibleContent>
                    </Grid>

                  </Grid>
                </Spin>

                {/* NOME FANTASIA */}
                <Grid container>
                  <Grid item sm={12} xs={12}>
                    <Form.Item
                      name="nome_fantasia"
                      label="Nome de tratamento"
                    >
                      <Input onInput={handleInput} disabled placeholder="Nome de tratamentoo"/>
                    </Form.Item>
                  </Grid>
                </Grid>

                {/* BOTÃO SALVAR TRIAGEM */}
                <Grid item sm={12} xs={12} style={{ paddingTop: 10 }}>
                  <ButtonComponent
                    color="primary"
                    disabled={infoAlteracaoTriagem !== undefined}
                    loading={loadingConfirmarTriagem}
                    onClick={() => onSubmitConfirmarTriagem()}
                    fullWidth
                    type="submit"
                  >
                    <SaveOutlined style={{ marginRight: 10 }} /> Salvar
                  </ButtonComponent>
                </Grid>
              </Grid>
            </Grid> { /* antes do form */}
          </Form>
        </Spin>
      </Grid>
    </Grid>
  );
};

export default PerfilComponent;
