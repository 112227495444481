import api from '@http/api';
import moment from 'moment';
import { IPerfil } from '@interfaces/UserComplete';

export interface IResponseRequestPessoaByCPF {
  pessoa: {
    id: string;
    documento: string;
    nome: string;
    perfis: IPerfil[];
    tipos_perfis: string[];
  };
}

export interface IResponsePessoaByCPF {
  response?: {
    pessoa: {
      id: string;
      nome: string;
      documento: string;
      perfis: IPerfil[];
      tipos_perfis: string[];
    };
  };
  error: boolean;
}

export interface IResponseCreatePessoa {
  id: number;
}

export interface IPessoa {
  id: number;
  id_pessoa_responsavel: number;
  id_pessoa_indicador: number;
  nome_pessoa_responsavel: string;
  nome_pessoa_indicador: string;
}

export interface IRequestUpdateResponsavel {
  pessoa: IPessoa;
}

export interface IMotivoDevolucao {
  id: number;
  codigoCorreio: string;
  descricao: string ;
  dataCadastro: Date;
  objetivoDevolucao: number;
}

export interface IRequestCreateCorrespDevolvida {
  observacao: string;
  data_envio: Date;
  data_retorno: Date;
  mala_mensal: boolean;
  id_perfil: number;
  id_motivo_devolucao: number;
}

export interface IResponseCreateCorrespDevolvida {
  id: number;
}


export interface IFormaContribuicao {
  id: string;
  descricao: string;
  data_cadastro: string;
  status_ativo: boolean;
}

export interface IOperacao {
  id: string;
  descricao: string;
  codigo_operacao: string;
}


export interface ICidadeById {
  descricao: string;
}

export interface IResponseGetCidadesByUf {
  cidade_id: string;
  cidade_nome: string;
}

export interface ICidade {
  id: string | number;
  label: string;
}

class PageEditCadastroPessoaServices {

  public async getPessoaByID(id: number): Promise<IResponsePessoaByCPF> {
    return await api
      .get(`api/Pessoa/GetPessoaById/${id}`)
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }

  public async getPessoaByDocumento(
    documento: string,
  ): Promise<IResponsePessoaByCPF> {
    return await api
      .post<IResponseRequestPessoaByCPF>(`api/Pessoa/FindPessoaByCpf`, {
        documento,
      })
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }

  public async UpdateResponsavel(
    pessoa: IRequestUpdateResponsavel,
  ) {
    console.log(pessoa);
    return await api
      .put('/api/Pessoa/UpdatePessoaByPessoaId', pessoa)
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
          response: [],
        };
      });
  }

  public async ValidarTriagemOperacao(id_perfil: any, informacaoInstantanea: boolean, nova_informacao: any, coluna: string) {
    return await api
    .post(`/api/Triagem/AtualizarNovaInformacao?id_perfil=${id_perfil}&informacaoInstantanea=${informacaoInstantanea}&nova_informacao=${nova_informacao}&coluna=${coluna}`,)
    .then(response => {
      return {
        error: false,
        //response: response.data,
      };
    })
    .catch(error => {
      return {
        error: true,
        //response: [],
      };
    });
}

public async loadFormaContribuicao() {
  return await api
    .get<IFormaContribuicao[]>('/api/FormaContribuicao/GetFormasContribuicao')
    .then(response => {
      return {
        error: false,
        response: response.data,
      };
    })
    .catch(error => {
      return {
        error: true,
        response: [],
      };
    });
}

public async loadTiposOperacao(id: any) {
  return await api
    .get<IOperacao[]>(
      `/api/Financeiro/GetFinanceiroOperacoes?bancoId=${id}`,
    )
    .then(response => {
      return {
        error: false,
        response: response.data,
      };
    })
    .catch(error => {
      return {
        error: true,
        response: [],
      };
    });
}

public async loadCidadeById(id: string) {
  return await api
    .get(
      `/api/ContatoOffline/GetCidadeById/${id}`,
    )
    .then(response => {
      return {
        error: false,
        response: response.data,
      };
    })
    .catch(error => {
      return {
        error: true,
        response: [],
      };
    });
}

public async getCidadesByUF(estado: any) {
  return await api
    .post<IResponseGetCidadesByUf[]>('/api/ContatoOffline/FindCidades', {
      uf: estado,
    })
    .then(response => {
      let reescrito: ICidade[] = [];

      response.data.map(cidade => {
        const obj: ICidade = {
          id: cidade.cidade_id,
          label: cidade.cidade_nome,
        };

        return reescrito.push(obj);
      });

      return {
        error: false,
        response: reescrito,
      };
    })
    .catch(() => {
      return {
        error: true,
        response: [],
      };
    });
}

public async DescartarTriagemOperacao(id_perfil: any, coluna: string) {
  return await api
  .delete(`/api/Triagem/DescartarNovaInformacao?id_perfil=${id_perfil}&coluna=${coluna}`,)
  .then(response => {
    return {
      error: false,
      //response: response.data,
    };
  })
  .catch(error => {
    return {
      error: true,
      //response: [],
    };
  });
}


}

export default PageEditCadastroPessoaServices;
