import api from '@http/api';
import moment from 'moment';
import { IPerfil } from '@interfaces/UserComplete';



export interface ICidadeById {
  descricao: string;
}

export interface IResponseGetCidadesByUf {
  cidade_id: string;
  cidade_nome: string;
}

export interface ICidade {
  id: string | number;
  label: string;
}

class PageEditTriagemPerfilServices {


  public async ValidarTriagemCidade(id_perfil: any, informacaoInstantanea: boolean, nova_informacao: any, coluna: string) {
    return await api
    .post(`/api/Triagem/AtualizarNovaInformacao?id_perfil=${id_perfil}&informacaoInstantanea=${informacaoInstantanea}&nova_informacao=${nova_informacao}&coluna=${coluna}`,)
    .then(response => {
      return {
        error: false,
        //response: response.data,
      };
    })
    .catch(error => {
      return {
        error: true,
        //response: [],
      };
    });
  }

  public async DescartarTriagemCidade(id_perfil: any, coluna: string) {
    return await api
    .delete(`/api/Triagem/DescartarNovaInformacao?id_perfil=${id_perfil}&coluna=${coluna}`,)
    .then(response => {
      return {
        error: false,
        //response: response.data,
      };
    })
    .catch(error => {
      return {
        error: true,
        //response: [],
      };
    });
  }



  public async loadCidadeById(id: string) {
    return await api
      .get(
        `/api/ContatoOffline/GetCidadeById/${id}`,
      )
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
          response: [],
        };
      });
  }

  public async getCidadesByUF(estado: any) {
    return await api
      .post<IResponseGetCidadesByUf[]>('/api/ContatoOffline/FindCidades', {
        uf: estado,
      })
      .then(response => {
        let reescrito: ICidade[] = [];

        response.data.map(cidade => {
          const obj: ICidade = {
            id: cidade.cidade_id,
            label: cidade.cidade_nome,
          };

          return reescrito.push(obj);
        });

        return {
          error: false,
          response: reescrito,
        };
      })
      .catch(() => {
        return {
          error: true,
          response: [],
        };
      });
  }



}

export default PageEditTriagemPerfilServices;
