import React, { useEffect, useRef, useCallback, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useParams, useLocation } from 'react-router-dom';

import Modal from '@src/components/Shared/Modal/ModalPure';
import api from '@http/api';
import { IPerfil, IPessoaTriagem } from '@interfaces/UserComplete';
import { MaskCPFeCNPJ } from '@utils/Masks';

import { Grid, CircularProgress, Checkbox } from '@material-ui/core';
import { Form, Input, Spin, Radio } from 'antd';

import ButtonComponent from '@components/Shared/Buttons/Button';
import NewComponentPerfil from '@src/Pages/Private/shared/Triagem/update';
import NewComponentPerfil2 from '@src/Pages/Private/shared/Triagem/updateTriado';


import { LabelHeaderInput, DivLoading, CustomInput } from './styles';
import { TitlePage } from '@styles/styles.global';

import { validaCPFandCNPJ } from '@src/utils/Validators';

import { useAuth } from '@hooks/AuthenticateContext';
import { useLoading } from '@hooks/LoadingContext';

import InvisibleContent from '@components/Shared/InvisibleContent';
import { useFooterTelefonia as useFooterTelefoniaSemWebPhone } from '@hooks/FooterTelefoniaSemWebPhone';

import Service from '@src/Pages/Private/shared/EditCadastro/services';

import { isEmptyObject } from '@src/utils/Validators';
import serviceSocket from '@src/utils/SendSocket';
import { parseFromString } from 'date-fns-timezone';

import ComponentPerfilServices, {
  IInfosAlteradasTriagem,
} from '.././updateTriado/services';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}


interface Iprops {
  functionReload?: Function;
}

const EditCadastro: React.FC<Iprops> = ({

  functionReload,

}) => {

  const auth = useAuth();

  const { nameFunction2 } = useFooterTelefoniaSemWebPhone();
  const query = useQuery();

  const [formRef] = Form.useForm();
  const { removeLoading, setValues } = useLoading();
  const { enqueueSnackbar } = useSnackbar();
  const { idCadastro }: { idCadastro: string } = useParams();
  const [user, setUser] = useState<IPerfil>({} as IPerfil);
  const [pessoaTriagem, setPessoaTriagem] = useState<IPessoaTriagem>({} as IPessoaTriagem);

  const [loading, setLoading] = useState(true);
  const [loadingEditPessoa, setLoadingEditPessoa] = useState(false);
  const [tipoDocumento, setTipoDocumento] = useState<'pf' | 'pj'>('pf');

  const [NomePessoa, setNomePessoa] = useState('');

  const [idInteracao, setIdInteracao] = useState<any>();
  const [idPessoa, setIdPessoa] = useState<any>();

  const [idExists, setIdExists] = useState(false);
  const [atrelaUsuario, setAtrelaUsuario] = useState(false);
  const [loadingVerificaTriagem, setLoadingVerificaTriagem] = useState(false);
  const [loadingAtrelarUsuarioTriagem, setLoadingAtrelarUsuarioTriagem] = useState(false);


  const [queryTelefonia, setQueryTelefonia] = useState<{
    id_ligacao_interacao: number;
    id_estrategia: number;
    estrategia_nome: string;
    id_pessoa: number;
    id_perfil: number;
    id_estrategia_ligacao: number;
    nomeEstrategia: string;
    contatos_perfil: string[];
  } | null>();

  async function loadVerificaPerfilTriagem(id: string) {
    setLoadingVerificaTriagem (true)
    await api
      .get(`api/Triagem/VerificaPerfilPossuiPerfilTriagem?id_perfil=${id}`)

      .then(async response => {
       // setTiposPerfis(response.data.pessoa.tipo_perfil_descricao);

       setIdExists(response.data);

        //removeLoading();
        setLoadingVerificaTriagem(false);
      })
      .catch(error => {
        console.log(error);
      });
  }

  async function loadPessoa(id: string) {
    setValues(true);
    await api
      .get(`api/Triagem/PegaInformacoesFixasCadastro?id_perfil=${id}`)

      .then(async response => {
       // setTiposPerfis(response.data.pessoa.tipo_perfil_descricao);

        setPessoaTriagem(response.data);

        const { typeDocument, valueModify } = MaskCPFeCNPJ(
          response.data.documento,
        );

        setNomePessoa(response.data.nome_pessoa)

        const NomePessoa2 = response.data.nome_pessoa;

        setTipoDocumento(typeDocument);
        response.data.documento = valueModify;

        const queryTelefonia = query.get('telefonia');

        if (queryTelefonia !== null) {
          let JSONPARSED = JSON.parse(queryTelefonia) as {
            id_ligacao_interacao: number;

            id_pessoa: number;
          };

          const service = new Service();

          const { error, response } = await service.FindDadosLigacao(
            JSONPARSED.id_ligacao_interacao,
            JSONPARSED.id_pessoa,
          );

          console.log(' teste...', response?.id_pessoa);

          setIdInteracao(response?.id_ligacao_interacao);
          setIdPessoa(response?.id_pessoa);

          if (!error && response) {


            if (!isEmptyObject(response)) {
              // alert('Enviou socket');
              const socket = new serviceSocket();
              await socket.sendEventSocket({
                eventName: `dados-para-telefonia-${auth.user.id}`,
                message: JSON.stringify({
                  id_ligacao_interacao: response.id_ligacao_interacao,
                  id_estrategia: response.id_estrategia,
                  estrategia_nome: response.estrategia_nome,
                  id_pessoa: response.id_pessoa,
                  id_perfil: response.id_perfil,
                  id_estrategia_ligacao: null,
                  nomeEstrategia: response.estrategia_nome,
                  nomePessoa: NomePessoa2,
                  contatos_perfil: response.contatos_perfil,
                }),
              });
            }
            if (auth.user.telefonia !== 'Zenvia')  {
              console.log('ENVIOU');
              nameFunction2({
                example: {
                  id_ligacao_interacao: response.id_ligacao_interacao,
                  id_estrategia: response.id_estrategia,
                  estrategia_nome: response.estrategia_nome,
                  id_pessoa: response.id_pessoa,
                  id_perfil: response.id_perfil,
                  id_estrategia_ligacao: null,
                  nomeEstrategia: response.estrategia_nome,
                  nomePessoa: NomePessoa2,
                  contatos_perfil: response.contatos_perfil,
                },
              });
            }
          }
        } else {
          setQueryTelefonia(null);
        }




        removeLoading();
        setLoading(false);
      })
      .catch(error => {
        console.log(error);

        enqueueSnackbar('Ocorreu um erro222.', {
          title: 'Erro de autenticação',
          variant: 'error',
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        });
      });
    await formRef.validateFields();
  }

  async function atrelarUsuarioTriagem(id: string) {
    setLoadingAtrelarUsuarioTriagem (true)
    await api
      .put(`api/Triagem/AtrelarUsuarioTriagem?id_perfil=${id}`)

      .then(async response => {

        setAtrelaUsuario(response.data);
        //removeLoading();
        setLoadingAtrelarUsuarioTriagem(false);
      })
      .catch(error => {
        console.log(error);
      });
  }


//Bloqueio inspecionar
{/*useEffect(() => {
  if (auth.user.role !== 'ADM AMEX') {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'F12') {
        event.preventDefault();
        alert('F12 está desativado.');
      }
      if (event.ctrlKey && event.shiftKey && event.key === 'I') {
        event.preventDefault();
        alert('Ctrl+Shift+I está desativado.');
      }
      if (event.ctrlKey && event.shiftKey && event.key === 'J') {
        event.preventDefault();
        alert('Ctrl+Shift+J está desativado.');
      }
      if (event.ctrlKey && event.key === 'U') {
        event.preventDefault();
        alert('Ctrl+U está desativado.');
      }
    };

    const handleContextMenu = (event: MouseEvent) => {
      event.preventDefault();
      alert('O botão direito do mouse está desativado.');
    };

    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('contextmenu', handleContextMenu);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  }
}, [auth.user.role]);}*/}

  useEffect(() => {
    loadVerificaPerfilTriagem(idCadastro);
    loadPessoa(idCadastro);
    atrelarUsuarioTriagem(idCadastro);
    //loadPerfil(idCadastro);
    //telefonia();
    window.document.title = 'CRM - Edição de Cadastro Triagem';
  }, []);






  if (loading) {
    return (
      <DivLoading container item md={11} xs={12}>
        <CircularProgress color="secondary" size={20}></CircularProgress>
      </DivLoading>
    );
  }

  if (!loading && !user) {
    return (
      <DivLoading container item md={11} xs={12}>
        <p>Nenhum cadastro encontrado.</p>
      </DivLoading>
    );
  }



  return (
    <Grid container>

      <Grid item sm={12}>

      {/* // HEADER PAGE */}
      <Grid
        container
        alignItems="center"
        alignContent="center"
        justify="flex-start"
      >


        <Grid container item sm={12}>
          <Grid item sm={3} xs={12} style={{ alignContent: 'center' }}>
            <TitlePage style={{ marginRight: 20, paddingTop: 2 }}>
              Cadastro triagem{' '}
            </TitlePage>
          </Grid>

          <Grid container item alignContent="center" sm={6} style={{ paddingTop: 2 }}>
            <Form size="middle" layout="inline" labelAlign="left">
              <Grid item sm={5} xs={12}>
                <Form.Item
                  label="Documento: "
                  name="documento"
                  style={{ paddingLeft: 10, paddingRight: 5, margin: 0 }}
                >
                  <Input placeholder="Documento" defaultValue={pessoaTriagem.documento} disabled />
                </Form.Item>
              </Grid>
              {/* <Grid item sm={8} xs={10}>
              {idCadastro}
            </Grid> */}

              <Grid item sm={6} xs={12}>
                <Form.Item
                  label="Nome: "
                  name="nome_pessoa"
                  style={{ paddingLeft: 10, paddingRight: 10, margin: 0 }}
                >
                  <Input
                    placeholder="Nome do usuário"
                    defaultValue={pessoaTriagem.nome_pessoa}
                    disabled
                  />
                </Form.Item>
              </Grid>
            </Form>
          </Grid>



      </Grid>


      </Grid>
      </Grid>

      {/* // HEADER PAGE */}


        {/* Component Perfil */}
        <Grid item sm={6} style={{ paddingTop: 20 ,paddingLeft: 20, paddingRight: 20 }}>

          <Grid item sm={11}>
            <NewComponentPerfil
             //data={perfil}
             perfilID={parseInt(idCadastro)}
             functionReload={() => loadPessoa(idCadastro)}
             verificaTriagem={idExists}
             idInteracao={idInteracao}
             idPessoa={idPessoa}
            />
          </Grid>
        </Grid>

        <InvisibleContent visible={idExists === true}>

        <Grid item sm={6} style={{ paddingTop: 20 ,paddingLeft: 20, paddingRight: 20 }}>

          <Grid item sm={11}>
            <NewComponentPerfil2
             //data={perfil}
             perfilID={parseInt(idCadastro)}
             functionReload={() => loadPessoa(idCadastro)}
             idInteracao={idInteracao}
             idPessoa={idPessoa}
            />
          </Grid>
        </Grid>
        </InvisibleContent>
        {/* Component Perfil */}


    </Grid>
  );
};

export default EditCadastro;
