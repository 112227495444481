import React, { useEffect, useState } from 'react';
import {
  Grid,
  // ClickAwayListener,
  // Grow,
  // Paper,
  // Popper,
  // MenuItem,
  // MenuList,
  // ButtonGroup,
  Button,
} from '@material-ui/core';
import { format } from 'date-fns';
import { CurrencyFormat } from '@utils/formats';
import { Checkbox, Form, Input, Select as SelectAntd, Spin } from 'antd';
import MaskedInput from 'antd-mask-input';
import { AddBox, ArrowDropDown, SaveOutlined, Edit } from '@material-ui/icons';
import { useSnackbar } from 'notistack';

import { getColors } from '@styles/colorsTheme';

import InvisibleContent from '@components/Shared/InvisibleContent';
import CreatePerfil from '@components/Shared/Perfil/create';
import UpdateTipoPerfil from '@components/Shared/Perfil/updateTipoPerfil';
import Modal from '@src/components/Shared/Modal/ModalPure';
import ButtonComponent from '@components/Shared/Buttons/Button';
import CidadesService from '@components/Shared/BuscaCidades/services';
import { IPerfil, IUser } from '@interfaces/UserComplete';
import HistoricoComponent from '@components/Shared/Historico';

import InputEstadoCidade from '@components/Shared/EstadoCidadeInputs';

import SearchCEPCorreios from '@utils/SearchCEPCorreios';
import useDebounce from '@hooks/useDebounce';
import { isReal, ClearString } from '@utils/Masks';

import ComponentPerfilServices, {
  IOrigemEspecifica,
  IOrigemMacro,
  IMotivo,
  IFormaContribuicao,
  ITipoCorrespondecia,
  IDesativacaoDebitoAutomaticoMotivos,
} from './services';

import ServiceCreatePerfil, { IConvenio, IOperacao } from '../create/services';
import ServiceUptadeTipoPerfil from '../updateTipoPerfil/services';

import moment from 'moment';
import { Pattern } from '@mui/icons-material';

interface Iprops {
  tiposPerfil: string[];
  data: IPerfil[];
  initPerfil?: number;
  pessoaID: number;
  functionReload?: Function;
}

interface ICidade {
  id: string;
  label: string;
}

interface IRecorrencia {
  id: number;
  descricao: string;
}

interface IRecorrenciaPixCredito {
  intervalo_mes: number;
  descricao: string;
}


const NAME_DEBITO = 'DÉBITO AUTOMÁTICO';
const NAME_ENERGIA = 'CONTA DE ENERGIA';
const NAME_PIX = 'PIX';
const NAME_CREDITO= 'CARTÃO DE CRÉDITO';


const PerfilComponent: React.FC<Iprops> = ({
  data,
  tiposPerfil,
  pessoaID,
  functionReload,
  initPerfil,
}) => {
  const [formRef] = Form.useForm();

  const { enqueueSnackbar } = useSnackbar();
  const [perfilSelected, setPerfilSelected] = useState<number>(0);
  const [open, setOpen] = useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [loadingCEP, setLoadingCEP] = useState(false);

  const [origensEspecificas, setOrigensEspecificas] = useState<
    IOrigemEspecifica[]
  >([]);

  const [origensMacro, setOrigensMacro] = useState<
    IOrigemMacro[]
  >([]);

  const [motivosCadastro, setMotivosCadastro] = useState<IMotivo[]>([]);

  const [formasContribuicao, setFormasContribuicao] = useState<
    IFormaContribuicao[]
  >([]);

  const [tiposCorrespondecia, setTiposCorrespondecia] = useState<
    ITipoCorrespondecia[]
  >([]);

  const [uf, setUF] = useState(
    data[selectedIndex].contato_offline.estado_descricao,
  );

  const [cidades, setCidades] = useState<ICidade[]>([]);

  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [openModalAddPerfil, setOpenModalAddPerfil] = useState(false);
  const [openModalUpdatePerfil, setOpenModalUpdatePerfil] = useState(false);
  const [cidade, setCidade] = useState<any>();

  const [checkTipoFormContribuicao, setCheckTipoFormContribuicao] =
    useState('');

    const [checkConvenio, setCheckConvenio] =
    useState('');

  const [convenios, setConvenios] = useState<IConvenio[]>([]);
  const [operacoes, setOperacoes] = useState<IOperacao[]>([]);
  const [operacoesValida, setoperacoesValida] = useState(false);

  const [recorrencias, setRecorrencias] = useState<IRecorrencia[]>([
    { id: 1, descricao: 'Mensal' },
    { id: 2, descricao: 'Bimestral' },
    { id: 3, descricao: 'Trimestral' },
    { id: 6, descricao: 'Semestral' },
    { id: 12, descricao: 'Anual' },
  ]);

  const [recorrenciasPixCredito, setRecorrenciasPixCredito] = useState<IRecorrenciaPixCredito[]>([
    { intervalo_mes: 999, descricao: 'Única' },
    { intervalo_mes: 1, descricao: 'Mensal' },
    { intervalo_mes: 6, descricao: 'Semestral' },
    { intervalo_mes: 12, descricao: 'Anual' },
  ]);


  const [controlModalMotivo, setControlModalMotivo] = useState(false);
  const [controleMotivo, setControleMotivo] = useState<{
    id: number;
    descricao: string;
  }>();
  const [motivos, setMotivos] = useState<IDesativacaoDebitoAutomaticoMotivos[]>(
    [],
  );
  const [valorModifyValorDebito, setValorModifyValorDebito] =
    useState<string>('');
  const [valorModifyValorDebitoEnergia, setValorModifyValorDebitoEnergia] =
    useState<string>('');
    const [valorModifyValorPadrao, setValorModifyValorPadrao] =
    useState<string>('');
  const [stringDebitoStatus, setStringDebitoStatus] = useState<string>('');
  const [stringEnergiaStatus, setStringEnergiaStatus] = useState<string>('');
  const [stringPixCreditoStatus, setStringPixCreditoStatus] = useState<string>('');

  const [triagem, setTriagem] = useState('');

  const [formaContribuicaoIdSelected, setFormaContribuicaoIdSelected] =
    useState<number>(0);

  const [user, setUser] = useState<IUser>({} as IUser);
  const [user2, setUser2] = useState<IUser>({} as IUser);
  const [pessoaEdit, setPessoaEdit] = useState(false);


  function handleInput(e: any) {
    var ss = e.target.selectionStart;
    var se = e.target.selectionEnd;
    e.target.value = e.target.value.toUpperCase();
    e.target.selectionStart = ss;
    e.target.selectionEnd = se;
  };

  const createArrayRange = (
    start: number,
    increment: number,
    length: number,
  ) => {
    let array = [];

    for (let i = start; i <= length; i += increment) {
      array.push(i);
    }

    return array;
  };

  const searchCEPDebounce = async (value: string) => {
    const searchCEPCorreios = new SearchCEPCorreios();
    setLoadingCEP(true);
    const { error, response } = await searchCEPCorreios.SearchCEP(value);
    setLoadingCEP(false);
    if (!error && response) {
      setUF(response.uf);
      let endereco1 = response.logradouro;
      endereco1 = endereco1.toUpperCase();

      let bairro1 = response.bairro;
      bairro1 = bairro1.toUpperCase();
      formRef.setFieldsValue({
        endereco: endereco1,
        bairro: bairro1,
        estado_descricao: response.uf,
        //cidade_id: response.localidade,
      });
      clearCadastro();
    }
  };
  const debounceCEP = useDebounce(searchCEPDebounce, 800);

  const loadConvenios = async (id: number) => {
    const servicePerfilCreate = new ServiceCreatePerfil();
    const servicePerfilTipoUpdate = new ServiceUptadeTipoPerfil();

    const conveniosResponse = await servicePerfilCreate.GetConvenios(id);

    if (!conveniosResponse.error && conveniosResponse.response) {
      setConvenios(conveniosResponse.response);
    }
  };

  const loadOperacoes = async (id: number) => {
    let banco = data[perfilSelected].debito_automatico.id_banco;

    convenios.map(item => {
      if (item.id === id) {
        banco = Number(item.id_banco);
      }
    });

    const servicePerfilCreate = new ServiceCreatePerfil();

    const operacoesResponse = await servicePerfilCreate.GetOperacoes(banco);

    if (!operacoesResponse.error && operacoesResponse.response) {
      setOperacoes(operacoesResponse.response);
      validaOperacao(operacoesResponse.response.length);
    }
  };

  const validaOperacao = (value: number) => {
    if (value === 0) {
      setoperacoesValida(false);
    } else if (value > 0) {
      setoperacoesValida(true);
    }
  };

  const dataValuesForm = (index: number) => {
    setCheckTipoFormContribuicao(data[index].forma_contribuicao_descricao);
    setFormaContribuicaoIdSelected(data[index].forma_contribuicao_id);
    setCheckConvenio (data[index].debito_automatico.nome_cedente);

    setTriagem(data[index].status_triagem);

    if (data[index].status_triagem === null){
      setTriagem('DISPONÍVEL');
    }

    setStringDebitoStatus(
      data[index].debito_automatico?.status_ativo
        ? ' está ativo'
        : ' está inativo',
    );

    setStringEnergiaStatus(
      data[index].debito_conta_energia?.status_ativo
        ? ' está ativo'
        : ' está inativo',
    );

    setStringPixCreditoStatus(
      data[index].perfil_recorrencia_doacao?.status_ativo
        ? ' está ativo'
        : ' está inativo',
    );

    return {
      origem_descricao: data[index].origem_descricao || '',
      nome_fantasia: data[index].nome_fantasia || '',
      forma_contribuicao_id: data[index].forma_contribuicao_id || '',
      tipo_correspondencia_id: data[index].tipo_correspondencia_id || '',
      status_triagem: data[index].status_triagem || '',
      valor_padrao_doacao:
      Intl.NumberFormat('pt-br', {
        style: 'currency',
        currency: 'BRL',
      })
        .format(data[index].valor_padrao_doacao)
        .replace('R$', '')
        .trim() || '',
      celular: data[index].celular.contato_online_descricao || '',
      whatsapp: data[index].whatsapp.contato_online_descricao || '',
      telefone_fixo: data[index].telefone_fixo.contato_online_descricao || '',
      telefone_comercial:
        data[index].telefone_comercial.contato_online_descricao || '',
      email: data[index].email.contato_online_descricao || '',
      endereco: data[index].contato_offline.endereco || '',
      complemento: data[index].contato_offline.complemento || '',
      Distrito: data[index].contato_offline.Distrito || '',
      bairro: data[index].contato_offline.bairro || '',
      cep: data[index].contato_offline.cep || '',
      motivo: data[index].motivo_cadastro_id || '',
      origem_id: data[index].origem_id || '',
      origem_macro_id: data[index].origem_macro_id || '',
      origem_macro_descricao: data[index].origem_macro_descricao || '',
      cidade_id: data[index].contato_offline.cidade_id || '',
      estado_descricao: data[index].contato_offline.estado_descricao || '',
      convenio_id: data[index].debito_automatico?.id_convenio || '',
      banco_id: data[index].debito_automatico?.id_banco,
      agencia_numero: data[index].debito_automatico?.agencia_numero || '',
      agencia_digito: data[index].debito_automatico?.agencia_digito || '',
      conta_numero: data[index].debito_automatico?.conta_numero || '',
      conta_digito: data[index].debito_automatico?.conta_digito || '',
      operacao: data[index].debito_automatico?.operacao || '',
      codigo_remessa: data[index].debito_automatico?.codigo_remessa || '',
      valor_debito:
        Intl.NumberFormat('pt-br', {
          style: 'currency',
          currency: 'BRL',
        })
          .format(data[index].debito_automatico?.valor)
          .replace('R$', '')
          .trim() || '',
      dia_vencimento: data[index].debito_automatico?.dia_vencimento || '',
      debito_automatico_status_ativo:
        data[index].debito_automatico?.status_ativo,

      convenio_energia_id: data[index].debito_conta_energia?.id_convenio,
      conta_contrato_numero:
        data[index].debito_conta_energia?.conta_contrato_numero || '',
      valor_debito_energia:
        Intl.NumberFormat('pt-br', {
          style: 'currency',
          currency: 'BRL',
        })
          .format(data[index].debito_conta_energia?.valor)
          .replace('R$', '')
          .trim() || '',
      recorrencia: data[index].debito_conta_energia?.recorrencia || '',
      debito_conta_energia_status_ativo:
        data[index].debito_conta_energia?.status_ativo,

       intervalo_mes: data[index].perfil_recorrencia_doacao?.intervalo_mes || '',
       pix_credito_status_ativo:
       data[index].perfil_recorrencia_doacao?.status_ativo,
    };
  };



  const finishCreatePerfil = async () => {
    if (functionReload) {
      await functionReload();
    }
    setOpenModalAddPerfil(false);
  };

  const finishUpdateTipoPerfil = async () => {
    if (functionReload) {
      await functionReload();
    }
    setOpenModalUpdatePerfil(false);
  };

  const handleMenuItemClick = async (index: number) => {
    console.log(index);
    setSelectedIndex(index);
    setPerfilSelected(index);

    setUF(data[index].contato_offline.estado_descricao);

    await loadConvenios(data[index].forma_contribuicao_id);

    formRef.setFieldsValue(dataValuesForm(index));
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    const init = async () => {
      const servicePerfilCreate = new ServiceCreatePerfil();
      const servicePerfilTipoUpdate = new ServiceUptadeTipoPerfil();

      const componentPerfilServices = new ComponentPerfilServices();
      const origemEspecificaResponse =
        await componentPerfilServices.loadOrigemEspecifica();

      const origemMacroResponse =
        await componentPerfilServices.loadOrigemMacro();

      console.log('TESTE', initPerfil || 0);

      const indexPerfil = filterPerfilByID();

      const conveniosResponse = await servicePerfilCreate.GetConvenios(
        data[indexPerfil].forma_contribuicao_id,
      );

      const operacoesResponse = await servicePerfilCreate.GetOperacoes(
        data[indexPerfil].debito_automatico.id_banco,
      );

      const motivosResponse = await componentPerfilServices.loadMotivos();


      if (!conveniosResponse.error && conveniosResponse.response) {
        setConvenios(conveniosResponse.response);
      }

      if (!operacoesResponse.error && operacoesResponse.response) {
        setOperacoes(operacoesResponse.response);
      }

      if (!motivosResponse.error) {
        setMotivosCadastro(motivosResponse.response);
      }

      if (!origemEspecificaResponse.error) {
        setOrigensEspecificas(origemEspecificaResponse.response);
      }

      if (!origemMacroResponse.error) {
        setOrigensMacro(origemMacroResponse.response);
      }

      const formaContribuicaoResponse =
        await componentPerfilServices.loadFormaContribuicao();

      if (!formaContribuicaoResponse.error) {
        setFormasContribuicao(formaContribuicaoResponse.response);
        const id: any = data[0].forma_contribuicao_id;

        const forma = formaContribuicaoResponse.response.filter(
          x => x.id === id,
        );

        if (forma.length > 0) {
          setCheckTipoFormContribuicao(forma[0].descricao);
        }
      }

      const tiposCorrespondeicaResponse =
        await componentPerfilServices.loadTiposCorrespondecia();

      if (!tiposCorrespondeicaResponse.error) {
        setTiposCorrespondecia(tiposCorrespondeicaResponse.response);
      }
    };

    init();
    if (initPerfil && initPerfil > 0) {
      filterPerfilByID();
    } else {
      formRef.setFieldsValue(dataValuesForm(0));
    }
  }, []);

  const validaAlteracao = () => {
    if (user != user2){
      setPessoaEdit (true);
    }else if (user === user2){
    setPessoaEdit (false);
    }
  }

  async function onSubmit(values: any, motivo: boolean) {
    const componentPerfilServices = new ComponentPerfilServices();

    setLoadingUpdate(true);

    function limparValor(valor: string): string {
      return valor.replace(/[^\d]+/g, '');
    }

    const valorNumerico = parseFloat(limparValor(valorModifyValorPadrao)) / 100;
    const cepLimpo = limparValor(values.cep);

    const updatePerfilRequest: any = {
      contato_offline: {
        cep: cepLimpo,
        endereco: values.endereco.toUpperCase(),
        complemento: values.complemento,
        Distrito: values.Distrito,
        bairro: values.bairro.toUpperCase(),
        cidade_id: values.cidade_id,
      },
      id: data[perfilSelected].perfil_id.toString(),
      status_ativo: data[perfilSelected].status_ativo,
      forma_contribuicao_id: values.forma_contribuicao_id,
      motivo_cadastro_id: values.motivo,
      nome_fantasia: values.nome_fantasia,
      origem_especifica_id: values.origem_id,
      origem_macro_id: values.origem_id,
      tipo_correspondencia_id: values.tipo_correspondencia_id,
      valor_padrao_doacao:  ClearString(valorModifyValorPadrao) / 100 ||  data[perfilSelected].valor_padrao_doacao,
      celular: {
        contato_online_descricao: values.celular,
      },
      whatsapp: {
        contato_online_descricao: values.whatsapp,
      },
      email: {
        contato_online_descricao: values.email,
      },
      telefone_comercial: {
        contato_online_descricao: values.telefone_comercial,
      },
      telefone_fixo: {
        contato_online_descricao: values.telefone_fixo,
      },
    };

    if (
      checkTipoFormContribuicao === NAME_DEBITO ||
      data[perfilSelected].debito_automatico?.id
    ) {
      updatePerfilRequest.debito_automatico = {
        id: data[perfilSelected].debito_automatico?.id,
        id_perfil: data[perfilSelected].perfil_id,
        status_ativo:
          values.debito_automatico_status_ativo === undefined
            ? data[perfilSelected].debito_automatico?.status_ativo
            : values.debito_automatico_status_ativo
            ? 1
            : 0,

        recorrente: 1,

        id_convenio:
          values.convenio_id ||
          data[perfilSelected].debito_automatico?.id_convenio,
          id_banco:
          values.banco_id ||
          data[perfilSelected].debito_automatico?.id_banco,
        agencia_numero:
          values.agencia_numero ||
          data[perfilSelected].debito_automatico?.agencia_numero,
        agencia_digito:
          values.agencia_digito ||
          data[perfilSelected].debito_automatico?.agencia_digito,
        conta_numero:
          values.conta_numero ||
          data[perfilSelected].debito_automatico?.conta_numero,
        conta_digito:
          values.conta_digito ||
          data[perfilSelected].debito_automatico?.conta_digito,
        operacao:
          values.operacao || data[perfilSelected].debito_automatico?.operacao,
        valor:
          ClearString(valorModifyValorDebito) / 100 ||
          data[perfilSelected].debito_automatico?.valor,
        dia_vencimento:
          values.dia_vencimento ||
          data[perfilSelected].debito_automatico?.dia_vencimento,
      };

      if (motivo) {
        updatePerfilRequest.debito_automatico_desativacao_motivo =
          controleMotivo;
      }
    } else {
      updatePerfilRequest.debito_automatico = {
        id: 0,
        id_convenio: 0,
        id_banco: 0,
        agencia_numero: '',
        agencia_digito: '',
        conta_numero: '',
        conta_digito: '',
        operacao: '',
        valor: 0,
        dia_vencimento: '',
        recorrente: 0,
        status_ativo: 0,
        id_perfil: 0,
      };
    }

    console.log(checkTipoFormContribuicao);

    console.log(data[perfilSelected].debito_conta_energia?.id);

    if (
      checkTipoFormContribuicao === NAME_ENERGIA ||
      data[perfilSelected].debito_conta_energia?.id
    ) {
      updatePerfilRequest.debito_conta_energia = {
        id: data[perfilSelected].debito_conta_energia?.id,
        id_perfil: data[perfilSelected].perfil_id,
        status_ativo:
          values.debito_conta_energia_status_ativo === undefined
            ? data[perfilSelected].debito_conta_energia?.status_ativo
            : values.debito_conta_energia_status_ativo
            ? 1
            : 0,

        recorrente: 1,

        id_convenio: 0,
        // values.convenio_energia_id ||
        // data[perfilSelected].debito_conta_energia?.id_convenio,
        conta_contrato_numero:
          values.conta_contrato_numero ||
          data[perfilSelected].debito_conta_energia?.conta_contrato_numero,
        recorrencia:
          values.recorrencia ||
          data[perfilSelected].debito_conta_energia?.recorrencia,
        valor:
          ClearString(valorModifyValorDebitoEnergia) / 100 ||
          data[perfilSelected].debito_conta_energia?.valor,
      };
    } else {
      console.log('entrou aqui');

      updatePerfilRequest.debito_conta_energia = {
        id: 0,
        id_convenio: 0,
        conta_contrato_numero: '',
        recorrencia: 0,
        valor: 0,
        status_ativo: true,
        id_perfil: 0,
      };
    }


    if (
      checkTipoFormContribuicao === NAME_PIX || NAME_CREDITO ||
      data[perfilSelected].perfil_recorrencia_doacao?.id
    ) {
      updatePerfilRequest.perfil_recorrencia_doacao = {
        id: data[perfilSelected].perfil_recorrencia_doacao?.id,
        //id_perfil: data[perfilSelected].perfil_id,
        intervalo_mes:
        values.intervalo_mes ||
        data[perfilSelected].perfil_recorrencia_doacao?.intervalo_mes,
        status_ativo:
          values.pix_credito_status_ativo === undefined
            ? data[perfilSelected].perfil_recorrencia_doacao?.status_ativo
            : values.pix_credito_status_ativo
            ? true
            : false,

        //recorrente: 1,

        //id_convenio: 0,
        // values.convenio_energia_id ||
        // data[perfilSelected].debito_conta_energia?.id_convenio,
        //conta_contrato_numero:
          //values.conta_contrato_numero ||
          //data[perfilSelected].debito_conta_energia?.conta_contrato_numero,
       // recorrencia:
         // values.recorrencia ||
          //data[perfilSelected].debito_conta_energia?.recorrencia,
       // valor:
         // ClearString(valorModifyValorDebitoEnergia) / 100 ||
         // data[perfilSelected].debito_conta_energia?.valor,
      };
    } else {
      console.log('entrou aqui');
    }

    console.log(updatePerfilRequest.debito_automatico);

    const updatePerfilResponse = await componentPerfilServices.UpdatePerfil(
      updatePerfilRequest,
    );

    if (!updatePerfilResponse.error) {
      enqueueSnackbar(`Perfil salvo com sucesso!`, {
        title: 'Perfil salvo com sucesso!',
        variant: 'success',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      setLoadingUpdate(false);

      if (functionReload) {
        await functionReload();
      }
    } else {
      enqueueSnackbar(`Ocorreu um erro`, {
        title: 'Ocorreu um erro ',
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      setLoadingUpdate(false);
    }
  }

  useEffect(() => {
    const getCidades = async () => {
      const cidadesService = new CidadesService();
      const getCidadesByUFResponse = await cidadesService.getCidadesByUF(uf);
      if (!getCidadesByUFResponse.error) {
        setCidades(getCidadesByUFResponse.response);
      }
    };

    getCidades();
  }, [uf]);

  // useEffect(() => {
  //   setSelectedIndex( 0);
  //   setPerfilSelected( 0);

  //   setUF(data[0].contato_offline.estado_descricao);
  //   formRef.setFieldsValue(dataValuesForm( 0));
  // }, [initPerfil]);

  const filterCheckTipoFormContribuicao = (id: any) => {
    const forma = formasContribuicao.filter(x => x.id === id);

    if (forma.length > 0) setCheckTipoFormContribuicao(forma[0].descricao);
  };

  const filterCheckConvenio = (id: any) => {
    const forma = convenios.filter(x => x.id === id);

    if (forma.length > 0) setCheckConvenio(forma[0].descricao);
  };

  const filterPerfilByID = () => {
    if (initPerfil) {
      console.log(data);
      const filter = data.filter(data => data.perfil_id === initPerfil);
      if (filter.length > 0) {
        const indexPerfil = tiposPerfil.findIndex(
          x => x === filter[0].tipo_perfil_descricao,
        );

        setSelectedIndex(indexPerfil);
        setPerfilSelected(indexPerfil);

        setUF(data[indexPerfil].contato_offline.estado_descricao);
        //setCidade(data[indexPerfil].contato_offline.cidade_descricao);
        formRef.setFieldsValue(dataValuesForm(indexPerfil));

        return indexPerfil;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  };

    {/*async function onSubmit(values: any, motivo: boolean) {
    const componentPerfilServices = new ComponentPerfilServices();

    setLoadingUpdate(true);

    function limparValor(valor: string): string {
      return valor.replace(/[^\d]+/g, '');
    }

    const valorNumerico = parseFloat(limparValor(valorModifyValorPadrao)) / 100;
    const cepLimpo = limparValor(values.cep);

    const updatePerfilRequest: any = {
      perfil_contato_offline: {
        cep: cepLimpo || null,
        endereco: values.endereco.toUpperCase() || null,
        complemento: values.complemento || null,
        Distrito: values.Distrito || null,
        bairro: values.bairro.toUpperCase() || null,
        cidade_id: values.cidade_id || null,
        estado_id: values.estado_id || null,
      },

      perfil: {
      id: data[perfilSelected].perfil_id.toString(),
      status_ativo: data[perfilSelected].status_ativo,
      forma_contribuicao_id: values.forma_contribuicao_id || null,
      motivo_cadastro_id: values.motivo || null,
      nome_fantasia: values.nome_fantasia || null,
      origem_especifica_id: values.origem_id || null,
      tipo_correspondencia_id: values.tipo_correspondencia_id || null,
      valor_padrao_doacao:  ClearString(valorModifyValorPadrao) / 100 ||  data[perfilSelected].valor_padrao_doacao || null,
      },

      perfil_contato_online: {
        email:values.email || null,
        celular: values.celular || null,
        comercial: values.telefone_comercial || null,
        residencial: values.telefone_fixo || null,
        whatsapp: values.whatsapp || null,
      },

    };

    if (
      checkTipoFormContribuicao === NAME_DEBITO ||
      data[perfilSelected].debito_automatico?.id
    ) {
      updatePerfilRequest.perfil_debito_automatico = {
        id: data[perfilSelected].debito_automatico?.id || null,
        //id_perfil: data[perfilSelected].perfil_id,
        status_ativo:
          values.debito_automatico_status_ativo === undefined
            ? data[perfilSelected].debito_automatico?.status_ativo
            : values.debito_automatico_status_ativo
            ? 1
            : 0 || null,

        recorrente: 1 || null,

        id_convenio:
          values.convenio_id ||
          data[perfilSelected].debito_automatico?.id_convenio || null,
          //id_banco:
          //values.banco_id ||
          //data[perfilSelected].debito_automatico?.id_banco,
        agencia_numero:
          values.agencia_numero ||
          data[perfilSelected].debito_automatico?.agencia_numero || null,
        agencia_digito:
          values.agencia_digito ||
          data[perfilSelected].debito_automatico?.agencia_digito || null,
        conta_numero:
          values.conta_numero ||
          data[perfilSelected].debito_automatico?.conta_numero || null,
        conta_digito:
          values.conta_digito ||
          data[perfilSelected].debito_automatico?.conta_digito || null,
        operacao:
          values.operacao || data[perfilSelected].debito_automatico?.operacao || null,
        valor:
          ClearString(valorModifyValorDebito) / 100 ||
          data[perfilSelected].debito_automatico?.valor || null,
        dia_vencimento:
          values.dia_vencimento ||
          data[perfilSelected].debito_automatico?.dia_vencimento || null,
      };

      if (motivo) {
        updatePerfilRequest.debito_automatico_desativacao_motivo =
          controleMotivo;
      }
    } else {
      updatePerfilRequest.perfil_debito_automatico = {
        id: null,
        id_convenio: null,
       // id_banco: null,
        agencia_numero: null,
        agencia_digito: null,
        conta_numero: null,
        conta_digito: null,
        operacao: null,
        valor: null,
        dia_vencimento: null,
        recorrente: null,
        status_ativo: null,
        //id_perfil: 0,
      };
    }

    console.log(checkTipoFormContribuicao);

    console.log(data[perfilSelected].debito_conta_energia?.id);

    if (
      checkTipoFormContribuicao === NAME_ENERGIA ||
      data[perfilSelected].debito_conta_energia?.id
    ) {
      updatePerfilRequest.perfil_conta_energia = {
        id: data[perfilSelected].debito_conta_energia?.id,
        id_perfil: data[perfilSelected].perfil_id,
        status_ativo:
          values.debito_conta_energia_status_ativo === undefined
            ? data[perfilSelected].debito_conta_energia?.status_ativo
            : values.debito_conta_energia_status_ativo
            ? 1
            : 0,

        recorrente: 1,

        id_convenio: 0,
        // values.convenio_energia_id ||
        // data[perfilSelected].debito_conta_energia?.id_convenio,
        conta_contrato_numero:
          values.conta_contrato_numero ||
          data[perfilSelected].debito_conta_energia?.conta_contrato_numero,
        recorrencia:
          values.recorrencia ||
          data[perfilSelected].debito_conta_energia?.recorrencia,
        valor:
          ClearString(valorModifyValorDebitoEnergia) / 100 ||
          data[perfilSelected].debito_conta_energia?.valor,
      };
    } else {
      console.log('entrou aqui');

      updatePerfilRequest.perfil_conta_energia = {
        id: null,
        id_convenio: null,
        conta_contrato_numero: null,
        recorrencia: null,
        valor: null,
        status_ativo: null,
        //id_perfil: 0,
      };
    }


    if (
      checkTipoFormContribuicao === NAME_PIX || NAME_CREDITO ||
      data[perfilSelected].perfil_recorrencia_doacao?.id
    ) {
      updatePerfilRequest.perfil_recorrencia = {
        id: data[perfilSelected].perfil_recorrencia_doacao?.id || null,
        //id_perfil: data[perfilSelected].perfil_id,
        intervalo_mes:
        values.intervalo_mes ||
        data[perfilSelected].perfil_recorrencia_doacao?.intervalo_mes || null,
        status_ativo:
          values.pix_credito_status_ativo === undefined
            ? data[perfilSelected].perfil_recorrencia_doacao?.status_ativo
            : values.pix_credito_status_ativo
            ? true
            : false || null,

        //recorrente: 1,

        //id_convenio: 0,
        // values.convenio_energia_id ||
        // data[perfilSelected].debito_conta_energia?.id_convenio,
        //conta_contrato_numero:
          //values.conta_contrato_numero ||
          //data[perfilSelected].debito_conta_energia?.conta_contrato_numero,
       // recorrencia:
         // values.recorrencia ||
          //data[perfilSelected].debito_conta_energia?.recorrencia,
       // valor:
         // ClearString(valorModifyValorDebitoEnergia) / 100 ||
         // data[perfilSelected].debito_conta_energia?.valor,
      };
    } else {
      console.log('entrou aqui');

      updatePerfilRequest.perfil_recorrencia_doacao = {
        id: null,
        intervalo_mes: null,
        dia_recorrencia: null,
        status_ativo: null,
        //id_perfil: 0,
      };
    }

    console.log(updatePerfilRequest.debito_automatico);

    const updatePerfilResponse = await componentPerfilServices.UpdatePerfil(
      updatePerfilRequest,
    );

    if (!updatePerfilResponse.error) {
      enqueueSnackbar(`Perfil salvo com sucesso!`, {
        title: 'Perfil salvo com sucesso!',
        variant: 'success',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      setLoadingUpdate(false);

      if (functionReload) {
        await functionReload();
      }
    } else {
      enqueueSnackbar(`Ocorreu um erro`, {
        title: 'Ocorreu um erro ',
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      setLoadingUpdate(false);
    }
  }*/}

  const getStringFormContrib = (id: any) => {
    const forma = formasContribuicao.filter(x => x.id === id);

    if (forma.length > 0) return forma[0].descricao;
  };

  const loadMotivosDesativacao = async () => {
    const service = new ComponentPerfilServices();

    const { error, response } = await service.GetDebitoDesativacaoMotivos();

    if (!error && response) {
      setMotivos(response);
      setControleMotivo(response[0]);
    }
  };

  const onSubmitCheckMotivo = async (values: any) => {
    if (
      (getStringFormContrib(data[perfilSelected].forma_contribuicao_id) ===
        NAME_DEBITO &&
        getStringFormContrib(values.forma_contribuicao_id) !== NAME_DEBITO) ||
      (data[perfilSelected].debito_automatico?.status_ativo === 1 &&
        values?.debito_automatico_status_ativo === Boolean(0))
    ) {
      await loadMotivosDesativacao();

      setControlModalMotivo(true);
   // } //else if (
      //(getStringFormContrib(data[perfilSelected].forma_contribuicao_id) ===
        //NAME_ENERGIA &&
       // getStringFormContrib(values.forma_contribuicao_id) !== NAME_ENERGIA) ||
     // (data[perfilSelected].debito_conta_energia?.status_ativo === true &&
       // values?.debito_conta_energia === Boolean(0))
   // ) {
      //await loadMotivosDesativacao();

      //setControlModalMotivo(true);
    } else {
      onSubmit(values, false);
    }

  };


  //console.log("CHECANDO CONVENIO SELECIONADO", checkConvenio)


  const clearCadastro = () => {
    formRef.setFieldsValue({
      cidade_id: [],
    });
  };


  return (
    <Grid container>
      <Grid container item sm={8}>
        <Spin size="large" spinning={loadingUpdate} tip="Enviando dados...">
          <Grid
            container
            item
            xs={12}
            md={12}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item sm={12}>
              <p style={{ textAlign: 'center', paddingTop: 20 }}>Perfil</p>
            </Grid>

            {/* <Grid>
              <ButtonGroup
                fullWidth
                variant="contained"
                color="secondary"
                ref={anchorRef}
                style={{ height: 40 }}
              >
                <Button onClick={handleToggle}>
                  {tiposPerfil[selectedIndex]}
                </Button>

                <Button
                  color="secondary"
                  onClick={handleToggle}
                  style={{ width: 40 }}
                >
                  <ArrowDropDown />
                </Button>


              </ButtonGroup>
            </Grid> */}

            <Modal
              title=""
              size="grande"
              openModal={openModalAddPerfil}
              controlModal={(value: boolean) => setOpenModalAddPerfil(value)}
            >
              <CreatePerfil
                idUsuario={pessoaID}
                finishCreate={finishCreatePerfil}
                data={data}
              />
            </Modal>

            <Modal
              title=""
              size="pequeno"
              openModal={openModalUpdatePerfil}
              controlModal={(value: boolean) => setOpenModalUpdatePerfil(value)}
            >
              <UpdateTipoPerfil
                perfil_id={data[perfilSelected].perfil_id}
                tipo_perfil_descricao={tiposPerfil[perfilSelected]}
                finishUpdate={finishUpdateTipoPerfil}
              />
            </Modal>

            <Grid
              container
              item
              xs={12}
              md={12}
              justifyContent="center"
              alignItems="center"
              style={{ padding: 10 }}
            >
              <Grid item xs={10} md={10}>
                <SelectAntd
                  value={selectedIndex}
                  style={{ width: '100%' }}
                  onChange={value => {
                    handleMenuItemClick(Number(value));
                  }}
                >
                  {tiposPerfil.map((option, index) => (
                    <SelectAntd.Option key={index} value={index}>
                      {option}
                    </SelectAntd.Option>
                  ))}
                </SelectAntd>
              </Grid>

              <Grid item xs={1} md={1}>
                <ButtonComponent
                  color="default"
                  onClick={() => setOpenModalUpdatePerfil(true)}
                  style={{ minWidth: 0, height: 32 }}
                >
                  <Edit />
                </ButtonComponent>
              </Grid>

              <Grid item xs={1} md={1}>
                <ButtonComponent
                  color="secondary"
                  onClick={() => setOpenModalAddPerfil(true)}
                  style={{ minWidth: 0, height: 32 }}
                >
                  <AddBox />
                </ButtonComponent>
              </Grid>
            </Grid>

            {/* <Popper
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              placement="bottom-start"
              transition
              disablePortal
              style={{ position: "absolute", zIndex: 1000 }}
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "left-start" : "left-start",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList
                        id="split-button-menu"
                        style={{
                          width: 310,
                          maxHeight: 300,
                          overflowY: "scroll",
                          overflowX: "hidden",
                        }}
                      >
                        {tiposPerfil.map((option, index) => (
                          <MenuItem
                            key={index}
                            // ={index === 2}
                            // selected={index === selectedIndex}
                            onClick={(event) =>
                              handleMenuItemClick(event, index)
                            }
                          >
                            {option}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper> */}
          </Grid>

          <Form
            form={formRef}
            size="middle"
            layout="vertical"
            scrollToFirstError={true}
            onFinish={onSubmitCheckMotivo}
            labelAlign="left"
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
            requiredMark={false}
          >
            <Grid
              container item sm={12} xs={12} lg={12}
              style={{
                maxHeight: 350,
                overflowY: 'scroll',
                overflowX: 'hidden',
                paddingRight: 10,
                paddingLeft: 10,
                marginTop: 10,
                marginBottom: 20,
              }}
              justify="center"
              alignItems="center"
            >
              <div
                style={{
                  background: getColors()?.branco,
                  border: `5px solid ${getColors()?.azulClaro} `,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  minHeight: 100,
                  maxHeight: 100,
                  padding: 20,
                  flex: 1,
                  // marginTop: 0,
                  // marginBottom: 0,
                  // marginLeft: 10,
                  // marginRight: 10,
                }}
              >
                {/* CARD PARA PERFIL QUE NÃO SEJA INDICADOR */}
                <InvisibleContent visible={!data[perfilSelected].is_indicador}>
                  <Grid container justify="center" alignItems="center">
                    <Grid item sm={6} xs={12}>
                      <Grid container>
                      <Grid item md={12}>
                            <p style={{ color: '#616161' }}>
                              Triagem:{' '}
                              <strong>
                                {triagem}
                              </strong>
                            </p>
                          </Grid>

                        <Grid item md={12}>
                            <p style={{ color: '#616161' }}>
                              ID do Perfil:{' '}
                              <strong>
                                {data[perfilSelected]?.perfil_id}
                              </strong>
                            </p>
                          </Grid>


                      </Grid>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <Grid container>
                      <Grid item md={12}>
                          <p style={{ color: '#616161' }}>
                            Cadastro em:{' '}
                            <strong>
                              {format(
                                new Date(data[perfilSelected]?.data_cadastro),
                                'dd/MM/yyyy',
                              ) === '01/01/0001'
                                ? '--/--/----'
                                : format(
                                    new Date(
                                      data[perfilSelected]?.data_cadastro,
                                    ),
                                    'dd/MM/yyyy',
                                  )}
                            </strong>
                          </p>
                        </Grid>
                        <Grid item md={12}>
                          <p style={{ color: '#616161' }}>
                            Atualizado em:{' '}
                            <strong>
                              {format(
                                new Date(
                                  data[perfilSelected]?.data_modificacao,
                                ),
                                'dd/MM/yyyy',
                              ) === '01/01/0001'
                                ? '--/--/----'
                                : format(
                                    new Date(
                                      data[perfilSelected]?.data_modificacao,
                                    ),
                                    'dd/MM/yyyy',
                                  )}
                            </strong>
                          </p>
                        </Grid>


                      </Grid>
                    </Grid>
                  </Grid>
                </InvisibleContent>

                {/* CARD PARA PERFIL INDICADOR  */}
                <InvisibleContent visible={data[perfilSelected].is_indicador}>
                  <Grid container justify="center" alignItems="center">
                    <Grid item sm={6} xs={12}>
                      <Grid container>
                        <Grid item md={12}>
                          <p style={{ color: '#616161' }}>
                            Classificação:{' '}
                            <strong>
                              {data[perfilSelected]?.indicador
                                .classificacao_descricao !== null
                                ? data[perfilSelected]?.indicador
                                    .classificacao_descricao
                                : 'Sem Classificação'}
                            </strong>
                          </p>
                        </Grid>

                        <Grid item md={12}>
                          <p style={{ color: '#616161' }}>
                            Numero de indicados:{' '}
                            <strong>
                              {
                                data[perfilSelected]?.indicador
                                  .quantidade_indicados
                              }
                            </strong>
                          </p>
                        </Grid>
                        <Grid item md={12}>
                          <p style={{ color: '#616161' }}>
                            Última indicação:{' '}
                            <strong>
                              {moment
                                .utc(
                                  new Date(
                                    data[
                                      perfilSelected
                                    ]?.indicador.data_ultima_indicacao,
                                  ),
                                )
                                .format('DD/MM/YYYY')}
                            </strong>
                          </p>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <Grid container>
                        <Grid item md={12}>
                          <p style={{ color: '#616161' }}>
                            Total de arrecadação dos indicados:{' '}
                            <strong>
                              {CurrencyFormat(
                                data[perfilSelected]?.indicador
                                  .valor_arrecadado_indicados,
                              )}
                            </strong>
                          </p>
                        </Grid>
                        <Grid item md={12}>
                          <p style={{ color: '#616161' }}>
                            Média de doação dos indicados:{' '}
                            <strong>
                              {CurrencyFormat(
                                data[perfilSelected]?.indicador
                                  .media_doacao_indicados,
                              )}
                            </strong>
                          </p>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </InvisibleContent>
              </div>

              <Grid container justify="center" alignItems="center">
                {/* Tipo de Perfil */}
                {/* <Grid item lg={12} xs={12}>
                  <Form.Item
                    label="Tipo de perfil"
                    hasFeedback
                    name="nome"
                    rules={[{ required: true, message: 'Campo obrigatorio' }]}
                  >
                    <Input disabled placeholder="Tipo de perfil" />
                  </Form.Item>
                </Grid> */}
                {/* Tipo de Perfil */}

                {/* ORIGEM MACRO, ESPECIFICA e MOTIVO */}

                <Grid container>
                  {/* <Grid item lg={6} xs={12}>
                    <Form.Item
                      label="Origem macro"
                      hasFeedback
                      name="origem_descricao"
                      rules={[{ required: true, message: 'Campo obrigatorio' }]}
                    >
                      <Input disabled placeholder="Origem macro" />
                    </Form.Item>
                  </Grid> */}

                  <Grid item lg={12} sm={12} xs={12} style={{ marginTop: 10 }}>
                    <Form.Item
                      name="origem_macro_id"
                      label="Origem macro"
                      hasFeedback
                      rules={[
                        {
                          required: false,
                          message: 'Selecione uma Origem macro',
                        },
                      ]}
                    >
                      <SelectAntd disabled placeholder="Origem macro" onChange = {validaAlteracao}>
                        {origensMacro.map(item => (
                          <SelectAntd.Option key={item.id} value={item.id}>
                            {item.descricao}
                          </SelectAntd.Option>
                        ))}
                      </SelectAntd>
                    </Form.Item>
                  </Grid>

                  <Grid item lg={12} sm={12} xs={12} style={{ marginTop: 10 }}>
                    <Form.Item
                      name="origem_id"
                      label="Origem específica"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: 'Selecione uma Origem específica',
                        },
                      ]}
                    >
                      <SelectAntd disabled placeholder="Origem específica" onChange = {validaAlteracao}>
                        {origensEspecificas.map(item => (
                          <SelectAntd.Option key={item.id} value={item.id}>
                            {item.descricao}
                          </SelectAntd.Option>
                        ))}
                      </SelectAntd>
                    </Form.Item>
                  </Grid>
                </Grid>

                <Grid item sm={12} xs={12}>
                  <Form.Item
                    name="motivo"
                    label="Motivo"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: 'Selecione um motivo',
                      },
                    ]}
                  >
                    <SelectAntd disabled placeholder="Motivo" onChange = {validaAlteracao}>
                      {motivosCadastro.map(item => (
                        <SelectAntd.Option key={item.id} value={item.id}>
                          {item.descricao}
                        </SelectAntd.Option>
                      ))}
                    </SelectAntd>
                  </Form.Item>
                </Grid>
                {/* ORIGEM MACRO, ESPECIFICA e MOTIVO */}

                {/* { formaContribuicao, Valor e tipoCorrespondencia } */}
                <Grid container>
                  <Grid item sm={6} xs={12}>
                    <Form.Item
                      name="forma_contribuicao_id"
                      label="Forma de contrib."
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: 'Selecione uma forma de Contribuição',
                        },
                      ]}
                    >
                      <SelectAntd
                        placeholder="Forma de contrib."
                        onChange={e => {
                          loadConvenios(Number(e));
                          filterCheckTipoFormContribuicao(e);
                          validaAlteracao();
                        }}
                      >
                        {formasContribuicao.map(item => (
                          <SelectAntd.Option key={item.id} value={item.id}>
                            {item.descricao}
                          </SelectAntd.Option>
                        ))}
                      </SelectAntd>
                    </Form.Item>
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <Form.Item
                      name="tipo_correspondencia_id"
                      label="Tipo de corresp."
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: 'Selecione um tipo de correspondencia',
                        },
                      ]}
                    >
                      <SelectAntd placeholder="Tipo de corresp." onChange = {validaAlteracao}>
                        {tiposCorrespondecia.map(item => (
                          <SelectAntd.Option key={item.id} value={item.id}>
                            {item.descricao}
                          </SelectAntd.Option>
                        ))}
                      </SelectAntd>
                    </Form.Item>
                  </Grid>

                  <InvisibleContent
                    visible={checkTipoFormContribuicao === null?
                     checkTipoFormContribuicao : checkTipoFormContribuicao.toLowerCase().includes(NAME_DEBITO.toLowerCase())}
                  >
                    <>
                      <Grid item sm={6} xs={12}>
                        <Form.Item
                          name="convenio_id"
                          label="Convênio"
                          hasFeedback
                          rules={[
                            {
                              required: true,
                              message: 'Selecione um convênio',
                            },
                          ]}
                        >
                          <SelectAntd
                            placeholder="Convênio"
                            onChange={e => {
                              filterCheckConvenio(e)
                              loadOperacoes(Number(e));
                              validaAlteracao();
                            }}
                          >
                            {convenios.map(item => (
                              <SelectAntd.Option key={item.id} value={item.id}>
                                {item.descricao + ' - ' + item.numero_convenio}
                              </SelectAntd.Option>
                            ))}
                          </SelectAntd>
                        </Form.Item>
                      </Grid>

                      <Grid item sm={6} xs={12}>
                        <Form.Item
                          name="dia_vencimento"
                          label="Dia do vencimento"
                          hasFeedback
                          rules={[
                            {
                              required: true,
                              message: 'Selecione um dia',
                            },
                          ]}
                        >
                          <SelectAntd placeholder="Dia do vencimento" onChange = {validaAlteracao}>
                            {createArrayRange(1, 1, 31).map(i => (
                              <SelectAntd.Option key={i} value={i}>
                                {i}
                              </SelectAntd.Option>
                            ))}
                          </SelectAntd>
                        </Form.Item>
                      </Grid>

                      <Grid item sm={9} xs={12}>
                        <Form.Item
                          name="agencia_numero"
                          label="Número da Agência"
                          hasFeedback
                          rules={[
                            {
                              required: true,
                            },
                            { max: 4, message: 'Máximo de  4 caracteres' },
                            {
                              pattern: /^[a-zA-Z0-9]+$/,
                              message: "Somente números ou letras"
                            }
                          ]}
                        >
                          <Input placeholder="Número da Agência" onChange = {validaAlteracao}/>
                        </Form.Item>
                      </Grid>
                      <Grid item sm={3} xs={12}>
                        <Form.Item
                          name="agencia_digito"
                          label="Digito da Agência"
                          hasFeedback
                          rules={[
                            {
                              required: false,
                            },
                            { max: 1, message: 'Máximo de  1 caracter' },
                            {
                             // pattern: /^[a-zA-Z0-9]+$/,
                             // message: "Somente números ou letras"
                            }
                          ]}
                        >
                          <Input placeholder="Digito da Agência" onChange = {validaAlteracao}/>
                        </Form.Item>
                      </Grid>

                      <InvisibleContent
                        visible={checkConvenio === 'BANCO ITAÚ S.A. '}
                      >
                        <Grid item sm={9} xs={12}>
                          <Form.Item
                            name="conta_numero"
                            label="Número da conta"
                            hasFeedback
                            rules={[
                              {
                                required: true,
                                max: 5, message: 'Máximo de 5 caracteres',
                              },
                            ]}
                          >
                            <Input placeholder="Número da conta" onChange = {validaAlteracao}/>
                          </Form.Item>
                        </Grid>
                      </InvisibleContent>

                      <InvisibleContent
                        visible={checkConvenio !== 'BANCO ITAÚ S.A. '}
                      >
                        <Grid item sm={9} xs={12}>
                          <Form.Item
                            name="conta_numero"
                            label="Número da conta"
                            hasFeedback
                            rules={[
                              {
                                required: true,
                                //max: 5, message: 'Máximo de 5 caracteres',
                              },
                            ]}
                          >
                            <Input placeholder="Número da conta" onChange = {validaAlteracao}/>
                          </Form.Item>
                        </Grid>
                      </InvisibleContent>

                      <Grid item sm={3} xs={12}>
                        <Form.Item
                          name="conta_digito"
                          label="Digito da conta"
                          hasFeedback
                          rules={[
                            {
                              required: false,
                            },
                          ]}
                        >
                          <Input placeholder="Digito da conta" onChange = {validaAlteracao}/>
                        </Form.Item>
                      </Grid>


                      <InvisibleContent
                    visible={checkConvenio === 'CAIXA ECONOMICA FEDERAL' || checkConvenio === 'SANTANDER'}

                  >
                      <Grid item sm={6} xs={12}>
                        <Form.Item
                          name="operacao"
                          label="Operação"
                          hasFeedback
                          rules={[
                            {
                              required: false,
                            },
                          ]}
                        >
                          <SelectAntd
                            placeholder="Operação"
                            //disabled={!operacoesValida}
                            onChange = {validaAlteracao}
                          >
                            {operacoes.map(item => (
                              <SelectAntd.Option
                                key={item.codigo_operacao}
                                value={item.codigo_operacao}
                              >
                                {item.codigo_operacao + ' - ' + item.descricao}
                              </SelectAntd.Option>
                            ))}
                          </SelectAntd>
                        </Form.Item>
                      </Grid>
                       </InvisibleContent>
                      <Grid item sm={6} xs={12}>
                        <Form.Item
                          name="valor_debito"
                          label="Valor do débito"
                          hasFeedback
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <Input
                            placeholder="Valor débito"
                            onChange={(e: any) => {
                              formRef.setFieldsValue({
                                valor_debito: isReal(e.target.value),
                              });

                              validaAlteracao();
                              setValorModifyValorDebito(e.target.value);
                            }}
                          />
                        </Form.Item>
                      </Grid>

                      <Grid item sm={6} xs={12}>

                      <Form.Item
                          name="codigo_remessa"
                          label="Código para débito"

                          hasFeedback
                          rules={[
                            {
                             // required: true,
                            },
                          ]}
                        >
                          <Input disabled placeholder="Código para débito"/>

                      </Form.Item>

                      </Grid>

                      <Grid item sm={6} xs={12} style={{paddingTop: 20}}>
                        <Form.Item
                          name="debito_automatico_status_ativo"
                          valuePropName="checked"
                        >
                          <Checkbox
                            onChange={e => {
                              console.log(e.target.checked);
                              setStringDebitoStatus(
                                e.target.checked
                                  ? ' está ativo'
                                  : ' está inativo',
                              );
                              validaAlteracao();
                            }}
                            style={{paddingBottom: 30}}
                          >
                            Débito
                            {stringDebitoStatus}
                          </Checkbox>
                        </Form.Item>
                      </Grid>

                    </>
                  </InvisibleContent>

                  {/*hjkhjkhkhkhkhjkhhhjkhjkhkhkhjkhkhjkhjkhkdgjdgjdgjdjjgjghjghjfjghjgjgchzdkjsfhskdfsdjfsldf*/}
                  <InvisibleContent
                    visible={checkTipoFormContribuicao === null?
                      checkTipoFormContribuicao : checkTipoFormContribuicao
                      .toLowerCase()
                      .includes(NAME_ENERGIA.toLowerCase())}
                  >
                    <>
                      <Grid item sm={6} xs={12}>
                        <Form.Item
                          name="convenio_energia_id"
                          label="Convênio"
                          hasFeedback
                          rules={[
                            {
                              required: false,

                              message: 'Selecione um convênio',
                            },
                          ]}
                        >
                          <Input placeholder="Convênio" disabled onChange = {validaAlteracao}/>
                        </Form.Item>
                      </Grid>

                      <Grid item sm={6} xs={12}>
                        <Form.Item
                          name="conta_contrato_numero"
                          label="Número da conta"
                          hasFeedback
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <Input placeholder="Número da conta" onChange = {validaAlteracao}/>
                        </Form.Item>
                      </Grid>

                      <Grid item sm={6} xs={12}>
                        <Form.Item
                          name="recorrencia"
                          label="Recorrência"
                          hasFeedback
                          rules={[
                            {
                              required: false,
                            },
                          ]}
                        >
                          <SelectAntd placeholder="Recorrência" onChange = {validaAlteracao}>
                            {recorrencias.map(item => (
                              <SelectAntd.Option key={item.id} value={item.id}>
                                {item.id + ' - ' + item.descricao}
                              </SelectAntd.Option>
                            ))}
                          </SelectAntd>
                        </Form.Item>
                      </Grid>

                      <Grid item sm={6} xs={12}>
                        <Form.Item
                          name="valor_debito_energia"
                          label="Valor do débito"
                          hasFeedback
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <Input
                            placeholder="Valor débito"
                            onChange={(e: any) => {
                              formRef.setFieldsValue({
                                valor_debito_energia: isReal(e.target.value),
                              });
                              validaAlteracao();
                              setValorModifyValorDebitoEnergia(e.target.value);
                            }}
                          />
                        </Form.Item>
                      </Grid>

                      <Form.Item
                        name="debito_conta_energia_status_ativo"
                        valuePropName="checked"
                      >
                        <Checkbox
                          onChange={e => {
                            console.log(e.target.checked);
                            setStringEnergiaStatus(
                              e.target.checked
                                ? ' está ativo'
                                : ' está inativo',
                            );
                            validaAlteracao();

                          }}
                          style={{paddingBottom: 40}}
                        >
                          <p style={{fontSize: 12}}>Conta de energia
                          {stringEnergiaStatus}</p>
                        </Checkbox>
                      </Form.Item>
                    </>
                  </InvisibleContent>
                </Grid>

                <InvisibleContent
                  visible={checkTipoFormContribuicao === 'PIX'}
                >
                  <>
                    <Grid item lg={6} xs={12}>
                      <Form.Item
                        name="pix_credito_status_ativo"
                        valuePropName="checked"
                      >
                        <Checkbox
                          onChange={e => {
                            console.log(e.target.checked);
                            setStringPixCreditoStatus(
                              e.target.checked
                              ? ' está ativo'
                              : ' está inativo',
                            );
                            validaAlteracao();

                          }}

                          style={{paddingBottom: 40}}
                        >
                          <p  style={{fontSize: 12}}> Pix recorrência
                          {stringPixCreditoStatus} </p>
                        </Checkbox>
                      </Form.Item>

                    </Grid>
                    <Grid item lg={6} xs={12}>
                      <Form.Item
                        name="intervalo_mes"
                        label="Recorrência"
                        hasFeedback
                        rules={[{ required: false, },]}
                      >
                        <SelectAntd placeholder="Recorrência" onChange = {validaAlteracao}>
                          {recorrenciasPixCredito.map(item => (
                            <SelectAntd.Option key={item.intervalo_mes} value={item.intervalo_mes}>
                              {item.descricao}
                            </SelectAntd.Option>
                          ))}
                        </SelectAntd>
                      </Form.Item>

                    </Grid>
                  </>
                </InvisibleContent>

                <InvisibleContent
                  visible={checkTipoFormContribuicao ==='CARTÃO DE CRÉDITO'}
                >
                  <>
                    <Grid item lg={6} xs={12}>
                      <Form.Item
                        name="pix_credito_status_ativo"
                        valuePropName="checked"
                      >
                        <Checkbox
                          onChange={e => {
                            console.log(e.target.checked);
                            setStringPixCreditoStatus(
                              e.target.checked
                              ? ' está ativo'
                              : ' está inativo',
                            );
                            validaAlteracao();

                          }}
                          style={{paddingBottom: 40}}
                        >
                          <p style={{fontSize: 12}}>Cartão de crédito recorrência
                          {stringPixCreditoStatus}</p>
                        </Checkbox>
                      </Form.Item>

                    </Grid>
                    <Grid item lg={6} xs={12}>
                      <Form.Item
                        name="intervalo_mes"
                        label="Recorrência"
                        hasFeedback
                        rules={[{ required: false, },]}
                      >
                        <SelectAntd placeholder="Recorrência" onChange = {validaAlteracao}>
                          {recorrenciasPixCredito.map(item => (
                            <SelectAntd.Option key={item.intervalo_mes} value={item.intervalo_mes}>
                              {item.intervalo_mes + ' - ' + item.descricao}
                            </SelectAntd.Option>
                          ))}
                        </SelectAntd>
                      </Form.Item>

                    </Grid>
                  </>
                </InvisibleContent>

                <InvisibleContent
                    visible={checkTipoFormContribuicao != 'DÉBITO AUTOMÁTICO'}
                  >
                    <>
                <Grid item lg={12} xs={12}>
                  <Form.Item label="Valor padrão doação" hasFeedback name="valor_padrao_doacao">
                    <Input
                      //disabled
                      onChange={(e: any) => {
                      formRef.setFieldsValue({
                        valor_padrao_doacao: isReal(e.target.value),
                      });
                      validaAlteracao();
                      setValorModifyValorPadrao(e.target.value);
                      }}
                      placeholder="Valor" />
                  </Form.Item>
                </Grid>
                </>
                </InvisibleContent>
                {/* { formaContribuicao, Valor e tipoCorrespondencia } */}

                {/* { celular, whatsapp, tel fixo, ttel comercial, email } */}
                <Grid container>
                  <Grid item lg={6} xs={12}>
                    <Form.Item label="Celular" hasFeedback name="celular"
                    rules={[
                      {
                        //pattern: /\(\d{2,}\) \d{5,}\-\d{4}/,
                        //message: 'Número de celular inválido'
                      }

                    ]}>
                      <MaskedInput
                        mask="(11) 11111-1111"
                        placeholder="Celular"
                        onChange = {validaAlteracao}
                      />
                    </Form.Item>
                  </Grid>

                  <Grid item lg={6} xs={12}>
                    <Form.Item label="WhatsApp" hasFeedback name="whatsapp"
                     rules={[
                      {
                        //pattern: /\(\d{2,}\) \d{5,}\-\d{4}/,
                        //message: 'Número de celular inválido'
                      }

                    ]}
                    >
                      <MaskedInput
                        mask="(11) 11111-1111"
                        placeholder="WhatsApp"
                        onChange = {validaAlteracao}
                      />
                    </Form.Item>
                  </Grid>

                  <Grid item lg={6} xs={12}>
                    <Form.Item
                      label="Telefone fixo"
                      hasFeedback
                      name="telefone_fixo"
                      rules={[
                        {
                          //pattern: /\(\d{2,}\) \d{4,}\-\d{4}/,
                          //message: 'Número de telefone inválido'
                        }

                      ]}
                    >
                      <MaskedInput
                        mask="(11) 1111-1111"
                        placeholder="Telefone fixo"
                        onChange = {validaAlteracao}
                      />
                    </Form.Item>
                  </Grid>

                  <Grid item lg={6} xs={12}>
                    <Form.Item
                      label="Telefone comercial"
                      hasFeedback
                      name="telefone_comercial"
                      rules={[
                        {
                         // pattern: /\(\d{2,}\) \d{4,}\-\d{4}/,
                          //message: 'Número de celular inválido'
                        }

                      ]}
                    >
                      <MaskedInput
                        mask="(11) 1111-1111"
                        placeholder="Telefone comercial"
                        onChange = {validaAlteracao}
                      />
                    </Form.Item>
                  </Grid>
                </Grid>

                <Grid item lg={12} xs={12}>
                  <Form.Item
                    label="E-mail"
                    hasFeedback
                    name="email"
                    rules={[
                      {
                        pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])+\.([a-z])+/,
                        message: 'Somente letras (a - z), números (0 - 9) e pontos (.) são permitidos.'
                      }

                    ]}
                  >
                    <Input
                      placeholder="E-mail"
                      onChange = {validaAlteracao}
                    />
                  </Form.Item>
                </Grid>
                {/* { celular, whatsapp, tel fixo, ttel comercial, email } */}

                <Spin spinning={loadingCEP} tip="Buscando dados do CEP">
                  <Grid container>
                    {/* { cep, uf, cidade } */}

                    <Grid item lg={12} xs={12}>
                      <Form.Item label="CEP" hasFeedback name="cep">
                        <MaskedInput
                          mask="11111-111"
                          placeholder="CEP"
                          onChange={(e: any) => { debounceCEP(e.target.value);
                          validaAlteracao();
                          }}
                        />
                      </Form.Item>
                    </Grid>

                    <InputEstadoCidade ufProps={uf} />

                    {/* { cep, uf, cidade } */}

                    {/* { endereço, numero } */}
                    <Grid item lg={12} xs={12}>
                      <Form.Item
                        label="Endereço"
                        hasFeedback
                        name="endereco"
                        rules={
                          [
                            { required: false },
                            {pattern:  /^[a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ0-9\-\ ]+$/,
                            message: 'Somente letras'}
                          ]
                        }
                      >
                        <Input placeholder="Endereço" onChange = {validaAlteracao}/>
                      </Form.Item>
                    </Grid>
                    {/* { endereço, numero } */}

                    {/* { complemento, bairro } */}
                    <Grid item sm={6} xs={12}>
                      <Form.Item
                        label="Bairro"
                        hasFeedback
                        name="bairro"
                        rules={[{ required: false }]}
                      >
                        <Input placeholder="Bairro" onChange = {validaAlteracao}/>
                      </Form.Item>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <Form.Item
                        label="Distrito"
                        hasFeedback
                        name="Distrito"
                      >
                        <Input onInput={handleInput}  placeholder="Distrito" onChange = {validaAlteracao}/>
                      </Form.Item>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                    <Form.Item
                        label="Complemento"
                        hasFeedback
                        name="complemento"
                        rules={
                          [

                             { max: 45, message: 'Máximo de 45 caracteres' },
                          ]
                        }
                      >
                        <Input onInput={handleInput}  placeholder="Complemento" onChange = {validaAlteracao}/>
                      </Form.Item>
                    </Grid>
                    <Grid container>
                      <Grid item sm={12} xs={12}>
                        <Form.Item
                          name="nome_fantasia"
                          label="Nome de tratamento"
                        >
                          <Input onInput={handleInput} disabled placeholder="Nome de tratamento" onChange = {validaAlteracao}/>
                        </Form.Item>
                      </Grid>
                    </Grid>
                    {/* { complemento, bairro } */}
                  </Grid>
                </Spin>
                <Grid item sm={12} xs={12} style={{ paddingTop: 10 }}>
                  <ButtonComponent
                    color="primary"
                    disabled={loadingUpdate || !pessoaEdit}
                    loading={loadingUpdate}
                    fullWidth
                    type="submit"
                  >
                    <SaveOutlined style={{ marginRight: 10 }} /> Salvar
                  </ButtonComponent>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        </Spin>
      </Grid>

      <Grid container item sm={4}>
        <HistoricoComponent
          idPerfil={data[perfilSelected].perfil_id}
          nomePerfil={data[perfilSelected].tipo_perfil_descricao}
          isIndicador={data[perfilSelected].is_indicador}
          tiposPerfil={tiposPerfil}
          idPessoa={pessoaID}
          triagem={triagem}
        />
      </Grid>

      <Modal
        size="medio"
        title="Confirmação"
        openModal={controlModalMotivo}
        controlModal={() => {}}
      >
        <Grid container item sm={12}>
          <Form
            size="middle"
            layout="vertical"
            scrollToFirstError={true}
            onFinish={() => {}}
            labelAlign="left"
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
            requiredMark={false}
          >
            <Grid item sm={12} xs={12}>
              <Form.Item
                label="Motivo de desativação"
                hasFeedback
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <SelectAntd
                  style={{ width: '100%' }}
                  defaultValue={JSON.stringify(motivos[0])}
                  onChange={value => setControleMotivo(JSON.parse(value))}
                >
                  {motivos.map((item: IDesativacaoDebitoAutomaticoMotivos) => (
                    <SelectAntd.Option value={JSON.stringify(item)}>
                      {item.descricao}
                    </SelectAntd.Option>
                  ))}
                </SelectAntd>
              </Form.Item>
            </Grid>
          </Form>
        </Grid>
        <Grid item sm={12} xs={12} style={{ paddingTop: 10 }}>
          <ButtonComponent
            color="primary"
            disabled={loadingUpdate}
            loading={loadingUpdate}
            fullWidth
            onClick={() => {
              onSubmit(formRef.getFieldsValue(), true);
              setControlModalMotivo(false);
            }}
          >
            <SaveOutlined style={{ marginRight: 10 }} /> Salvar
          </ButtonComponent>
        </Grid>
      </Modal>
    </Grid>
  );
};

export default PerfilComponent;
