import React, { useState, useEffect } from 'react';

// import { Container } from './styles';

import TableCollapsible from '../../Tables/TableColapseNoPagination';
import api from '@http/api';
import { Grid, IconButton } from '@material-ui/core';
import { CurrencyFormat, FormatDate } from '@utils/formats';

//import { Grid } from '@material-ui/core';
import { Form, Input, Select as SelectAntd, Spin, Radio } from 'antd';
import {
  AddOutlined,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from '@material-ui/icons';
import { SaveOutlined } from '@material-ui/icons';
import ButtonComponent from '@components/Shared/Buttons/Button';
import Modal from '@src/components/Shared/Modal/ModalPure';
import CreateSolicitacao from '@components/Shared/Solicitacao/create';
import { FileDownloadOutlined } from '@mui/icons-material/';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';

import {
  StyledTableCell,
  StyledTableCell2,
  StyledTableRow,
} from '@src/components/Shared/Tables/TableColapseNoPagination/styles';

interface ITest {
  Data: string;
  TipoSolicitacao: string;
  valor: string;
  dataVencimento: string;
  solicitadoPor: string;
  idStatusSolicitacao: number;
  StatusSolicitacao: string;
  Observacao: [
    {
      coluna: string;
      valor: string;
    },
  ];
}

interface IProps {
  idPerfil: number;
  functionReload?: Function;
  tiposPerfil: string[];
  triagem: string;
}

const GetSolicitacoes: React.FC<IProps> = ({
  idPerfil,
  functionReload,
  tiposPerfil,
  triagem
}) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<ITest[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [openModalCreateSolicitacao, setOpenModalCreateSolicitacao] =
    useState(false);
  const [perfilSelected, setPerfilSelected] = useState<number>(0);
  const [status, setStatus] = useState<ITest[]>([]);
  const [newUserVisibility, setnewUserVisibility] = React.useState(false);


  const columnsLabel = {
    Data: 'Data',
    TipoSolicitacao: 'Solicitação',
    valor: 'Valor',
    dataVencimento: 'Vencimento',
    solicitadoPor: 'Solicitado por',
    idStatusSolicitacao: 'StatusId',
    StatusSolicitacao: 'Status',
    Observacao: [
      {
        coluna: '',
        valor: '',
      },
    ],
  };

  useEffect(() => {
    async function loadData() {
      setLoading(true);
      await api
        .get(`api/Solicitacao/GetSolicitacaoByPerfilID/${idPerfil}`)
        .then(response => {
          const items = response.data;
          setTotal(response.data.total_doacoes);
          var itemData: ITest[] = [];

          items.map((item: ITest) => {
            console.log(item);
            return itemData.push({
              Data: item.Data,
              TipoSolicitacao: item.TipoSolicitacao,
              valor: item.valor,
              dataVencimento: item.dataVencimento,
              solicitadoPor: item.solicitadoPor,
              StatusSolicitacao: item.StatusSolicitacao,
              idStatusSolicitacao: item.idStatusSolicitacao,
              Observacao: item.Observacao,
            });
          });

          setData(itemData);
          setLoading(false);
        })
        .catch(error => {
          alert(error);
        });
    }

    loadData();
  }, [idPerfil]);

  const finishCreateSolicitacao = async () => {
    if (functionReload) {
      await functionReload();
    }
    setOpenModalCreateSolicitacao(false);
  };

  const handleDisableVisibilityChange = () => {
    setOpenModalCreateSolicitacao(false);
  };

  return (
    <>
      <Modal
        title="Nova solicitação"
        size="medio"
        openModal={openModalCreateSolicitacao}
        controlModal={(value: boolean) => setOpenModalCreateSolicitacao(value)}
      >
        <CreateSolicitacao
          perfil_id={idPerfil}
          tipo_perfil_descricao={tiposPerfil[perfilSelected]}
          finishCreate={finishCreateSolicitacao}
          //tiposPerfil={tiposPerfil}
          Cancel={handleDisableVisibilityChange}

        />
      </Modal>

      <Grid item sm={12} xs={12} style={{ paddingTop: 10 }}>
        <ButtonComponent
          color="secondary"
          onClick={() => setOpenModalCreateSolicitacao(true)}
          loading={loading}
          disabled={triagem !== 'DISPONÍVEL'}
        >
          <AddOutlined style={{ marginRight: 10 }} /> Nova solicitação
        </ButtonComponent>
      </Grid>

      <Grid
        item
        sm={12}
        xs={12}
        style={{ marginTop: 10, paddingLeft: 10, paddingRight: 10 }}
      ></Grid>
      <TableCollapsible
        loading={loading}
        columns={[columnsLabel]}
        FieldsBold={['TipoSolicitacao']}
        data={data}
        keys={[
          'Data',
          'TipoSolicitacao',
          'valor',
          'dataVencimento',
          'solicitadoPor',
          'StatusSolicitacao',
          'Detalhes'
        ]}
       />
    </>
  );
};

export default GetSolicitacoes;
