import React, { useEffect, useState, useRef } from 'react';

import { Grid, IconButton } from '@material-ui/core';
import { Form, Input, Select as SelectAntd, Spin, Radio } from 'antd';
import { SaveOutlined } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import ButtonComponent from '@components/Shared/Buttons/Button';
import InvisibleContent from '@src/components/Shared/InvisibleContent';

import ComponentPerfilServices from './services';

interface IProps {
  finishAgenciaDigito?: Function;
  agenciaDigito: any;
  agenciaDigitoNovo: any;
  perfil: number;
}

const optionsFilter = ['ID', 'CPF/CNPJ'];

const TriagemComponent:React.FC<IProps> = ({
  agenciaDigito,
  finishAgenciaDigito,
  agenciaDigitoNovo,
  perfil,

}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [formRef] = Form.useForm();

  const [informacaoInstantanea, setInformacaoInstantanea] = useState(true);

  const [loadingValidar, setLoadingValidar] = useState(false);
  const [loadingDescartar, setLoadingDescartar] = useState(false);


  const [colunaValidar, setColunaValidar] = useState('AgenciaDigito');
  const [colunaDescartar, setColunaDescartar] = useState('NovaAgenciaDigito');

  const [novoValor, setNovoValor] = useState('');

  async function onSubmitValidarNovo() {
    setLoadingValidar(true);

    const service = new ComponentPerfilServices();
    const { error,  } = await service.ValidarTriagemAgenciaDigito(
      perfil,
      informacaoInstantanea,
      novoValor,
      colunaValidar,
    );

    if (!error) {
      enqueueSnackbar(`Cadastro alterado com sucesso!`, {
        variant: 'success',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      if (finishAgenciaDigito) {
        finishAgenciaDigito();
      }
      window.location.reload();

    } else {
      enqueueSnackbar(`Ocorreu um erro`, {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
    }

    setLoadingValidar(false);
    //window.location.reload();
  }


  async function onSubmitDescartar() {
    setLoadingDescartar(true);

    const service = new ComponentPerfilServices();
    const { error,  } = await service.DescartarTriagemAgenciaDigito(
      perfil,
      colunaDescartar,
    );

    if (!error) {
      enqueueSnackbar(`Cadastro descartado com sucesso!`, {
        variant: 'success',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      //setLoading(false);
      if (finishAgenciaDigito) {
        finishAgenciaDigito();
      }
      window.location.reload();

    } else {
      enqueueSnackbar(`Ocorreu um erro`, {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      //setLoading(false);
    }
    setLoadingDescartar(false);
  }

  useEffect(() => {
    //init();
    window.document.title = 'CRM - Edição de Cadastro Triagem';
  }, []);

  return (
    <Grid container>
      <Grid container item sm={12}>
        <Grid container item xs={12} md={12} justify="center" alignItems="center">
          <Form
            form={formRef}
            scrollToFirstError={true}
            //onFinish={onSubmitValidar}
            requiredMark={false}
            style={{ width: '100%' }}
            layout="vertical"
          >
            <Grid item container justify="center" alignItems="center" alignContent='center' sm={12} xs={12} lg={12}>
              <Grid item lg={12} xs={12} sm={12}>
                <Form.Item name="agencia">
                  <Input  placeholder="Dígito agência"
                    onChange={(e: any) => {
                      setNovoValor(e.target.value);
                    }}
                  />
                </Form.Item>
              </Grid>

              <Grid container item lg={12} sm={12} xs={12} style={{ paddingTop: 10 }}>
                <Grid item lg={5} sm={12} xs={12}>
                  <ButtonComponent
                    color="default"
                    fullWidth
                    type="submit"
                    onClick={onSubmitDescartar}
                  >
                    <SaveOutlined /> Descartar
                  </ButtonComponent>
                </Grid>

                <Grid item lg={1} sm={12} xs={12}></Grid>

                <Grid item lg={5} sm={12} xs={12}>
                  <ButtonComponent
                    color="quartenary"
                    fullWidth
                    type="submit"
                    loading={loadingValidar}
                    onClick={onSubmitValidarNovo}
                  >
                    <SaveOutlined /> Validar
                  </ButtonComponent>
                </Grid>

              </Grid>
            </Grid>
          </Form>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TriagemComponent;
