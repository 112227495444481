import api from '@http/api';
import moment from 'moment';
import { IPerfil } from '@interfaces/UserComplete';

class PageEditTriagemPerfilServices {


  public async ValidarTriagemEstado(id_perfil: any, informacaoInstantanea: boolean, nova_informacao: any, coluna: string) {
    return await api
    .post(`/api/Triagem/AtualizarNovaInformacao?id_perfil=${id_perfil}&informacaoInstantanea=${informacaoInstantanea}&nova_informacao=${nova_informacao}&coluna=${coluna}`,)
    .then(response => {
      return {
        error: false,
        //response: response.data,
      };
    })
    .catch(error => {
      return {
        error: true,
        //response: [],
      };
    });
  }


  public async DescartarTriagemEstado(id_perfil: any, coluna: string) {
    return await api
    .delete(`/api/Triagem/DescartarNovaInformacao?id_perfil=${id_perfil}&coluna=${coluna}`,)
    .then(response => {
      return {
        error: false,
        //response: response.data,
      };
    })
    .catch(error => {
      return {
        error: true,
        //response: [],
      };
    });
  }


  public async loadEstadoById(id: string) {
    return await api
      .get(
        `/api/ContatoOffline/GetEstadoById/${id}`,
      )
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
          response: [],
        };
      });
  }


}

export default PageEditTriagemPerfilServices;
