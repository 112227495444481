import React, { useEffect, useState, useCallback } from 'react';
import { Grid, IconButton } from '@material-ui/core';
import { Checkbox, Form, Input, Select as SelectAntd, Spin } from 'antd';
import MaskedInput from 'antd-mask-input';
import { SaveOutlined, Edit } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';
import { format, addDays } from 'date-fns';
import ButtonComponent from '@components/Shared/Buttons/Button';
import InvisibleContent from '@components/Shared/InvisibleContent';

import InputEstadoCidade from './EstadoCidadeInputs';
import CidadesService from '@components/Shared/BuscaCidades/services';
import { isReal, ClearString } from '@utils/Masks';
import SearchCEPCorreios from '@utils/SearchCEPCorreios';
import useDebounce from '@hooks/useDebounce';

import ComponentPerfilServices, {
  IOrigemEspecifica,
  IMotivo,
  IPerfilTriagem,
  IRequestSolicitacao,
} from './services';

import ServiceCreatePerfil, { IConvenio, IOperacao, IFormaContribuicao, ITipoCorrespondecia, IOrigemMacro } from '@src/components/Shared/Perfil/create/services'


const DATA_EMAIL = addDays(new Date(), 7).toISOString().split('T')[0];
const DATA_CORREIO = addDays(new Date(), 30).toISOString().split('T')[0];

interface Iprops {
  //data: IPerfil[];
  perfilID: number;
  functionReload?: Function;
  verificaTriagem: boolean;
  idInteracao: number;
  idPessoa: number;
}

interface ICidade {
  id: string;
  label: string;
}

interface IRecorrencia {
  id: number;
  descricao: string;
}

interface IRecorrenciaPixCredito {
  intervalo_mes: number;
  descricao: string;
}


const PerfilComponent: React.FC<Iprops> = ({
 // data,
  perfilID,
  functionReload,
  verificaTriagem,
  idInteracao,
  idPessoa
}) => {
  const [formRef] = Form.useForm();

  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [loadingCEP, setLoadingCEP] = useState(false);

  const [perfilSelected, setPerfilSelected] = useState(perfilID);


  const [origensEspecificas, setOrigensEspecificas] = useState<
    IOrigemEspecifica[]
  >([]);

  const [motivosCadastro, setMotivosCadastro] = useState<IMotivo[]>([]);

  const [loadingEspecificaByMacro, setLoadingEspecificaByMacro] = useState(false);
  const [loadingMotivoByEspecifica, setLoadingMotivoByEspecifica] = useState(false);

  const [loadingUpdate, setLoadingUpdate] = useState(false);


  const [checkTipoFormContribuicao, setCheckTipoFormContribuicao] =
    useState<any>('');

    const [checkTipoFormContribuicaoID, setCheckTipoFormContribuicaoID] =
    useState<any>('');

    const [checkTipoCorrespondenciaID, setCheckTipoCorrespondenciaID] =
    useState<any>('');

    const [checkTipoConvenio, setCheckTipoConvenio] =
    useState<any>('');

    const [checkTipoConvenioID, setCheckTipoConvenioID] =
    useState<any>('');

    const [conveniocheckTipoConvenioBanco, setconveniocheckTipoConvenioBanco] =
    useState<any>('');

    const [checkTipoOperacao, setCheckTipoOperacao] =
    useState<any>('');

    const [checkConvenio, setCheckConvenio] =
    useState('');

    const [statusDebito, setStatusDebito] =
    useState(0);

    const [checkOrigemMacro, setCheckOrigemMacro] =
    useState<any>('');

    const [checkOrigemEspecifica, setCheckOrigemEspecifica] =
    useState<any>('');

    const [checkMotivo, setCheckMotivo] =
    useState<any>('');

    const [checkCidade, setCheckCidade] =
    useState<any>('');




    const [tiposCorrespondecia, setTiposCorrespondecia] = useState<
    ITipoCorrespondecia[]
  >([]);

  const [convenios, setConvenios] = useState<IConvenio[]>([]);
  const [operacoes, setOperacoes] = useState<IOperacao[]>([]);
  const [origensMacro, setOrigensMacro] = useState<IOrigemMacro[]>([]);

  const [recorrencias, setRecorrencias] = useState<IRecorrencia[]>([
    { id: 1, descricao: 'Mensal' },
    { id: 2, descricao: 'Bimestral' },
    { id: 3, descricao: 'Trimestral' },
    { id: 6, descricao: 'Semestral' },
    { id: 12, descricao: 'Anual' },
  ]);

  const [recorrenciasPixCredito, setRecorrenciasPixCredito] = useState<IRecorrenciaPixCredito[]>([
    { intervalo_mes: 999, descricao: 'Única' },
    { intervalo_mes: 1, descricao: 'Mensal' },
    { intervalo_mes: 6, descricao: 'Semestral' },
    { intervalo_mes: 12, descricao: 'Anual' },
  ]);

  const [formasContribuicao, setFormasContribuicao] = useState<
  IFormaContribuicao[]
>([]);



  const [valorModifyValorDebito, setValorModifyValorDebito] =
    useState<string>('');
  const [stringDebitoStatus, setStringDebitoStatus] = useState<string>('');

  const [loadingCadastro, setLoadingCadastro] = useState(false);
  const [loadingCadastroTeste, setLoadingCadastroTeste] = useState(false);
  const [loadingCadastroLigacao, setLoadingCadastroLigacao] = useState(false);
  const [loadingCreateSolicitacao, setLoadingCreateSolicitacao] = useState(false);

  const [perfilTriagem, setPerfilTriagem] = useState<IPerfilTriagem>();
  const [tipoPerfilTriagem, setTipoPerfilTriagem] =  useState(undefined);

  const [uf, setUF] = useState<any>();
  const [cidades, setCidades] = useState<ICidade[]>([]);


  const [controleMotivo, setControleMotivo] = useState<{
    id: number;
    descricao: string;
  }>();

  function handleInput(e: any) {
    var ss = e.target.selectionStart;
    var se = e.target.selectionEnd;
    e.target.value = e.target.value.toUpperCase();
    e.target.selectionStart = ss;
    e.target.selectionEnd = se;
  };

  const createArrayRange = (
    start: number,
    increment: number,
    length: number,
  ) => {
    let array = [];

    for (let i = start; i <= length; i += increment) {
      array.push(i);
    }

    return array;
  };


  const loadPerfilTriagem = useCallback(async perfilID => {
    setLoadingCadastro(true);
    const services = new ComponentPerfilServices();
    const { error, response } = await services.GetPerfilByID({ id: perfilID });

    if (!error && response) {

      const perfilTriagem: IPerfilTriagem = {
        nome: response.nome,
        tipo_perfil_descricao: response.tipo_perfil_descricao,
        origem_macro_id: response.origem_macro_id,
        origem_macro_descricao: response.origem_macro_descricao,
        origem_id: response.origem_id,
        origem_descricao: response.origem_descricao,
        motivo_cadastro_id: response.motivo_cadastro_id,
        motivo_cadastro_descricao: response.motivo_cadastro_descricao,
        tipo_correspondencia_id: response.tipo_correspondencia_id,
        tipo_correspondencia_descricao: response.tipo_correspondencia_descricao,
        forma_contribuicao_id: response.forma_contribuicao_id,
        forma_contribuicao_descricao: response.forma_contribuicao_descricao,
        valor_padrao_doacao: response?.valor_padrao_doacao,
        telefone_fixo: response.telefone_fixo,
        telefone_comercial: response.telefone_comercial,
        celular: response.celular,
        whatsapp: response.whatsapp,
        email: response.email,
        contato_offline: response.contato_offline,
        nome_fantasia: response.nome_fantasia,
        debito_automatico: response.debito_automatico


      };

      setPerfilTriagem(perfilTriagem);
      setCheckTipoFormContribuicao(perfilTriagem?.forma_contribuicao_descricao)
      setCheckTipoFormContribuicaoID(perfilTriagem?.forma_contribuicao_id)
      setCheckTipoConvenioID(perfilTriagem.debito_automatico.id_convenio)
      setStatusDebito(perfilTriagem.debito_automatico.status_ativo)
      setconveniocheckTipoConvenioBanco(perfilTriagem.debito_automatico.id_banco)
      setCheckTipoCorrespondenciaID(perfilTriagem.tipo_correspondencia_id)
      setCheckOrigemEspecifica(perfilTriagem.origem_id)
      setCheckMotivo(perfilTriagem.motivo_cadastro_id)
      setCheckCidade(perfilTriagem.contato_offline.cidade_id)
      setUF(perfilTriagem.contato_offline.estado_descricao)
      setCheckOrigemMacro(perfilTriagem.origem_macro_id)
      //checkTipoOperacao(perfilTriagem.debito_automatico.operacao)

      setStringDebitoStatus(
        perfilTriagem.debito_automatico.status_ativo
          ? ' está ativo'
          : ' está inativo',
      );


      formRef.setFieldsValue({
        nome: response.nome,
        origem_id: response.origem_descricao,
        tipo_perfil_descricao: response.tipo_perfil_descricao,
        //origem_descricao: response.origem_descricao,
        origem_macro_id: response.origem_macro_descricao,
        motivo_cadastro_id: response.motivo_cadastro_descricao,
        tipo_correspondencia_id: response.tipo_correspondencia_descricao,
        forma_contribuicao_id: response.forma_contribuicao_descricao,
        //forma_contribuicao_descricao: response.forma_contribuicao_descricao,
        valor_padrao_doacao: Intl.NumberFormat('pt-br', {
          style: 'currency',
          currency: 'BRL',
        })
          .format(response.valor_padrao_doacao)
          .replace('R$', '')
          .trim() || '',
        telefone_fixo: response.telefone_fixo.contato_online_descricao,
        telefone_comercial: response.telefone_comercial.contato_online_descricao,
        celular: response.celular.contato_online_descricao,
        whatsapp: response.whatsapp.contato_online_descricao,
        email: response.email.contato_online_descricao,
        cep: response.contato_offline.cep,
        endereco: response.contato_offline.endereco,
        bairro: response.contato_offline.bairro,
        Distrito: response.contato_offline.Distrito,
        complemento: response.contato_offline.complemento,
        estado_descricao: response.contato_offline.estado_descricao,
        cidade_id: response.contato_offline.cidade_id,
        contato_offline: response.contato_offline,
        nome_fantasia: response.nome_fantasia,
        debito_automatico: response.debito_automatico,
        convenio_id: response.debito_automatico.id_convenio,
        agencia_numero: response.debito_automatico.agencia_numero,
        agencia_digito: response.debito_automatico.agencia_digito,
        conta_numero: response.debito_automatico.conta_numero,
        conta_digito: response.debito_automatico.conta_digito,
        valor_debito: Intl.NumberFormat('pt-br', {
          style: 'currency',
          currency: 'BRL',
        })
          .format(response.debito_automatico?.valor)
          .replace('R$', '')
          .trim() || undefined,
        dia_vencimento: response.debito_automatico.dia_vencimento,
        operacao: response.debito_automatico.operacao,
        debito_automatico_status_ativo: response.debito_automatico.status_ativo

      });
      setLoadingCadastro(false);
    } else {
      console.log('error', error);
    }
  }, []);


  async function onSubmitListaLigacao() {
    setLoadingCadastroLigacao(true);
    const services = new ComponentPerfilServices();
    const { error,  } = await services.CreateListaLigacaoTriagem(
      perfilID
    );

    setLoadingCadastroLigacao(false);

    if (!error) {
      enqueueSnackbar(`Cadastro adicionado na lista de ligação`, {
        variant: 'success',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      setLoadingCadastroLigacao(false);
      history.push('/ligacoes');


    } else {
      enqueueSnackbar(`Ocorreu um erro`, {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      setLoadingCadastroTeste(false);
    }

    //window.location.reload();
  }

  const loadConvenios = async (id: number) => {
    const servicePerfilCreate = new ServiceCreatePerfil();


    const conveniosResponse = await servicePerfilCreate.GetConvenios(id);

    if (!conveniosResponse.error && conveniosResponse.response) {
      setConvenios(conveniosResponse.response);
    }
  };

  const loadOperacoes = async (id: number) => {
    let banco = conveniocheckTipoConvenioBanco;

    convenios.map(item => {
      if (item.id === id) {
        banco = Number(item.id_banco);
      }
    });

    const servicePerfilCreate = new ServiceCreatePerfil();

    const operacoesResponse = await servicePerfilCreate.GetOperacoes(banco);

    if (!operacoesResponse.error && operacoesResponse.response) {
      setOperacoes(operacoesResponse.response);
      //validaOperacao(operacoesResponse.response.length);
    }
  };

  const init = async () => {

    const service = new ServiceCreatePerfil();

    const formaContribuicaoResponse = await service.loadFormaContribuicao();

    if (!formaContribuicaoResponse.error) {
      setFormasContribuicao(formaContribuicaoResponse.response);
      let id: any;

      const forma = formaContribuicaoResponse.response.filter(
        x => x.id === id,
      );

      if (forma.length > 0) {
        setCheckTipoFormContribuicao(forma[0].descricao);
      }
    }

    const tiposCorrespondeicaResponse =
    await service.loadTiposCorrespondecia();

    if (!tiposCorrespondeicaResponse.error) {
      setTiposCorrespondecia(tiposCorrespondeicaResponse.response);
    }

    loadConvenios(checkTipoFormContribuicaoID)

    const origemMacroResponse = await service.loadOrigemMacro();

    if (!origemMacroResponse.error) {
        setOrigensMacro(origemMacroResponse.response);
      }


    //loadOperacoes(conveniocheckTipoConvenioBanco)
  }

  const loadOrigemEspecificaByOrigemMacro = async (
    origem_macro_id: string | number,
  ) => {
    const service = new ServiceCreatePerfil();
    const { error, response } = await service.GetTipoOrigemEspecificaByOrigemMacro(
      origem_macro_id,
    );
    if (!error && response) {
      setOrigensEspecificas(response?.result || []);
      setLoadingEspecificaByMacro(true);

    } else {
      return 0;
    }
  };

  const loadMotivoByOrigemEspecifica = async (

    origem_especifica_id: string | number,
  ) => {
    const service = new ServiceCreatePerfil();
    const { error, response } = await service.GetTipoMotivoByOrigemEspecifica(
      origem_especifica_id,
    );
    if (!error && response) {
      setMotivosCadastro(response?.result || []);
      setLoadingMotivoByEspecifica(true);

    } else {
      return 0;
    }
  };


  const filterCheckTipoFormContribuicao = (id: any) => {
    const forma = formasContribuicao.filter(x => x.id === id);

    if (forma.length > 0) setCheckTipoFormContribuicao(forma[0].descricao)
    setCheckTipoFormContribuicaoID(forma[0].id);
  };

  const filterCheckTipoCorrespondencia= (id: any) => {
    const forma = tiposCorrespondecia.filter(x => x.id === id);

    if (forma.length > 0)
    setCheckTipoCorrespondenciaID(forma[0].id);
  };

  const filterCheckTipoConvenio = (id: any) => {
    const forma = convenios.filter(x => x.id === id);

    if (forma.length > 0) setCheckTipoConvenio(forma[0].descricao)
    setconveniocheckTipoConvenioBanco(forma[0].id_banco)
    setCheckTipoConvenioID(forma[0].id)
  };

  const filterCheckTipoOperacao = (id: any) => {
    const forma = operacoes.filter(x => x.id === id);

    if (forma.length > 0) setCheckTipoOperacao(forma[0].codigo_operacao)
  };

  const searchCEPDebounce = async (value: string) => {
    const searchCEPCorreios = new SearchCEPCorreios();
    setLoadingCEP(true);
    const { error, response } = await searchCEPCorreios.SearchCEP(value);
    setLoadingCEP(false);
    if (!error && response) {
      setUF(response.uf);
      let endereco1 = response.logradouro;
      endereco1 = endereco1.toUpperCase();

      let bairro1 = response.bairro;
      bairro1 = bairro1.toUpperCase();
      formRef.setFieldsValue({
        endereco: endereco1,
        bairro: bairro1,
        estado_descricao: response.uf,
        //cidade_id: response.localidade,
      });
    }
  };
  const debounceCEP = useDebounce(searchCEPDebounce, 800);



  useEffect(() => {
    //loadInfoAlteradas(perfilID);
    loadPerfilTriagem(perfilID);
    init();

  }, []);


  useEffect(() => {
    const getCidades = async () => {
      const cidadesService = new CidadesService();
      const getCidadesByUFResponse = await cidadesService.getCidadesByUF(uf);
      if (!getCidadesByUFResponse.error) {
        setCidades(getCidadesByUFResponse.response);
      }
    };

    getCidades();
  }, [uf]);


  //console.log('ID CONTRIB', conveniocheckTipoConvenioBanco)


  async function onSubmit(values: any, motivo: boolean) {
    const componentPerfilServices = new ComponentPerfilServices();

    setLoadingUpdate(true);

    function limparValor(valor: string): string {
      return valor.replace(/[^\d]+/g, '');
    }

    //const valorNumerico = parseFloat(limparValor(valorModifyValorPadrao)) / 100;
    const cepLimpo = limparValor(values.cep);

    const updatePerfilRequest: any = {
      contato_offline: {
        cep: cepLimpo || '',
        endereco: values.endereco.toUpperCase() || '',
        complemento: values.complemento || '',
        Distrito: values.Distrito || '',
        bairro: values.bairro.toUpperCase() || '',
        cidade_id: values.cidade_id || 0,
      },
      id: perfilID.toString(),
      status_ativo: 1,
      forma_contribuicao_id: checkTipoFormContribuicaoID || 0,
      motivo_cadastro_id: checkMotivo || 0,
      nome_fantasia: values.nome_fantasia || '',
      origem_especifica_id: checkOrigemEspecifica || 0,
      origem_macro_id: checkOrigemMacro || 0,
      tipo_correspondencia_id: checkTipoCorrespondenciaID || 0,
      valor_padrao_doacao: ClearString(valorModifyValorDebito) / 100 || 0,
      recorrencia_meses: values.recorrencia_meses || '',
      celular: {
        contato_online_descricao: values.celular || '',
      },
      whatsapp: {
        contato_online_descricao: values.whatsapp || '',
      },
      email: {
        contato_online_descricao: values.email || '',
      },
      telefone_comercial: {
        contato_online_descricao: values.telefone_comercial || '',
      },
      telefone_fixo: {
        contato_online_descricao: values.telefone_fixo || '',
      },

      debito_automatico: {
        id: values.id || 0,
        id_convenio: checkTipoConvenioID || 0,
        id_banco:  conveniocheckTipoConvenioBanco || 0,
        //nome_cedente?: string;
        agencia_numero: values.agencia_numero || '',
        agencia_digito: values.agencia_digito || '',
        conta_numero: values.conta_numero || '',
        conta_digito: values.conta_digito || '',
        operacao: checkTipoOperacao || '',
        valor: ClearString(valorModifyValorDebito) / 100 || 0,
        dia_vencimento: values.dia_vencimento || '',
        recorrente: values.recorrencia || 0,
        status_ativo: statusDebito || 0,
      },

      debito_conta_energia: {
        id: 0,
        id_convenio: 0,
        conta_contrato_numero: '',
        recorrencia: 0,
        valor: 0,
        status_ativo: 0,
        //id_perfil: 0,
      },


      perfil_recorrencia_doacao: {
        id: 0,
        intervalo_mes: 0,
        status_ativo: 0,
      },



    };


    //console.log(updatePerfilRequest.debito_automatico);

    const updatePerfilResponse = await componentPerfilServices.UpdatePerfil(
      updatePerfilRequest,
    );

    if (!updatePerfilResponse.error) {
      enqueueSnackbar(`Perfil salvo com sucesso!`, {
        title: 'Perfil salvo com sucesso!',
        variant: 'success',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      setLoadingUpdate(false);

      window.location.reload();

      if ( idInteracao === undefined){
        history.push('/cadastros/consulta/triagem');
      }else if (idInteracao !== undefined){
        history.push(`/cadastro/${idPessoa}?telefonia={"id_ligacao_interacao":${idInteracao},"id_pessoa":${idPessoa},"id_perfil":${perfilID}}`);
      }
    } else {
      enqueueSnackbar(`Ocorreu um errro`, {
        title: 'Ocorreu um erro ',
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      setLoadingUpdate(false);
    }
  }

  async function onSubmitSolicitacao(values: any) {
    setLoadingCreateSolicitacao(true);

    console.log(values);

    if(checkTipoCorrespondenciaID === 1) //EMAIL
    {
      const createSolicitacao: IRequestSolicitacao = {
        id_perfil: perfilID,
        id_tipo_solicitacao: 21,
        observacoes: '',
        valor: 0,
        data_vencimento: DATA_EMAIL,
        id_campanha: 0,
      };

      const service = new ComponentPerfilServices();
      const { error, response } = await service.CreateSolicitacao(
        createSolicitacao,
      );

      setLoadingCreateSolicitacao(false);

      if (!error) {
        console.log('true');

      } else {
        console.log('Error');
      }

    }

    if(checkTipoCorrespondenciaID === 2) // CORREIO
    {
      const createSolicitacao: IRequestSolicitacao = {
        id_perfil: perfilID,
        id_tipo_solicitacao: 15,
        observacoes: '',
        valor: 0,
        data_vencimento: DATA_CORREIO,
        id_campanha: 0,
      };

      const service = new ComponentPerfilServices();
      const { error, response } = await service.CreateSolicitacao(
        createSolicitacao,
      );

      setLoadingCreateSolicitacao(false);

      if (!error) {
        console.log('true');

      } else {
        console.log('Error');
      }

    }
  }

  const onSubmitCheckMotivo = async (values: any) => {
      onSubmit(values, false);
      onSubmitSolicitacao(values);
  };

  return (
    <Grid container>
      <Grid container item sm={12}>
      <Grid item lg={6} xs={6} style={{ marginLeft: 3,  }}>
          <ButtonComponent
            color="primary"
            fullWidth
            disabled={verificaTriagem === true}
            onClick={() => onSubmitListaLigacao()}
            //titleButton="Aprovar solicitações"
            //Icon={() => <Icon path={mdiArrowTopRight} size={1} color="White" />}
          >Adicionar a lista de ligação</ButtonComponent>
        </Grid>
        <Spin size="large" spinning={loadingUpdate} tip="Enviando dados...">


          {/* MODAIS */}





   {/*FORMULARIO */}



          <Form
           form={formRef}
           size="middle"
           layout="vertical"
           scrollToFirstError={true}
           onFinish={onSubmitCheckMotivo}
           labelAlign="left"
           labelCol={{
             span: 24,
           }}
           wrapperCol={{
             span: 24,
           }}
           requiredMark={false}
          >
            <Grid
              container item sm={12} xs={12} lg={12}

              justify="center"
              alignItems="center"
              style={{paddingTop: 3}}
            >



              <Grid item lg={12} xs={12}>
                <Form.Item name="tipo_perfil_descricao">
                  <Input disabled
                     suffix={ <InvisibleContent visible={tipoPerfilTriagem !== (undefined)}><IconButton
                      size='small'
                      //disabled={tipoPerfilTriagem === (undefined)}
                        color="primary"

                        style={{ minWidth: 0, height: 10}}
                      >
                        <Edit fontSize="small" />



                      </IconButton></InvisibleContent>}
                      placeholder="Tipo de perfil" />
                </Form.Item>
              </Grid>

              <Grid container justify="center" alignItems="center">
                {/* Tipo de Perfil */}
                {/* <Grid item lg={12} xs={12}>
                  <Form.Item
                    label="Tipo de perfil"
                    hasFeedback
                    name="nome"
                    rules={[{ required: true, message: 'Campo obrigatorio' }]}
                  >
                    <Input disabled placeholder="Tipo de perfil" />
                  </Form.Item>
                </Grid> */}
                {/* Tipo de Perfil */}

                {/* ORIGEM MACRO, ESPECIFICA e MOTIVO */}

                <Grid container>
                   <Grid item lg={12} sm={12} xs={12} style={{ marginTop: 10 }}>
                    <Form.Item
                      name="origem_macro_id"
                      label="Origem macro"
                      //hasFeedback
                      rules={[
                        {
                          required: false,
                          message: 'Selecione uma Origem macro',
                        },
                      ]}
                    >
                      <SelectAntd disabled={verificaTriagem === true} placeholder="Origem macro" onChange={(e: string) =>{loadOrigemEspecificaByOrigemMacro(e)}}>
                        {origensMacro.map(item => (
                          <SelectAntd.Option key={item.id} value={item.id}>
                            {item.descricao}
                          </SelectAntd.Option>
                        ))}
                      </SelectAntd>
                    </Form.Item>
                  </Grid>

                  <Grid item lg={12} sm={12} xs={12} style={{ marginTop: 10 }}>
                    <Form.Item
                      name="origem_id"
                      label="Origem específica"
                      rules={[
                        {
                          required: true,
                          message: 'Selecione uma Origem específica',
                        },
                      ]}
                    >
                      <SelectAntd disabled={verificaTriagem === true && !loadingEspecificaByMacro} placeholder="Origem específica" onChange={(e: string) =>{loadMotivoByOrigemEspecifica(e)}}>
                        {origensEspecificas.map(item => (
                          <SelectAntd.Option key={item.id} value={item.id}>
                            {item.descricao}
                          </SelectAntd.Option>
                        ))}
                      </SelectAntd>
                    </Form.Item>
                  </Grid>
                </Grid>

                <Grid item sm={12} xs={12}>
                  <Form.Item
                    name="motivo_cadastro_id"
                    label="Motivo"
                    rules={[
                      {
                        required: true,
                        message: 'Selecione um motivo',
                      },
                    ]}
                  >
                    <SelectAntd disabled={verificaTriagem === true && !loadingMotivoByEspecifica} placeholder="Motivo">
                      {motivosCadastro.map(item => (
                        <SelectAntd.Option key={item.id} value={item.id}>
                          {item.descricao}
                        </SelectAntd.Option>
                      ))}
                    </SelectAntd>
                  </Form.Item>
                </Grid>
                {/* ORIGEM MACRO, ESPECIFICA e MOTIVO */}

                {/* { formaContribuicao, Valor e tipoCorrespondencia } */}
                <Grid container>
                  <Grid item sm={6} xs={12}>

                    <Form.Item
                      name="forma_contribuicao_id"
                      label="Forma de contrib."
                     // hasFeedback
                      rules={[
                        {
                          required: true,
                          message: 'Selecione uma forma de Contribuição',
                        },
                      ]}
                    >
                      <SelectAntd
                        disabled={verificaTriagem === true}
                        placeholder="Forma de contrib."
                        onChange={e => {
                          //checkTipoFormContribuicaoID(Number(e.target.value));
                          loadConvenios(Number(e));
                          filterCheckTipoFormContribuicao(e);
                         // validaAlteracao();
                        }}
                      >
                        {formasContribuicao.map(item => (
                          <SelectAntd.Option key={item.id} value={item.id}>
                            {item.descricao}
                          </SelectAntd.Option>
                        ))}
                      </SelectAntd>
                    </Form.Item>



                  </Grid>

                  <Grid item sm={6} xs={12}>


                    <Form.Item
                      name="tipo_correspondencia_id"
                      label="Tipo de corresp."
                      rules={[
                        {
                          required: true,
                          message: 'Selecione um tipo de correspondencia',
                        },
                      ]}
                    >
                      <SelectAntd
                        disabled={verificaTriagem === true}
                        placeholder="Tipo de corresp."
                        onChange={e => {
                          //checkTipoFormContribuicaoID(Number(e.target.value));
                          //loadConvenios(Number(e));
                          filterCheckTipoCorrespondencia(e);
                         // validaAlteracao();
                        }}
                      >
                        {tiposCorrespondecia.map(item => (
                          <SelectAntd.Option key={item.id} value={item.id}>
                            {item.descricao}
                          </SelectAntd.Option>
                        ))}
                      </SelectAntd>
                    </Form.Item>

                  </Grid>


                  <InvisibleContent
                    visible={checkTipoFormContribuicao === 'DÉBITO AUTOMÁTICO'}
                  >
                    <>
                      <Grid item sm={6} xs={12}>

                    <Form.Item
                      name="convenio_id"
                      label="Convênio"
                      rules={[
                        {
                          required: true,
                          message: 'Selecione um convênio',
                        },
                      ]}
                    >
                         <SelectAntd
                          disabled={verificaTriagem === true}
                          placeholder="Convênio"
                          onChange={(e: any) => {
                            setCheckConvenio(e);
                            filterCheckTipoConvenio(e);
                            loadOperacoes(Number(e));
                            //validaAlteracao();
                          }}
                        >
                          {convenios.map(item => (
                            <SelectAntd.Option key={item.id} value={item.id}>
                              {item.descricao}
                            </SelectAntd.Option>
                          ))}
                          </SelectAntd>



                    </Form.Item>

                      </Grid>





                      <Grid item sm={6} xs={12}>
                    <Form.Item
                      name="dia_vencimento"
                      label="Dia do vencimento"
                      //hasFeedback
                      rules={[
                        {
                          required: true,
                          message: 'Selecione um dia',
                        },
                      ]}
                    >
                     <SelectAntd disabled={verificaTriagem === true} placeholder="Dia do vencimento">
                            {createArrayRange(1, 1, 31).map(i => (
                              <SelectAntd.Option key={i} value={i}>
                                {i}
                              </SelectAntd.Option>
                            ))}
                          </SelectAntd>
                    </Form.Item>
                    </Grid>


                      <Grid item sm={9} xs={12}>
                        <Form.Item
                          name="agencia_numero"
                          label="Número da Agência"
                         // hasFeedback
                          rules={[
                            {
                              required: true,
                            },
                            { max: 4, message: 'Máximo de  4 caracteres' },
                            {
                              pattern: /^[a-zA-Z0-9]+$/,
                              message: "Somente números ou letras"
                            }
                          ]}
                        >
                         <Input
                            disabled={verificaTriagem === true}
                            placeholder="Número da agência"
                          ></Input>
                        </Form.Item>
                      </Grid>

                      <Grid item sm={3} xs={12}>
                        <Form.Item
                          name="agencia_digito"
                          label="Digito da Agência"
                          //hasFeedback
                          rules={[
                            {
                              required: false,
                            },
                            { max: 1, message: 'Máximo de  1 caracter' },
                            {
                             // pattern: /^[a-zA-Z0-9]+$/,
                             // message: "Somente números ou letras"
                            }
                          ]}
                        >
                           <Input
                            disabled={verificaTriagem === true}
                            placeholder="Número do digito"
                          ></Input>
                        </Form.Item>
                      </Grid>


                        <Grid item sm={9} xs={12}>
                          <Form.Item
                            name="conta_numero"
                            label="Número da conta"
                           // hasFeedback
                            rules={[
                              {
                                required: true,
                                max: 5, message: 'Máximo de 5 caracteres',
                              },
                            ]}
                          >
                             <Input
                            disabled={verificaTriagem === true}
                            placeholder="Número da conta"
                          ></Input>
                          </Form.Item>
                        </Grid>




                      <Grid item sm={3} xs={12}>
                        <Form.Item
                          name="conta_digito"
                          label="Digito da conta"
                          //hasFeedback
                          rules={[
                            {
                              required: false,
                            },
                          ]}
                        >
                          <Input
                            disabled={verificaTriagem === true}
                            placeholder="Digito da conta"
                          ></Input>
                        </Form.Item>
                      </Grid>


                      <InvisibleContent
                    visible={checkTipoConvenio === 'CAIXA ECONOMICA FEDERAL' || checkTipoConvenio === 'SANTANDER'}

                  >
                      <Grid item sm={6} xs={12}>

                    <Form.Item
                      name="operacao"
                      label="Operação"
                      rules={[
                        {
                          required: true,
                          message: 'Selecione uma operação',
                        },
                      ]}
                    >
                         <SelectAntd
                          disabled={verificaTriagem === true}
                          placeholder="Operação"
                          onChange={(e: any) => {
                            //setCheckConvenio(e);
                            filterCheckTipoOperacao(e);
                            //validaAlteracao();
                          }}
                        >
                          {operacoes.map(item => (
                            <SelectAntd.Option key={item.id} value={item.id}>
                              {item.descricao}
                            </SelectAntd.Option>
                          ))}
                          </SelectAntd>
                    </Form.Item>


                      </Grid>
                       </InvisibleContent>
                      <Grid item sm={6} xs={12}>
                        <Form.Item
                          name="valor_debito"
                          label="Valor do débito"
                          //hasFeedback
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <Input
                            disabled={verificaTriagem === true}
                            placeholder="Valor débito"
                            onChange={(e: any) => {
                              formRef.setFieldsValue({
                                valor_debito: isReal(e.target.value),
                              });
                              setValorModifyValorDebito(e.target.value);


                              //setValorModifyValorDebito(e.target.value);
                            }}
                          />
                        </Form.Item>
                      </Grid>

                      <InvisibleContent visible={verificaTriagem === true}>
                     <Grid item sm={6} xs={12} style={{paddingTop: 20}}>
                        <Form.Item
                          name="debito_automatico_status_ativo"
                          valuePropName="checked"
                        >
                          <Checkbox
                          disabled
                            onChange={e => {
                              //setStatusDebito(e.target.value)
                              console.log(e.target.checked);
                              setStringDebitoStatus(
                                e.target.checked
                                  ? ' está ativo'
                                  : ' está inativo',
                              );

                            }}
                            style={{paddingBottom: 30}}
                          >
                            Débito
                            {stringDebitoStatus}
                          </Checkbox>
                        </Form.Item>
                          </Grid>
                          </InvisibleContent>

                    </>
                  </InvisibleContent>

                  {/*hjkhjkhkhkhkhjkhhhjkhjkhkhkhjkhkhjkhjkhkdgjdgjdgjdjjgjghjghjfjghjgjgchzdkjsfhskdfsdjfsldf*/}
                 {/* <InvisibleContent
                    visible={checkTipoFormContribuicao === 'CONTA DE ENERGIA'}
                  >
                    <>
                      <Grid item sm={6} xs={12}>
                        <Form.Item
                          name="convenio_energia_id"
                          label="Convênio"
                          hasFeedback
                          rules={[
                            {
                              required: false,

                              message: 'Selecione um convênio',
                            },
                          ]}
                        >
                          <Input placeholder="Convênio" disabled/>
                        </Form.Item>
                      </Grid>

                      <Grid item sm={6} xs={12}>
                        <Form.Item
                          name="conta_contrato_numero"
                          label="Número da conta"
                          hasFeedback
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <Input placeholder="Número da conta"/>
                        </Form.Item>
                      </Grid>

                      <Grid item sm={6} xs={12}>
                        <Form.Item
                          name="recorrencia"
                          label="Recorrência"
                          hasFeedback
                          rules={[
                            {
                              required: false,
                            },
                          ]}
                        >
                          <SelectAntd placeholder="Recorrência" >
                            {recorrencias.map(item => (
                              <SelectAntd.Option key={item.id} value={item.id}>
                                {item.id + ' - ' + item.descricao}
                              </SelectAntd.Option>
                            ))}
                          </SelectAntd>
                        </Form.Item>
                      </Grid>

                      <Grid item sm={6} xs={12}>
                        <Form.Item
                          name="valor_debito_energia"
                          label="Valor do débito"
                          hasFeedback
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <Input
                            placeholder="Valor débito"
                            onChange={(e: any) => {
                              formRef.setFieldsValue({
                                valor_debito_energia: isReal(e.target.value),
                              });

                              setValorModifyValorDebitoEnergia(e.target.value);
                            }}
                          />
                        </Form.Item>
                      </Grid>

                      <Form.Item
                        name="debito_conta_energia_status_ativo"
                        valuePropName="checked"
                      >
                        <Checkbox
                          onChange={e => {
                            console.log(e.target.checked);
                            setStringEnergiaStatus(
                              e.target.checked
                                ? ' está ativo'
                                : ' está inativo',
                            );


                          }}
                          style={{paddingBottom: 40}}
                        >
                          <p style={{fontSize: 12}}>Conta de energia
                          {stringEnergiaStatus}</p>
                        </Checkbox>
                      </Form.Item>
                    </>
                  </InvisibleContent>
                  */}
                </Grid>


{/*
                <InvisibleContent
                  visible={checkTipoFormContribuicao === 'PIX'}
                >
                  <>
                    <Grid item lg={6} xs={12}>
                      <Form.Item
                        name="pix_credito_status_ativo"
                        valuePropName="checked"
                      >
                        <Checkbox
                          onChange={e => {
                            console.log(e.target.checked);
                            setStringPixCreditoStatus(
                              e.target.checked
                              ? ' está ativo'
                              : ' está inativo',
                            );

                          }}

                          style={{paddingBottom: 40}}
                        >
                          <p  style={{fontSize: 12}}> Pix recorrência
                          {stringPixCreditoStatus} </p>
                        </Checkbox>
                      </Form.Item>

                    </Grid>
                    <Grid item lg={6} xs={12}>
                      <Form.Item
                        name="intervalo_mes"
                        label="Recorrência"
                        hasFeedback
                        rules={[{ required: false, },]}
                      >
                        <SelectAntd placeholder="Recorrência">
                          {recorrenciasPixCredito.map(item => (
                            <SelectAntd.Option key={item.intervalo_mes} value={item.intervalo_mes}>
                              {item.descricao}
                            </SelectAntd.Option>
                          ))}
                        </SelectAntd>
                      </Form.Item>

                    </Grid>
                  </>
                </InvisibleContent>

                <InvisibleContent
                  visible={checkTipoFormContribuicao ==='CARTÃO DE CRÉDITO'}
                >
                  <>
                    <Grid item lg={6} xs={12}>
                      <Form.Item
                        name="pix_credito_status_ativo"
                        valuePropName="checked"
                      >
                        <Checkbox
                          onChange={e => {
                            console.log(e.target.checked);
                            setStringPixCreditoStatus(
                              e.target.checked
                              ? ' está ativo'
                              : ' está inativo',
                            );


                          }}
                          style={{paddingBottom: 40}}
                        >
                          <p style={{fontSize: 12}}>Cartão de crédito recorrência
                          {stringPixCreditoStatus}</p>
                        </Checkbox>
                      </Form.Item>

                    </Grid>
                    <Grid item lg={6} xs={12}>
                      <Form.Item
                        name="intervalo_mes"
                        label="Recorrência"
                        hasFeedback
                        rules={[{ required: false, },]}
                      >
                        <SelectAntd placeholder="Recorrência">
                          {recorrenciasPixCredito.map(item => (
                            <SelectAntd.Option key={item.intervalo_mes} value={item.intervalo_mes}>
                              {item.intervalo_mes + ' - ' + item.descricao}
                            </SelectAntd.Option>
                          ))}
                        </SelectAntd>
                      </Form.Item>

                    </Grid>
                  </>
                </InvisibleContent>
                */}

                <InvisibleContent
                    visible={checkTipoFormContribuicao != 'DÉBITO AUTOMÁTICO'}
                  >
                    <>
                <Grid item lg={12} xs={12}>
                  <Form.Item label="Valor padrão doação" name="valor_padrao_doacao">
                  <Input
                   onChange={(e: any) => {
                    formRef.setFieldsValue({
                      valor_padrao_doacao: isReal(e.target.value),
                    });
                    setValorModifyValorDebito(e.target.value);
                    }}

                      disabled={verificaTriagem === true}
                      placeholder="Valor"
                        >

                      </Input>

                  </Form.Item>
                </Grid>
                </>
                </InvisibleContent>
                {/* { formaContribuicao, Valor e tipoCorrespondencia } */}

                {/* { celular, whatsapp, tel fixo, ttel comercial, email } */}
                <Grid container>
                  <Grid item lg={6} xs={12}>
                    <Form.Item label="Celular" name="celular"
                    rules={[
                      {
                        //pattern: /\(\d{2,}\) \d{5,}\-\d{4}/,
                        //message: 'Número de celular inválido'
                      }

                    ]}>
                      <MaskedInput
                      disabled={verificaTriagem === true}
                      mask='(11) 11111-1111'
                        placeholder="Celular"


                      />
                    </Form.Item>
                  </Grid>

                  <Grid item lg={6} xs={12}>
                    <Form.Item label="WhatsApp" name="whatsapp"
                     rules={[
                      {
                        //pattern: /\(\d{2,}\) \d{5,}\-\d{4}/,
                        //message: 'Número de celular inválido'
                      }

                    ]}
                    >
                      <MaskedInput
                        mask="(11) 11111-1111"
                        placeholder="WhatsApp"
                        disabled={verificaTriagem === true}


                      />
                    </Form.Item>
                  </Grid>

                  <Grid item lg={6} xs={12}>
                    <Form.Item
                      label="Telefone fixo"
                      name="telefone_fixo"
                      rules={[
                        {
                          //pattern: /\(\d{2,}\) \d{4,}\-\d{4}/,
                          //message: 'Número de telefone inválido'
                        }

                      ]}
                    >
                      <MaskedInput
                      mask='(11) 1111-1111'
                      disabled={verificaTriagem === true}
                        placeholder="Telefone fixo"


                      />
                    </Form.Item>
                  </Grid>

                  <Grid item lg={6} xs={12}>
                    <Form.Item
                      label="Telefone comercial"
                      name="telefone_comercial"
                      rules={[
                        {
                         // pattern: /\(\d{2,}\) \d{4,}\-\d{4}/,
                          //message: 'Número de celular inválido'
                        }

                      ]}
                    >
                      <MaskedInput
                        mask="(11) 1111-1111"
                        placeholder="Telefone comercial"
                        disabled={verificaTriagem === true}

                      />
                    </Form.Item>
                  </Grid>
                </Grid>

                <Grid item lg={12} xs={12}>
                  <Form.Item
                    label="E-mail"
                    name="email"
                    rules={[
                      {
                        pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])+\.([a-z])+/,
                        message: 'Somente letras (a - z), números (0 - 9) e pontos (.) são permitidos.'
                      }

                    ]}
                  >
                    <Input
                      placeholder="E-mail"
                      disabled={verificaTriagem === true}

                    />
                  </Form.Item>
                </Grid>
                {/* { celular, whatsapp, tel fixo, ttel comercial, email } */}

                <Spin spinning={loadingCEP} tip="Buscando dados do CEP">
                  <Grid container>
                    {/* { cep, uf, cidade } */}

                    <Grid item lg={12} xs={12}>
                      <Form.Item label="CEP" name="cep">
                        <MaskedInput
                        disabled={verificaTriagem === true}
                          mask="11111-111"
                          placeholder="CEP"
                          onChange={(e: any) => { debounceCEP(e.target.value);
                            //validaAlteracao();
                            }}


                        />
                      </Form.Item>
                    </Grid>



                    <InputEstadoCidade ufProps={uf} triagem={verificaTriagem}/>




                    {/* { cep, uf, cidade } */}

                    {/* { endereço, numero } */}
                    <Grid item lg={12} xs={12}>
                      <Form.Item
                        label="Endereço"
                        name="endereco"
                        rules={
                          [
                            { required: false },
                            {pattern:  /^[a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ0-9\-\ ]+$/,
                            message: 'Somente letras'}
                          ]
                        }
                      >
                        <Input disabled={verificaTriagem === true} placeholder="Endereço"  />
                      </Form.Item>
                    </Grid>
                    {/* { endereço, numero } */}

                    {/* { complemento, bairro } */}
                    <Grid item sm={6} xs={12}>
                      <Form.Item
                        label="Bairro"
                        name="bairro"
                        rules={[{ required: false }]}
                      >
                        <Input disabled={verificaTriagem === true} placeholder="Bairro" />
                      </Form.Item>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <Form.Item
                        label="Distrito"
                        name="Distrito"
                      >
                        <Input disabled={verificaTriagem === true} onInput={handleInput}  placeholder="Distrito" />
                      </Form.Item>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                    <Form.Item
                        label="Complemento"
                        name="complemento"
                        rules={
                          [

                             { max: 45, message: 'Máximo de 45 caracteres' },
                          ]
                        }
                      >
                        <Input disabled={verificaTriagem === true} onInput={handleInput}  placeholder="Complemento"
                         />
                      </Form.Item>
                    </Grid>
                    <Grid container>
                      <Grid item sm={12} xs={12}>
                        <Form.Item
                          name="nome_fantasia"
                          label="Nome de tratamento"
                        >
                          <Input onInput={handleInput} disabled placeholder="Nome de tratamentoo"/>
                        </Form.Item>
                      </Grid>
                    </Grid>
                    {/* { complemento, bairro } */}
                  </Grid>
                </Spin>
                <Grid item sm={12} xs={12} style={{ paddingTop: 10 }}>
                  <ButtonComponent
                    color="primary"
                    disabled={verificaTriagem === true}
                    loading={loadingUpdate}
                    fullWidth
                    type="submit"
                  >
                    <SaveOutlined style={{ marginRight: 10 }} /> Salvar
                  </ButtonComponent>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        </Spin>
      </Grid>




    </Grid>
  );
};

export default PerfilComponent;
