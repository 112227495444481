import api from '@http/api';


interface IResponseInfosAlteradasTriagem {
  error: boolean;
  response?: IInfosAlteradasTriagem;
}

export interface IInfosAlteradasTriagem {
  NovoTipoPerfil: any;
  NovaFormaContribuicao: any;
  NovoTipoCorrespondencia: any;
  NovoValorPadrao: any;
  NovoBairro: any;
  NovoEstado: any;
  NovaCidade: any;
  NovoCep: any;
  NovoLogradouro: any;
  NovoEndereco: any;
  NovoNumeroCasa: any;
  NovoComplemento: any;
  NovoDistrito: any;
  NovoEmail: any;
  NovoCelular: any;
  NovoWhatsApp: any;
  NovoTelefoneComercial: any;
  NovoTelefoneFixo: any;
  NovoConvenio: any;
  NovaAgenciaNumero: any;
  NovaAgenciaDigito: any;
  NovaContaNumero: any;
  NovoValorRecorrente: any;
  NovoDiaVencimentoDebito: any;
  NovaContaDigito: any;

  //NovaCidade: number;

}


interface IResponsePerfilTriagem {
  error: boolean;
  response?: IPerfilTriagem;
}

export interface IPerfilTriagem {
  nome: string;
  tipo_perfil_descricao: string;
  origem_macro_id: string;
  origem_macro_descricao: string;
  origem_id: string;
  origem_descricao: string
  motivo_cadastro_id: string;
  motivo_cadastro_descricao: string;
  tipo_correspondencia_id: string;
  tipo_correspondencia_descricao: string;
  forma_contribuicao_id: string;
  forma_contribuicao_descricao: string;
  valor_padrao_doacao: number;
  telefone_fixo: IContatoOnline;
  telefone_comercial: IContatoOnline;
  celular: IContatoOnline;
  whatsapp: IContatoOnline;
  email: IContatoOnline;
  contato_offline: IContatoOffline;
  nome_fantasia: string;
  debito_automatico: IDebito;
}

export interface IOrigemEspecifica {
  id: string;
  descricao: string;
  data_cadastro: string;
  status_ativo: boolean;
}

export interface IMotivo {
  id: string;
  descricao: string;
  data_cadastro: string;
  status_ativo: boolean;
}

export interface IFormaContribuicao {
  id: string;
  descricao: string;
  data_cadastro: string;
  status_ativo: boolean;
}

export interface ITipoCorrespondecia {
  id: string;
  descricao: string;
  data_cadastro: string;
  status_ativo: boolean;
}

export interface IDebito{
  //id?: number;
  id_convenio: number;
  id_banco:  number;
  //nome_cedente?: string;
  agencia_numero: string;
  agencia_digito: string;
  conta_numero: string;
  conta_digito: string;
  operacao: string;
  valor: number;
  dia_vencimento: string;
  recorrente: 0;
  status_ativo: 0;
  //id_perfil?: number;
  //data_cadastro?: Date;
  //data_modificacao?: Date;
}

export interface IContatoOffline {
  contato_offline_id: string;
  bairro: string;
  cidade_id: string;
  estado_id: string;
  estado_descricao: string;
  cidade_descricao: string;
  cep: string;
  logradouro: string;
  endereco: string;
  numero: string;
  complemento: string;
  status_ativo: string;
  Distrito: string;
}

export interface IContatoOnline {
  contato_online_descricao: string;
}






export interface IDesativacaoDebitoAutomaticoMotivos {
  id: number;
  descricao: string;
}

class ComponentPerfilServices {
  public async loadOrigemEspecifica() {
    return await api
      .get<IOrigemEspecifica[]>('/api/OrigemEspecifica/GetOrigensEspecificas')
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
          response: [],
        };
      });
  }

  public async loadMotivos() {
    return await api
      .get<IMotivo[]>('/api/MotivoCadastro/GetMotivosCadastros')
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
          response: [],
        };
      });
  }

  public async loadFormaContribuicao() {
    return await api
      .get<IFormaContribuicao[]>('/api/FormaContribuicao/GetFormasContribuicao')
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
          response: [],
        };
      });
  }

  public async loadTiposCorrespondecia() {
    return await api
      .get<ITipoCorrespondecia[]>(
        '/api/TipoCorrespondencia/GetTiposCorrespondecia',
      )
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
          response: [],
        };
      });
  }



  public async GetDebitoDesativacaoMotivos() {
    return await api
      .get<IDesativacaoDebitoAutomaticoMotivos[]>(
        'api/Financeiro/GetDebitoDesativacaoMotivos',
      )
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
          response: [],
        };
      });
  }

  public async GetPerfilByID({ id }: any): Promise<IResponsePerfilTriagem> {
    return await api
      .get<IPerfilTriagem>(`api/Perfil/GetPerfilById/${id}`)
      .then(response => {
        return {
          error: false,
          response: response.data,
        };

      })
      .catch(error => {
        return {
          error: true,
        };
      });

  }

  public async GetInformacoesAlteradas({ id }: any): Promise<IResponseInfosAlteradasTriagem> {
    return await api
      .get<IInfosAlteradasTriagem>(`api/Triagem/TrazerAsInformacoesAlteradas?id_perfil=${id}`)
      .then(response => {
        return {
          error: false,
          response: response.data,
        };

      })
      .catch(error => {
        return {
          error: true,
        };
      });

  }


  public async CreateListaLigacaoTriagem(id_perfil: any) {
    return await api
    .post(`/api/Triagem/MoverCadastroParaLigacaoDeTriagem?id_perfil=${id_perfil}`,)
    .then(response => {
      return {
        error: false,
        //response: response.data,
      };
    })
    .catch(error => {
      return {
        error: true,
        //response: [],
      };
    });
}

public async UpdatePerfil({
  contato_offline,
  forma_contribuicao_id,
  id,
  motivo_cadastro_id,
  nome_fantasia,
  origem_especifica_id,
  status_ativo,
  tipo_correspondencia_id,
  valor_padrao_doacao,
  recorrencia_meses,
  celular,
  email,
  telefone_comercial,
  telefone_fixo,
  whatsapp,
  debito_automatico,
  debito_conta_energia,
  perfil_recorrencia_doacao,
  debito_automatico_desativacao_motivo,
}: IUpdatePerfil) {
  return await api
    .put('/api/Perfil/UpdatePerfilById', {
      contato_offline,
      forma_contribuicao_id,
      id,
      motivo_cadastro_id,
      nome: '',
      nome_fantasia,
      origem_especifica_id,
      status_ativo,
      tipo_correspondencia_id,
      valor_padrao_doacao,
      recorrencia_meses,
      celular,
      email,
      telefone_comercial,
      telefone_fixo,
      whatsapp,
      debito_automatico,
      debito_conta_energia,
      perfil_recorrencia_doacao,
      debito_automatico_desativacao_motivo,
    })

    .then(response => {
      return {
        error: false,
      };
    })
    .catch(error => {
      return {
        error: true,
      };
    });
}

  public async CreateSolicitacao(data: IRequestSolicitacao) {
    return await api
      .post('api/Solicitacao/CreateSolicitacao', data)
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
          response: [],
        };
      });
  }


}

export interface IUpdatePerfil {
  id: string;
  motivo_cadastro_id: string;
  nome_fantasia: string;
  origem_especifica_id: string;
  forma_contribuicao_id: string;
  tipo_correspondencia_id: string;
  status_ativo: number;
  valor_padrao_doacao: number;
  recorrencia_meses: number;
  contato_offline: IContatoOffline;
  telefone_fixo: IContatoOnline;
  telefone_comercial: IContatoOnline;
  celular: IContatoOnline;
  whatsapp: IContatoOnline;
  email: IContatoOnline;
  debito_automatico: {
    id?: number;
    id_convenio?: number;
    id_banco?:  number;
    nome_cedente?: string;
    agencia_numero: string;
    agencia_digito: string;
    conta_numero: string;
    conta_digito: string;
    operacao: string;
    valor: number;
    dia_vencimento: string;
    recorrente: 0;
    status_ativo: 0;
    id_perfil?: number;
    data_cadastro?: Date;
    data_modificacao?: Date;
  };
  debito_automatico_desativacao_motivo?: {
    id: number;
    descricao: string;
  };

  debito_conta_energia: {
    id?: number;
    id_convenio?: number;
    conta_contrato_numero: string;
    recorrencia: number;
    valor: number;
    status_ativo: true;
    id_perfil?: number;
    data_cadastro?: Date;
    data_modificacao?: Date;
  };

  perfil_recorrencia_doacao: {
    id?: number;
    intervalo_mes: number;
    dia: number;
    status_ativo: true;


  };
}

export interface IRequestSolicitacao {
  id_perfil: number;
  id_tipo_solicitacao: number;
  observacoes: string;
  valor: number;
  data_vencimento: string;
  id_campanha: number;
}



export default ComponentPerfilServices;
