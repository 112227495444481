import React, { useEffect, useState, useRef } from 'react';

import { Grid, IconButton } from '@material-ui/core';
import { Form, Input, Select as SelectAntd, Spin, Radio } from 'antd';
import { SaveOutlined } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import ButtonComponent from '@components/Shared/Buttons/Button';


import ComponentPerfilServices, {
  ITipoConvenio,
} from './services';
import InvisibleContent from '@src/components/Shared/InvisibleContent';

interface IProps {
  finishConvenio?: Function;
  convenio: any;
  convenioNovo: any;
  perfil: number;
}

const optionsFilter = ['ID', 'CPF/CNPJ'];

const CorrespondenciaDevolvidaComponent:React.FC<IProps> = ({
  convenio,
  finishConvenio,
  convenioNovo,
  perfil,

}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [formRef] = Form.useForm();

  const [loadingValidar, setLoadingValidar] = useState(false);
  const [loadingDescartar, setLoadingDescartar] = useState(false);

  const [informacaoInstantanea, setInformacaoInstantanea] = useState(true)

  const [colunaValidar, setColunaValidar] = useState('ConvenioId');
  const [colunaDescartar, setColunaDescartar] = useState('NovoConvenio');

  const [tiposConvenios, settiposConvenios] = useState<
  ITipoConvenio[]>([]);

  const [convenioDescricao, setconvenioDescricao] = useState('');

  const [novoValor, setNovoValor] = useState('');

  const init = async () => {
    const componentPerfilServices = new ComponentPerfilServices();

    if (convenioNovo === "2"){
      setconvenioDescricao('BANCO DO BRASIL')
    } else if (convenioNovo === "6"){
      setconvenioDescricao('BANCO DO BRASIL')
    }else if (convenioNovo === "72"){
      setconvenioDescricao('SANTANDER')
    }else if (convenioNovo === "8"){
      setconvenioDescricao('CAIXA ECONOMICA FEDERAL')
    }else if (convenioNovo === "9"){
      setconvenioDescricao('BRADESCO')
    }else if (convenioNovo === "13"){
      setconvenioDescricao('ITAÚ UNIBANCO S.A.')
    }


   const tipoCorrespondenciasResponse =
      await componentPerfilServices.loadTiposConvenio();

    if (!tipoCorrespondenciasResponse.error) {
       settiposConvenios(tipoCorrespondenciasResponse.response);
    }
  }

  async function onSubmitValidarNovo() {
    setLoadingValidar(true);

    const service = new ComponentPerfilServices();
    const { error,  } = await service.ValidarTriagemConvenio(
      perfil,
      informacaoInstantanea,
      novoValor,
      colunaValidar,
    );

    if (!error) {
      enqueueSnackbar(`Cadastro alterado com sucesso!`, {
        variant: 'success',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      if (finishConvenio) {
        finishConvenio();
      }
      window.location.reload();
    } else {
      enqueueSnackbar(`Ocorreu um erro`, {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
    }
    setLoadingValidar(false);
  }


  async function onSubmitDescartar() {
    setLoadingDescartar(true);

    const service = new ComponentPerfilServices();
    const { error,  } = await service.DescartarTriagemConvenio(
      perfil,
      colunaDescartar,
    );

    if (!error) {
      enqueueSnackbar(`Cadastro descartado com sucesso!`, {
        variant: 'success',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      if (finishConvenio) {
        finishConvenio();
      }
    } else {
      enqueueSnackbar(`Ocorreu um erro`, {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
    }
    setLoadingDescartar(false);
    //window.location.reload();
  }

  const clearCadastro = () => {
    formRef.setFieldsValue({
      selectCadastro: [],
    });
  };

  useEffect(() => {
    init();
    window.document.title = 'CRM - Edição de Cadastro Triagem';
  }, []);

   return (
    <Grid container>
      <Grid container item sm={12}>
        <Grid container item xs={12} md={12} justify="center" alignItems="center">
          <Form
            form={formRef}
            scrollToFirstError={true}
            //onFinish={onSubmitValidar}
            requiredMark={false}
            style={{ width: '100%' }}
            layout="vertical"
          >
            <Grid item container justify="center" alignItems="center" alignContent='center' sm={12} xs={12} lg={12}>

              <Grid item lg={12} xs={12} sm={12}>
                <Form.Item name="convenio1">
                  <SelectAntd
                    disabled={informacaoInstantanea === false}
                    placeholder="Convênio"
                    onChange={(e: any) => {
                      setNovoValor(e);
                      //filterCheckTipoFormContribuicao(e);
                       //validaAlteracao();
                    }}
                  >
                    {tiposConvenios.map(item => (
                      <SelectAntd.Option key={item.id} value={item.id}>
                        {item.descricao}
                      </SelectAntd.Option>
                    ))}
                  </SelectAntd>
                </Form.Item>
              </Grid>

              <Grid container item lg={12} sm={12} xs={12} justify="center"
                alignItems="center" style={{ paddingTop: 15 }}>
                <Grid item lg={5} sm={12} xs={12}>
                  <ButtonComponent
                    color="default"
                    fullWidth
                    type="submit"
                    onClick={onSubmitDescartar}
                  >
                    <SaveOutlined /> Descartar
                  </ButtonComponent>
                </Grid>

                <Grid item lg={1} sm={12} xs={12}></Grid>

                <Grid item lg={5} sm={12} xs={12}>
                  <ButtonComponent
                    color="quartenary"
                    fullWidth
                    type="submit"
                    loading={loadingValidar}
                    onClick={onSubmitValidarNovo}
                  >
                    <SaveOutlined /> Validar
                  </ButtonComponent>
                </Grid>

              </Grid>
            </Grid>
          </Form>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CorrespondenciaDevolvidaComponent;
