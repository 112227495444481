import React from 'react';

import DoacoesAdmin from '@pages/Private/shared/Doacoes';
import Cadastros from '@pages/Private/shared/ListPessoas';
import Indicadores from '@pages/Private/shared/ListIndicadores';
import EditCadastro from '@pages/Private/shared/EditCadastro';
import Usuarios from '@pages/Private/shared/Usuarios';
import Consultas from '@src/Pages/Private/shared/ConsultasFinanceiro';

import EditarUsuario from '@pages/Private/shared/EditarUsuario';
import NovoCadastro from '@src/Pages/Private/Cliente/shared/NovoCadastro';
import TipoPerfil from '@pages/Private/Cliente/Admin/TiposPerfil';
import InserirDoacoes from '@src/Pages/Private/Cliente/Admin/InsercaoDoacoes';
import ListEstrategias from '@pages/Private/Cliente/Admin/ListEstrategias';
import ListSolicitacoes from '@pages/Private/Cliente/Admin/ListSolicitacoes';
import DetalhesEstrategia from '@pages/Private/Cliente/Admin/DetalhesEstrategia';
import ListEnvios from '@pages/Private/Cliente/Admin/ListEnvios';
import Financeiro from '@pages/Private/Cliente/Admin/Financeiro';
import HistoricoRemessa from '@pages/Private/Cliente/Admin/HistoricoRemessa';
import DetalhesRemessa from '@src/Pages/Private/Cliente/Admin/DetalhesRemessa';
//import DetalhesRetorno from '@src/Pages/Private/Cliente/Admin/DetalhesRetorno';
import DetalhesHistoricoRemessa from '@src/Pages/Private/Cliente/Admin/DetalhesHistoricoRemessa';
//import DetalhesRetorno from '@src/Pages/Private/Cliente/Admin/DetalhesRetorno';
import ImportarRetorno from '@src/Pages/Private/Cliente/Admin/ImportarRetorno';
import ListaLigacao from '@pages/Private/Cliente/Operacional/ListaLigacao';
import Example from '@src/Pages/example/index';
import ListCorrespondenciaDevolvida from '@src/Pages/Private/Cliente/Admin/CorrespondenciaDevolvida';

import ListCampanha from '@src/Pages/Private/Cliente/Admin/ListCampanhas';
import DetalhesCampanha from '@src/Pages/Private/Cliente/Admin/DetalhesCampanha';
import ConfigEstrategia from '@src/Pages/Private/Cliente/Admin/ConfigEstrategias';

import DashboardCadastros from '@src/Pages/Private/shared/Dashboard'
//import Dashboard from '@src/Pages/Private/shared/DashboardG'
import DashboardComparativo from '@src/Pages/Private/shared/DashboardComparativo'

import TriagemCadastro from '@pages/Private/shared/Triagem/ListPessoas';
import EditCadastroTriagem from '@pages/Private/shared/Triagem/EditCadastro';

import { ContactPage, AccountBoxIcon, StickyNote2, BookMarkIcon } from '@assets/Icons';
import {
  FavoriteBorderOutlined,
  LoyaltyOutlined,
  SendOutlined,
  PaymentOutlined,
  PhoneInTalk,
  TableChart,
  PieChartOutlined,
  BarChart
} from '@material-ui/icons';


import { ForwardToInbox, mdiDatabaseSearch, Article } from '@assets/Icons/';


export interface IRoute {
  index: number | null;
  path: string;
  component: React.ComponentType<any>;
  roles: string[];
}

export interface IRouteMenu {
  index: number;
  path?: string;
  Icon?: React.ReactNode;
  label: string;
  roles: string[];
  menu: 'lateral' | 'header';
  subMenu?: IRouteMenu[];
}

export const routesAdmin: IRoute[] = [
  {
    index: 1,
    path: '/home',
    component: DoacoesAdmin,
    roles: ['ADM CLIENTE - FINANCEIRO'],
  },
  {
    index: 8,
    path: '/cadastros/consulta',
    component: Cadastros,
    roles: ['ADM CLIENTE - FINANCEIRO'],
  },
  {
    index: null,
    path: '/cadastros/consulta/triagem',
    component: TriagemCadastro,
    roles: ['ADM CLIENTE - FINANCEIRO'],
  },
  {
    index: 3,
    path: '/cadastros/novo',
    component: NovoCadastro,
    roles: ['ADM CLIENTE - FINANCEIRO'],
  },
  {
    path: '/cadastros/solicitacoes',
    component: ListSolicitacoes,
    roles: ['ADM CLIENTE - FINANCEIRO'],
    index: null,
  },
  //{
   // path: '/estrategias/',
    //component: ListEstrategias,
    //roles: ['ADM CLIENTE - FINANCEIRO'],
    //index: null,
  //},
  //{
    //path: '/estrategias/:estrategiaID',
    //component: DetalhesEstrategia,
    //roles: ['ADM CLIENTE - FINANCEIRO'],
    //index: null,
  //},

  {
    path: '/cadastro/:idCadastro',
    component: EditCadastro,
    roles: ['ADM CLIENTE - FINANCEIRO'],
    index: null,
  },
  {
    path: '/cadastro/triagem/:idCadastro',
    component: EditCadastroTriagem,
    roles: ['ADM CLIENTE - FINANCEIRO'],
    index: null,
  },
  {
    index: null,
    path: '/inserir/doacoes',
    component: InserirDoacoes,
    roles: ['ADM CLIENTE - FINANCEIRO'],
  },
  {
    index: null,
    path: '/example',
    component: Example,
    roles: ['ADM CLIENTE - FINANCEIRO'],
  },

  //{
   //index: null,
    //path: '/estrategia/:idEstrategia',
    //component: ConfigEstrategia,
    //roles: ['ADM CLIENTE - FINANCEIRO'],
  //},
  {
    path: '/consultas',
    component: Consultas,
    roles: ['ADM CLIENTE - FINANCEIRO'],
    index: 9,
  },
  {
    index: 10,
    path: '/financeiro',
    component: Financeiro,
    roles: ['ADM CLIENTE - FINANCEIRO'],
  },
  {
    path: '/financeiro/gerarRemessa',
    component: Financeiro,
    roles: ['ADM CLIENTE - FINANCEIRO'],
    index: null,
  },
  {
    path: '/financeiro/importarRetorno',
    component: ImportarRetorno,
    roles: ['ADM CLIENTE - FINANCEIRO'],
    index: null,
  },
  {
    path: '/financeiro/historicoRemessa',
    component: HistoricoRemessa,
    roles: ['ADM CLIENTE - FINANCEIRO'],
    index: null,
  },
  //{
   //index: null,
    //path: '/remessa/debito/detalhes',
    //component: DetalhesRemessa,
    //roles: ['ADM CLIENTE - FINANCEIRO'],
  //},
  {
    index: null,
    path: '/historico/remessa/detalhes',
    component: DetalhesHistoricoRemessa,
    roles: ['ADM CLIENTE - FINANCEIRO'],
  },
  //{
   // index: null,
    //path: '/retorno/detalhes',
    //component: DetalhesRetorno,
    //roles: ['ADM CLIENTE - FINANCEIRO'],
  //},
  {
    path: '/ligacoes',
    component: ListaLigacao,
    roles: ['ADM CLIENTE - FINANCEIRO'],
    index: 4,
  },
  //{
   /// path: '/dashboard/novos',
   // component: DashboardCadastros,
   // roles: ['ADM CLIENTE - FINANCEIRO'],
    //index: null,
  //},
 // {
  //  path: '/dashboardNovo',
    //component: Dashboard,
   //roles: ['ADM CLIENTE - FINANCEIRO'],
    //index: null,
  //},
  //{
   // path: '/dashboard/comparativo',
  //  component: DashboardComparativo,
  //  roles: ['ADM CLIENTE - FINANCEIRO'],
   // index: null,
  //},
];


export const routesAdminFinanceiroMenu: IRouteMenu[] = [
  {
    index: 1,
    path: '/doacoes',
    label: 'Home',
    Icon: <FavoriteBorderOutlined />,
    roles: ['ADM CLIENTE - FINANCEIRO'],
    menu: 'header',
  },
  {
    index: 2,
    label: 'Cadastros',
    path: '/cadastros',
    Icon: ContactPage,
    roles: ['ADM CLIENTE - FINANCEIRO'],
    menu: 'lateral',
    subMenu: [
      {
        index: 2.1,
        path: '/cadastros/novo',
        label: 'Novo',
        roles: ['ADM CLIENTE - FINANCEIRO'],
        menu: 'lateral',
      },
      {
        index: 2.2,
        path: '/cadastros/consulta',
        label: 'Consulta',
        roles: ['ADM CLIENTE - FINANCEIRO'],
        menu: 'lateral',
      },
      //{
       // index: 2.4,
      //  path: '/cadastros/dadosGerais',
        //label: 'Dados Gerais',
       // roles: ['ADM CLIENTE - FINANCEIRO'],
       // menu: 'lateral',
      //},
      {
        index: 2.7,
        path: '/cadastros/consulta/triagem',
        label: 'Consulta triagem',
        roles: ['ADM CLIENTE - FINANCEIRO'],
        menu: 'lateral',
      },
    ],
  },
  {
    index: 3,
    label: 'Inserção de doações',
    path: '/inserir/doacoes',
    Icon: LoyaltyOutlined,
    roles: ['ADM CLIENTE - FINANCEIRO'],
    menu: 'lateral',
  },
  /*{
    index: 4,
    label: 'Campanha',
    path: '/campanha',
    Icon: BookMarkIcon,
    roles: ['ADM CLIENTE - FINANCEIRO'],
    menu: 'lateral',
  },*/

  {
    index: 7,
    label: 'Ligações',
    path: '/ligacoes',
    Icon: PhoneInTalk,
    roles: ['ADM CLIENTE - FINANCEIRO'],
    menu: 'lateral',
  },
  /*{
    index: 8,
    path: '/estrategias/',
    label: 'Estratégias',
    Icon: <StickyNote2 />,
    roles: ['ADM CLIENTE - FINANCEIRO'],
    menu: 'header',
  },*/
  {
    index: 9,
    path: '/consultas',
    label: 'Consultas',
    Icon: mdiDatabaseSearch,
    roles: ['ADM CLIENTE - FINANCEIRO'],
    menu: 'lateral',
  },
  {
    index: 10,
    label: 'Financeiro',
    path: '/financeiro',
    Icon: PaymentOutlined,
    roles: ['ADM CLIENTE - FINANCEIRO'],
    menu: 'lateral',
    subMenu: [
      // {
      //   index: 8.1,
      //   path: '',
      //   label: 'Emitir Boleto',
      //   roles: ['ADM CLIENTE - FINANCEIRO'],
      //   menu: 'lateral',
      // },
      // {
      //   index: 8.2,
      //   path: '',
      //   label: 'Histórico de boleto',
      //   roles: ['ADM CLIENTE - FINANCEIRO'],
      //   menu: 'lateral',
      // },
      {
        index: 10.3,
        path: '/financeiro/gerarRemessa',
        label: 'Gerar Remessa',
        roles: ['ADM CLIENTE - FINANCEIRO'],
        menu: 'lateral',
      },
      // {
      //   index: 8.4,
      //   path: '',
      //   label: 'Histórico de remessa',
      //   roles: ['ADM CLIENTE - FINANCEIRO'],
      //   menu: 'lateral',
      // },
      {
        index: 10.5,
        path: '/financeiro/importarRetorno',
        label: 'Importar retorno',
        roles: ['ADM CLIENTE - FINANCEIRO'],
        menu: 'lateral',
      },
      // {
      //   index: 8.6,
      //   path: '',
      //   label: 'Histórico de arquivos',
      //   roles: ['ADM CLIENTE - FINANCEIRO'],
      //   menu: 'lateral',
      // },
      {
        index: 10.6,
        path: '/financeiro/historicoRemessa',
        label: 'Histórico remessa',
        roles: ['ADM CLIENTE - FINANCEIRO'],
        menu: 'lateral',
      },
    ],
  },

  // {
  //   index: 12,
  //   path: '/dashboard',
  //   label: 'Dashboard',
  //   Icon: <FavoriteBorderOutlined />,
  //   roles: ['ADM CLIENTE - FINANCEIRO'],
  //   menu: 'header',
  // },
  // {
  //   index: 13,
  //   path: '/dashboardNovo',
  //   label: 'Dashboard Novo',
  //   Icon: <FavoriteBorderOutlined />,
  //   roles: ['ADM CLIENTE - FINANCEIRO'],
  //   menu: 'header',
  // },
  //{
    //index: 12,
    //label: 'Dashboard',
    //Icon: BarChart,
    //roles: ['ADM CLIENTE - FINANCEIRO'],
    //menu: 'lateral',
    //subMenu: [
      //{
        //index: 12.1,
        //path: '/dashboard/novos',
        //label: 'Novos e Geral',
        //roles: ['ADM CLIENTE - FINANCEIRO'],
        //menu: 'lateral',
      //},
      //{
        //index: 12.2,
        //path: '/dashboard/comparativo',
        //label: 'Comparativo',
        //roles: ['ADM CLIENTE - FINANCEIRO'],
        //menu: 'lateral',
      //},
    //],
  //},
];
