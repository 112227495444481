import React, { useState, useEffect, useCallback } from 'react';
import { useSnackbar } from 'notistack';

import { DeleteForeverOutlined, TuneOutlined, Visibility } from '@material-ui/icons';
import { Grid, IconButton } from '@material-ui/core';

import { TitlePage } from '@styles/styles.global';
import TableActions from '@components/Shared/Tables/TableCustomKeysCount';
import { Input, Select as SelectAntd, Form } from 'antd';
import Filtros from '@src/components/Shared/Filtros';
import Button from '@components/Shared/Buttons/Button';
import InvisibleContent from '@components/Shared/InvisibleContent';


import useDebounce from '@hooks/useDebounce';
import { MaskCPFeCNPJ } from '@utils/Masks';
import PageListPessoasServices, {
  IPerfil,
  IListPessoaTriagem,
} from './services';
import { subDays } from 'date-fns';



import { useLoading } from '@hooks/LoadingContext';

import { useAuth } from '@hooks/AuthenticateContext';

const columnsLabel = {
  id_perfil: 'ID Perfil',
  nome_pessoa: 'Nome',
  status_triagem: 'Situação:',
};

const optionsFilter = ['CPF/CNPJ', 'Nome'];

const ListPessoasTriagem: React.FC = () => {
  const { setValues, removeLoading } = useLoading();
  const [formRef] = Form.useForm();
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItens, setTotalItems] = useState(0);
  const [selectedFilter, setSelectedFilter] = useState('');
  const [valueInput, setValueInput] = useState('');
  const [tipoFiltroSelected, setTipoFiltroSelected] = useState<
    'Simples' | 'Detalhada'
  >();


  //console.log("asjflsjflsdfjlsdjflsdf7777",selectedFilter )

  const [data, setData] = useState<
  IListPessoaTriagem[]
  >([]);


  const [filtros, setFiltros] = useState(false);

  const setValuesOrError = (error: boolean, response: any) => {
    if (!error && response) {
      setTotalItems(response.total_itens);
      setTotalPages(response.total_pagina);
      setData(response.modelo);
      setLoading(false);
    } else {
      enqueueSnackbar('Ocorreu um erro.', {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      setLoading(false);
    }
  };

  const setValuesOrErrorCPF = (error: boolean, response: any) => {
    if (!error && response) {
      setTotalItems(response.total_itens);
      setTotalPages(response.total_pagina);
      setData(response.modelo);
      setLoading(false);
    } else {
      enqueueSnackbar('Ocorreu um erro.', {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      setLoading(false);
    }
  };

  const setValuesOrErrorNome = (error: boolean, response: any) => {
    if (!error && response) {
      setTotalItems(response.total_itens);
      setTotalPages(response.total_pagina);
      setData(response.modelo);
      setLoading(false);
    } else {
      enqueueSnackbar('Ocorreu um erro.', {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      setLoading(false);
    }
  };

  function setPagination(pagina_numero: number, pagina_tamanho: number) {
    if (itemsPerPage !== pagina_tamanho) {
      pagina_numero = 1;
    }

    setItemsPerPage(pagina_tamanho);
    loadOrigensEspecificas(page, pagina_tamanho);
  }

  const loadOrigensEspecificas = async (pagina_numero: number, pagina_tamanho: number) => {
    setLoading(true);
    const pageListPessoasServices = new PageListPessoasServices();

    const { error, response } =
      await pageListPessoasServices.loadPessoasTriagem({pagina_numero, pagina_tamanho});

      setValuesOrError(error, response);
  }



  const firstLoad = useCallback(async () => {
    const pageListPessoasServices = new PageListPessoasServices();

    setLoading(true);

    const { error, response } = await pageListPessoasServices.loadPessoasTriagem({
      pagina_numero: 1,
      pagina_tamanho: 10,
    });
    setValuesOrError(error, response);
  }, []);

  const loadBuscaCPF = useCallback(async () => {
    const pageListPessoasServices = new PageListPessoasServices();

    setLoading(true);

    const { error, response } = await pageListPessoasServices.loadPessoasTriagemByCPF({
      cpf: formRef.getFieldValue('cpf'),
      pagina_numero: 1,
      pagina_tamanho: 10,
    });
    setValuesOrErrorCPF(error, response);
  }, []);

  const loadBuscaNome = useCallback(async () => {
    const pageListPessoasServices = new PageListPessoasServices();

    setLoading(true);

    const { error, response } = await pageListPessoasServices.loadPessoasTriagemByNome({
      nome: formRef.getFieldValue('nome'),
      pagina_numero: 1,
      pagina_tamanho: 10,
    });
    setValuesOrErrorNome(error, response);
  }, []);

  const debouncedChange = useDebounce(firstLoad, 1000);
  const debouncedChangeCPF = useDebounce(loadBuscaCPF, 1000);
  const debouncedChangeNome = useDebounce(loadBuscaNome, 1000);


  const handleOpen = () => {
    setFiltros(!filtros);
  };

  function setValueCampo(e: any) {
    let value = e.value;

    if (selectedFilter === 'CPF/CNPJ') {
      const { valueModify } = MaskCPFeCNPJ(value);
      value = valueModify;
    }

    setValueInput(value);
  }



  useEffect(() => {
    firstLoad();
    window.document.title = 'CRM - Lista de Cadastros Triagem';
  }, [firstLoad]);


  //console.log('DEUS', valueInput)



  const { user, signOut } = useAuth();

    //Bloqueio inspecionar
    useEffect(() => {
      if (user.role !== 'ADM AMEX') {
        const handleKeyDown = (event: KeyboardEvent) => {
          if (event.key === 'F12') {
            event.preventDefault();
            alert('F12 está desativado.');
          }
          if (event.ctrlKey && event.shiftKey && event.key === 'I') {
            event.preventDefault();
            alert('Ctrl+Shift+I está desativado.');
          }
          if (event.ctrlKey && event.shiftKey && event.key === 'J') {
            event.preventDefault();
            alert('Ctrl+Shift+J está desativado.');
          }
          if (event.ctrlKey && event.key === 'U') {
            event.preventDefault();
            alert('Ctrl+U está desativado.');
          }
        };

        const handleContextMenu = (event: MouseEvent) => {
          event.preventDefault();
          alert('O botão direito do mouse está desativado.');
        };

        document.addEventListener('keydown', handleKeyDown);
        document.addEventListener('contextmenu', handleContextMenu);

        return () => {
          document.removeEventListener('keydown', handleKeyDown);
          document.removeEventListener('contextmenu', handleContextMenu);
        };
      }
    }, [user.role]);

  return (
    <Grid container alignItems="center" item lg={9} xs={12}>
      <Grid item md={12} xs={12} lg={12}>
        <TitlePage style={{ margin: 5, padding: 5 }}>Cadastros em triagem</TitlePage>
      </Grid>

      <Grid
        item
        md={2}
        lg={2}
        xs={12}
        style={{
          paddingRight: 10,
          paddingLeft: 10,
          alignSelf: 'flex-end',
          paddingTop: 10,
        }}
      >
        <Button
          color="secondary"
          fullWidth
          style={{
            height: 46,
          }}
          onClick={handleOpen}
        >
          <TuneOutlined style={{ marginRight: 10 }} /> Filtros
        </Button>
      </Grid>

      <Filtros abrir={filtros} openMenu={handleOpen}>
        <Grid
          item
          md={12}
          lg={12}
          xs={12}
          style={{ paddingRight: 10, paddingLeft: 10, paddingTop: 10 }}
        >
          <Form className="teste2 " layout={'vertical'} form={formRef}>

              <Grid item md={12} lg={12} xs={12}>
                <Form.Item
                  label={<label style={{ color: '#fff' }}>Filtros</label>}
                >
                  <SelectAntd
                    style={{ width: '100%' }}
                    placeholder="Filtros"
                    onChange={(e: string) => {
                      setValueInput('');
                      setSelectedFilter(e);
                      if (e === 'Sem filtro') {
                        debouncedChange(1, itemsPerPage, false, '');
                      }

                    }}
                  >
                    {optionsFilter.map((option, index) => (
                      <SelectAntd.Option key={option} value={option}>
                        {option}
                      </SelectAntd.Option>
                    ))}
                  </SelectAntd>


                  <InvisibleContent visible = { selectedFilter === 'CPF/CNPJ'}>
                  <Grid item md={12} lg={12} xs={12} style={{paddingTop: 10}}>
                  <Form.Item
              name="cpf">
                      <Input
                        placeholder="Buscar por:"
                        name={selectedFilter}
                        value={valueInput}
                        type={
                          selectedFilter === 'ID' || selectedFilter === 'ID AFIPE' || selectedFilter === 'ID Perfil'
                            ? 'number'
                            : 'text'
                        }
                        onChange={e => {
                          setValueCampo(e.target);
                          debouncedChangeCPF(1, itemsPerPage, false, e.target.value);
                        }}
                      />
                      </Form.Item>
                      </Grid>

                    </InvisibleContent>

                    <InvisibleContent visible = { selectedFilter === 'Nome'}>
                  <Grid item md={12} lg={12} xs={12} style={{paddingTop: 10}}>
                  <Form.Item
              name="nome">
                      <Input
                        placeholder="Buscar por:"
                        name={selectedFilter}
                        value={valueInput}
                        type={
                          selectedFilter === 'ID' || selectedFilter === 'ID AFIPE' || selectedFilter === 'ID Perfil'
                            ? 'number'
                            : 'text'
                        }
                        onChange={e => {
                          setValueCampo(e.target);
                          debouncedChangeNome(1, itemsPerPage, false, e.target.value);
                        }}
                      />
                      </Form.Item>
                      </Grid>

                    </InvisibleContent>

                </Form.Item>
              </Grid>

              <Grid
        item
        md={12}
        lg={12}
        xs={12}
        style={{
          paddingRight: 10,
          paddingLeft: 10,
          alignSelf: 'flex-end',
          paddingTop: 10,
        }}
      >
        <Button
          color="primary"
          fullWidth
          style={{
            height: 46,
          }}
          onClick={firstLoad}
        >
          <DeleteForeverOutlined style={{ marginRight: 10 }} /> Limpar filtros
        </Button>
      </Grid>





          </Form>
        </Grid>
      </Filtros>

      <Grid container>
        <Grid item xs={12} lg={12} style={{ padding: 10 }}>
          <TableActions
            keys={['id_perfil', 'nome_pessoa', 'status_triagem']}
            FieldsBold={['documento']}
            loading={loading}
            columns={[columnsLabel]}
            data={data}
            itemsPerPage={itemsPerPage}
            totalPages={totalPages}
            pageCurrent={page}
            fetchData={setPagination}
            totalItens={totalItens}
            Actions={[
              {
                Button: (row: IListPessoaTriagem) => {
                  return (

                    <IconButton
                    disabled ={row.status_triagem !== 'EM TRIAGEM' && row.id_usuario !== user.id }
                      onClick={() => window.open(`/cadastro/triagem/${row.id_perfil}/`)}
                    >
                      <Visibility />
                    </IconButton>


                  );
                },
              },
            ]}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ListPessoasTriagem;
