import React, { useEffect, useState, useRef } from 'react';

import { Grid, IconButton } from '@material-ui/core';
import { Form, Input, Select as SelectAntd, Spin, Radio } from 'antd';
import { SaveOutlined } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import ButtonComponent from '@components/Shared/Buttons/Button';
import InvisibleContent from '@src/components/Shared/InvisibleContent';

import ComponentPerfilServices, {
  ICidade,
} from './services';

interface IProps {
  finishCidade?: Function;
  cidade: any;
  cidadeNovo: any;
  estado: any;
  perfil: number;
}

const optionsFilter = ['ID', 'CPF/CNPJ'];

const CorrespondenciaDevolvidaComponent:React.FC<IProps> = ({
  cidade,
  finishCidade,
  cidadeNovo,
  estado,
  perfil,

}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [formRef] = Form.useForm();

  const [loadingValidar, setLoadingValidar] = useState(false);
  const [loadingDescartar, setLoadingDescartar] = useState(false);

  const [informacaoInstantanea, setInformacaoInstantanea] = useState(true)

  const [colunaValidar, setColunaValidar] = useState('Cidade');
  const [colunaDescartar, setColunaDescartar] = useState('NovaCidade');

  const [cidadeDescricao, setCidadeDescricao] = useState('');
  const [cidades, setCidades] = useState<ICidade[]>([]);

  const [novoValor, setNovoValor] = useState('');


  const init = async () => {
    const componentPerfilServices = new ComponentPerfilServices();

    const cidadesResponse = await componentPerfilServices.loadCidadeById(cidadeNovo);

    if (!cidadesResponse.error) {
       setCidadeDescricao(cidadesResponse.response);
    }


    const getCidadesByUFResponse = await componentPerfilServices.getCidadesByUF(estado);

    if (!getCidadesByUFResponse.error) {
      setCidades(getCidadesByUFResponse.response);
    }

  }


  async function onSubmitValidarNovo() {
    setLoadingValidar(true);

    const service = new ComponentPerfilServices();
    const { error,  } = await service.ValidarTriagemCidade(
      perfil,
      informacaoInstantanea,
      novoValor,
      colunaValidar,
    );

    if (!error) {
      enqueueSnackbar(`Cadastro alterado com sucesso!`, {
        variant: 'success',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      if (finishCidade) {
        finishCidade();
      }
      window.location.reload();
    } else {
      enqueueSnackbar(`Ocorreu um erro`, {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
    }
    setLoadingValidar(false);
  }


  async function onSubmitDescartar() {
    setLoadingDescartar(true);

    const service = new ComponentPerfilServices();
    const { error,  } = await service.DescartarTriagemCidade(
      perfil,
      colunaDescartar,
    );

    if (!error) {
      enqueueSnackbar(`Cadastro descartado com sucesso!`, {
        variant: 'success',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      if (finishCidade) {
        finishCidade();
      }
    } else {
      enqueueSnackbar(`Ocorreu um erro`, {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
    }
    setLoadingDescartar(false);
    //window.location.reload();
  }

  const clearCadastro = () => {
    formRef.setFieldsValue({
      selectCadastro: [],
    });
  };

  useEffect(() => {
    init();
    window.document.title = 'CRM - Edição de Cadastro Triagem';
  }, []);

  return (
    <Grid container>
      <Grid container item sm={12}>
        <Grid container item xs={12} md={12} justify="center" alignItems="center">
          <Form
            form={formRef}
            scrollToFirstError={true}
            //onFinish={onSubmitValidar}
            requiredMark={false}
            style={{ width: '100%' }}
            layout="vertical"
          >
            <Grid item container justify="center" alignItems="center" alignContent='center' sm={12} xs={12} lg={12}>



                  <Grid item lg={12} xs={12} sm={12}>
                    <Form.Item name="cidade1">
                      <SelectAntd

                          placeholder="Cidades"
                          onChange={(e: any) => {
                            setNovoValor(e);
                            //filterCheckTipoFormContribuicao(e);
                            //validaAlteracao();
                          }}
                        >
                          {cidades.map(item => (
                            <SelectAntd.Option key={item.id} value={item.id}>
                              {item.label}
                            </SelectAntd.Option>
                          ))}
                          </SelectAntd>
                    </Form.Item>
                  </Grid>

              <Grid container item lg={12} sm={12} xs={12} style={{ paddingTop: 10 }}>
                <Grid item lg={5} sm={12} xs={12}>
                  <ButtonComponent
                    color="default"
                    fullWidth
                    type="submit"
                    onClick={onSubmitDescartar}
                  >
                    <SaveOutlined /> Descartar
                  </ButtonComponent>
                </Grid>

                <Grid item lg={1} sm={12} xs={12}></Grid>

                <Grid item lg={5} sm={12} xs={12}>

                    <ButtonComponent
                      color="quartenary"
                      fullWidth
                      type="submit"
                      loading={loadingValidar}
                      onClick={onSubmitValidarNovo}
                    >
                      <SaveOutlined /> Validar
                    </ButtonComponent>



                </Grid>

              </Grid>
            </Grid>
          </Form>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CorrespondenciaDevolvidaComponent;
