import React, { useEffect, useState, useRef } from 'react';

import { Grid, IconButton } from '@material-ui/core';
import { Form, Input, Select as SelectAntd, Spin, Radio } from 'antd';
import { SaveOutlined } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import ButtonComponent from '@components/Shared/Buttons/Button';
import InvisibleContent from '@src/components/Shared/InvisibleContent';

import ComponentPerfilServices from './services';

interface IProps {
  finishEndereco?: Function;
  endereco: any;
  enderecoNovo: any;
  perfil: number;
}

const optionsFilter = ['ID', 'CPF/CNPJ'];

const TriagemComponent:React.FC<IProps> = ({
  endereco,
  finishEndereco,
  enderecoNovo,
  perfil,

}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [formRef] = Form.useForm();

  const [loadingValidar, setLoadingValidar] = useState(false);
  const [loadingDescartar, setLoadingDescartar] = useState(false);

  const [informacaoInstantanea, setInformacaoInstantanea] = useState(true)

  const [colunaValidar, setColunaValidar] = useState('Endereco');
  const [colunaDescartar, setColunaDescartar] = useState('NovoEndereco');

  const [novoValor, setNovoValor] = useState('');

  async function onSubmitValidarNovo() {
    setLoadingValidar(true);

    const service = new ComponentPerfilServices();
    const { error,  } = await service.ValidarTriagemEndereco(
      perfil,
      informacaoInstantanea,
      novoValor,
      colunaValidar,
    );

    if (!error) {
      enqueueSnackbar(`Cadastro alterado com sucesso!`, {
        variant: 'success',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      if (finishEndereco) {
        finishEndereco();
      }
      window.location.reload();
    } else {
      enqueueSnackbar(`Ocorreu um erro`, {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
    }
    setLoadingValidar(false);
    //window.location.reload();
  }

  async function onSubmitDescartar() {
    setLoadingDescartar(true);

    const service = new ComponentPerfilServices();
    const { error,  } = await service.DescartarTriagemEndereco(
      perfil,
      colunaDescartar,
    );

    if (!error) {
      enqueueSnackbar(`Cadastro descartado com sucesso!`, {
        variant: 'success',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      if (finishEndereco) {
        finishEndereco();
      }
      window.location.reload();
    } else {
      enqueueSnackbar(`Ocorreu um erro`, {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
    }
    setLoadingDescartar(false);
    //window.location.reload();
  }

  const clearCadastro = () => {
    formRef.setFieldsValue({
      selectCadastro: [],
    });
  };

  function handleInput(e: any) {
    var ss = e.target.selectionStart;
    var se = e.target.selectionEnd;
    e.target.value = e.target.value.toUpperCase();
    e.target.selectionStart = ss;
    e.target.selectionEnd = se;
  };


  useEffect(() => {
    //init();
    window.document.title = 'CRM - Edição de Cadastro Triagem';
  }, []);

  return (
    <Grid container>
      <Grid container item sm={12}>
        <Grid container item xs={12} md={12} justify="center" alignItems="center">
          <Form
            form={formRef}
            scrollToFirstError={true}
            //onFinish={onSubmitValidar}
            requiredMark={false}
            style={{ width: '100%' }}
            layout="vertical"
          >
            <Grid item container justify="center" alignItems="center" alignContent='center' sm={12} xs={12} lg={12}>

              <Grid item lg={12} xs={12} sm={12}>
                <Form.Item name="endereco1">
                  <Input onInput={handleInput}
                    onChange={(e: any) => {
                      setNovoValor(e.target.value);
                    }}
                    placeholder="Endereço"
                  />
                </Form.Item>
              </Grid>

              <Grid container item lg={12} sm={12} xs={12} style={{ paddingTop: 10 }}>
                <Grid item lg={5} sm={12} xs={12}>
                  <ButtonComponent
                    color="default"
                    fullWidth
                    type="submit"
                    onClick={onSubmitDescartar}
                  >
                    <SaveOutlined /> Descartar
                  </ButtonComponent>
                </Grid>

                <Grid item lg={1} sm={12} xs={12}></Grid>

                <Grid item lg={5} sm={12} xs={12}>
                  <ButtonComponent
                    color="quartenary"
                    fullWidth
                    type="submit"
                    loading={loadingValidar}
                    onClick={onSubmitValidarNovo}
                  >
                    <SaveOutlined /> Validar
                  </ButtonComponent>
                </Grid>

              </Grid>
            </Grid>
          </Form>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TriagemComponent;
