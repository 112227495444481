import api from '@http/api';
import { FormatDate } from '@utils/formats';
import { MaskCPFeCNPJ } from '@utils/Masks';



export interface IListPessoaTriagem {
  id_perfil: string;
  nome_pessoa: string;
  status_triagem: string;
  id_usuario: number;
}



export interface IPerfil {
  id: number;
  descricao: string;
  status_ativo: boolean;
}

export interface IBuscaDetalhada {
  pagina_numero: number;
  pagina_tamanho: number;
}

export interface IBuscaDetalhadaCPF {
  cpf: string;
  pagina_numero: number;
  pagina_tamanho: number;
}

export interface IBuscaDetalhadaNome {
  nome: string;
  pagina_numero: number;
  pagina_tamanho: number;
}

export interface IResponseListPessoaTriagem {
  total_itens: number;
  total_pagina: number;
  modelo: IListPessoaTriagem[];
}

interface IResponse {
  error: boolean;
  response?: {
    total_itens: number;
    total_pagina: number;
    modelo: IListPessoaTriagem[];
  };
}



class PageListPessoasServices {

  public async loadPessoasTriagem({ pagina_numero,
    pagina_tamanho}: IBuscaDetalhada):Promise<IResponse> {
    return await api
      .get<IResponseListPessoaTriagem>(`/api/Triagem/ListaCadastrosParaTriar?pagina_numero=${pagina_numero}&pagina_tamanho=${pagina_tamanho}`)
      .then(response => {
      const pessoaTriagem = response.data.modelo;
        const total_itens = response.data.total_itens;
        const total_pagina = response.data.total_pagina;

        var itemPessoaTriagem: IListPessoaTriagem[] = [];

        pessoaTriagem.map((item: IListPessoaTriagem) => {
          return itemPessoaTriagem.push({
            id_perfil: item.id_perfil,
            nome_pessoa: item.nome_pessoa,
            status_triagem: item.status_triagem,
            id_usuario: item.id_usuario,

          });
        });

        return {
          error: false,
          response: { total_itens, total_pagina, modelo: itemPessoaTriagem },
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }

  public async loadPessoasTriagemByCPF({ cpf, pagina_numero,
    pagina_tamanho}: IBuscaDetalhadaCPF):Promise<IResponse> {
    return await api
      .get<IResponseListPessoaTriagem>(`/api/Triagem/GetPerfilTriagemPorCPF?cpf=${cpf}&pagina_numero=${pagina_numero}&pagina_tamanho=${pagina_tamanho}`)
      .then(response => {
      const pessoaTriagem = response.data.modelo;
        const total_itens = response.data.total_itens;
        const total_pagina = response.data.total_pagina;

        var itemPessoaTriagemCPF: IListPessoaTriagem[] = [];

        pessoaTriagem.map((item: IListPessoaTriagem) => {
          return itemPessoaTriagemCPF.push({
            id_perfil: item.id_perfil,
            nome_pessoa: item.nome_pessoa,
            status_triagem: item.status_triagem,
            id_usuario: item.id_usuario,

          });
        });

        return {
          error: false,
          response: { total_itens, total_pagina, modelo: itemPessoaTriagemCPF },
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }

  public async loadPessoasTriagemByNome({ nome, pagina_numero,
    pagina_tamanho}: IBuscaDetalhadaNome):Promise<IResponse> {
    return await api
      .get<IResponseListPessoaTriagem>(`/api/Triagem/GetPerfilTriagemPorNome?nome=${nome}&pagina_numero=${pagina_numero}&pagina_tamanho=${pagina_tamanho}`)
      .then(response => {
      const pessoaTriagem = response.data.modelo;
        const total_itens = response.data.total_itens;
        const total_pagina = response.data.total_pagina;

        var itemPessoaTriagemNome: IListPessoaTriagem[] = [];

        pessoaTriagem.map((item: IListPessoaTriagem) => {
          return itemPessoaTriagemNome.push({
            id_perfil: item.id_perfil,
            nome_pessoa: item.nome_pessoa,
            status_triagem: item.status_triagem,
            id_usuario: item.id_usuario,

          });
        });

        return {
          error: false,
          response: { total_itens, total_pagina, modelo: itemPessoaTriagemNome },
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }
}

export default PageListPessoasServices;
